import DOTS from '../../../common/assets/images/dots.png'
import USER from '../../../common/assets/images/User.png'
import VECTOR from '../../../common/assets/images/Vector.png'
import UNREAD from '../../../common/assets/images/unread.png'

import { forModal } from '../organoids/operations'
import { UserSelected } from './UserSelected'
import { removeAfterPoint } from '../../../common/config/functions'
import { DateParse } from '../../messages/logics/MessageDateParse'
import { useHistory } from 'react-router-dom'



export interface IOperationItem {
    memberStatus:string,
    recipientStatus:string,
    userID: number,
    memberId: number,
    money: number,
    icon: string,
    color: string,
    date: string,
    origType: string,
    type: string,
    recipientId: string,
    isCanceled:string,
    setModal: React.Dispatch<React.SetStateAction<boolean>>,
    setForModal: React.Dispatch<React.SetStateAction<forModal>>,
    setModalLoading: React.Dispatch<React.SetStateAction<boolean>>,
    extAnticip: string,
    setForModalSecond: React.Dispatch<React.SetStateAction<forModal>>
}
export interface Idatas{
    id: number;
    type: string;
    date: string;
    extAnticip: string;
    money: number;
    memberStatus:string,
    recipientStatus:string,
    isCanceled:string
}

export const OperationItem = (params: IOperationItem) => {

    const history = useHistory()

    const onIconClick = () => {
        params.setModal(true)
        params.setModalLoading(true)
        const datas:Idatas = {
            id: params.userID, type: params.origType, date: params.date, extAnticip: params.extAnticip,
            money: params.money,  memberStatus:params.memberStatus,     recipientStatus:params.recipientStatus, isCanceled:params.isCanceled
        }
        const seconDatas:Idatas = {
            id: parseInt(params.recipientId), type: params.origType, date: params.date, extAnticip: params.extAnticip,
            money: params.money, memberStatus:params.memberStatus,     recipientStatus:params.recipientStatus, isCanceled:params.isCanceled
        }
        UserSelected({ history: history, setModalLoading: params.setModalLoading, id: params.memberId, setForModal: params.setForModal, data: datas })

        if (params.recipientId !== "") {
            UserSelected({ history: history, setModalLoading: params.setModalLoading, id: parseInt(params.recipientId!), setForModal: params.setForModalSecond, data: seconDatas })

        }
        else {
            params.setForModalSecond({} as forModal)
            
        }


    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', margin: "0.5rem 0" }}>
            <div style={{ position: 'relative' }}>
                {params.date === "" && params.isCanceled!=="1" ? <img style={{ position: 'absolute', top: -2, left: 20 }} src={UNREAD} alt="" /> : null}
                <img style={{ height: "30px", margin: "auto 1rem auto 0" }}
                    src={params.icon} alt="" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', fontSize: '12px', textAlign: 'start', flex: 3 }}>
                <span style={{ color: '#C9C9C9', fontSize: "10px" }} >{(params.isCanceled==="1" )? <span style={{color: '#b20000'}}>Отменена</span> : params.date !== "" ?
                    DateParse(params.date) : "Нет даты"
                }</span>
                <span style={{ color: 'white' }}>{params.type} &nbsp;
                    {params.type === "Перевод" ?
                        <><img style={{ height: 11 }} src={USER} alt="" /><span style={{ fontSize: 10 }}> #{params.memberId===0?"":params.memberId}</span>&nbsp;<img style={{ height: 10 }} src={VECTOR} alt="" />
                            &nbsp;<img style={{ height: 11 }} src={USER} alt="" /><span style={{ fontSize: 10 }}> #{params.recipientId}</span></> :
                        <><img style={{ height: 11 }} src={USER} alt="" /> <span style={{ fontSize: 10 }}> #{params.memberId} </span></>
                    }
                </span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', fontSize: '12px', textAlign: 'end', flex: 1 }}>
                <span style={{ color: '#C9C9C9', fontSize: "10px" }}>#{params.userID}</span>
                <span style={params.type === "Перевод" ? { color: 'white', fontWeight: 700, whiteSpace: "nowrap" } : params.type === "Вывод" ? { color: '#B20000', fontWeight: 700, whiteSpace: "nowrap" } : params.type === "Инвестирование" ? { color: '#E8A736', fontWeight: 700 , whiteSpace: "nowrap"} : { color: params.color, fontWeight: 700, whiteSpace: "nowrap" }}>{removeAfterPoint(params.money + "")}&nbsp;<i className="fas fa-euro-sign"></i></span>
            </div>
            <img style={{ height: "30px", margin: "auto 0 auto 1rem" }} src={DOTS} alt="" onClick={() => onIconClick()} />
        </div>
    )
}