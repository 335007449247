import { createContext, FC, useState } from "react"

export interface IfullUserInfo{
    documentId:string,
    id:number,
    name:string,
    bonusPoints:number,
    email:string,
    phone:string,
    dtCreate:string,
    accurals:string,
    deposit:number,
    account:number,
    password?:string,
    codeword?:string,
    blockCheck:string,
    isLevelTwoReferal?:boolean,
    verificationStatusEmail: number,
    verificationStatusPhone: number,
    passNumber?: string,
    dtIssue?: string,
    authority?: string,
    statusSignatura?: string,
}
interface IContext{
    fullUserInfo:IfullUserInfo,
    setFullUserInfo:React.Dispatch<React.SetStateAction<IfullUserInfo>>
    userName:string,
    setUserName:React.Dispatch<React.SetStateAction<string>>
}
export const UserContext = createContext<IContext>({} as IContext)

export const UserProvider:FC=({children})=>{
    const [fullUserInfo,setFullUserInfo]=useState<IfullUserInfo>({} as IfullUserInfo)
    const [userName,setUserName]=useState<string>('')
   
        return <UserContext.Provider value={{fullUserInfo,setFullUserInfo,userName,setUserName}}>
                {children}
            </UserContext.Provider>
}