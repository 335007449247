import {createEffect, restore} from 'effector'
import { myAxios } from '../../../common/config/forAxios';
import { refreshToken } from '../../../common/config/accesToken';

export interface IMessageItem{
    EmailFrom: string,
    Id: number,
    MemberId: number,
    ProblemDt: string,
    ProblemText: string,
    MessageRead?:number,
    FullName:string

}
export interface IMessageRead{
    Id?: string
    messageRead?: number

}
export interface IQueryMessage{
   setIsLoading:React.Dispatch<React.SetStateAction<boolean>>,
   history:any
}

export const LoadMessages = createEffect<IQueryMessage, IMessageItem[]>();

export const $messages= restore<IMessageItem[]>(LoadMessages,null)

export const setReadMessages = createEffect<IMessageRead, IMessageRead[]>();

export const $ReadMessages= restore<IMessageRead[]>(setReadMessages,null)


LoadMessages.use(async (params) => {
    var empty_pack:IMessageItem[]=[]
    await myAxios.post('/problems/list',{},{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
            .then(res=>{empty_pack=res.data.problems;
                        params.setIsLoading(false)
                    // console.log(res.data.problems)
                })
            .catch((async res=>{
                if(res.response.data.httpStatus === 401){
                    var newToken = await refreshToken(params.history)
                    await myAxios.post('/problems/list',{},{ headers:{"Authorization": "Bearer "+newToken}})
                            .then(res=>{empty_pack=res.data.problems;
                                    params.setIsLoading(false)})
                            }
            }))
        return empty_pack.reverse() as IMessageItem[]
})

setReadMessages.use(async (params) => {
    var empty_pack:IMessageRead[]=[]
    await myAxios.post('/problems/messageRead'
    ,{
        id: params.Id
    },
    {headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
            .then(res=>{
                // console.log(res)
                })
        return empty_pack.reverse() as IMessageRead[]
})

$messages.on(LoadMessages.doneData, (_, val) => val);
$ReadMessages.on(setReadMessages.doneData, (_, val) => val);