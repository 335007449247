import { createEffect } from 'effector'
import React from 'react';
import { myAxios } from '../../../common/config/forAxios';
import { refreshToken } from '../../../common/config/accesToken';

interface IVerOperationQuery {
    id?: number,
    title?: string,
    dateFact?: string,
    setModal: React.Dispatch<React.SetStateAction<boolean>>,
    history: any
    setErrorTextMessage:React.Dispatch<React.SetStateAction<string>>,

}

export const VerfOperation = createEffect<IVerOperationQuery, null>();


VerfOperation.use(async (params) => {
    // var dd = new Date(params.dateFact!)
    var dd = new Date() //Сегодняшний день?
    var dateString = ''
    if (Number.isNaN(dd.getMonth())) {
        dd = new Date(params.dateFact!.replace(" ", "T") + "Z");
    }
    dateString = new Date(dd!.getTime() - (dd!.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
    await myAxios.post('/stuff/setFactDt', { id: params.id, title: params.title, dtFact: dateString }, { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
        .then(res => { params.setModal(false) })
        .catch((async res => {
            if (res.response.data.httpStatus === 401) {
                var newToken = await refreshToken(params.history)
                await myAxios.post('/stuff/setFactDt', { id: params.id, title: params.title, dtFact: dateString }, { headers: { "Authorization": "Bearer " + newToken } })
                    .then(res => { params.setModal(false) })
            }
            else{
                params.setErrorTextMessage(res.response.data.error)
            }
        }))
    return null
})
