import LONGARROW from '../../../common/assets/images/longarrow2.png'
import MAIL from '../../../common/assets/images/email.png'
import PHONE from '../../../common/assets/images/phone.png'
import WISE from '../../../common/assets/images/wise.png'
import CARD from '../../../common/assets/images/carded.png'
import '../styles/modal.scss'
import '../styles/operations.css'
import DateAdapter from '@mui/lab/AdapterDateFns';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { useEffect, useRef, useState } from 'react';
import { styled, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { forModal } from '../organoids/operations';
import { ru } from 'date-fns/esm/locale';
import { onArrowTouch, onArrowTouchEnd, onArrowTouchStart } from '../../users/logics/arrowTouch'

import { VerfOperation } from '../logics/VerificationOperation'
import { Spinner } from 'react-bootstrap'
import ClipboardJS from 'clipboard'
import { useHistory } from 'react-router-dom'
import { CancelOperation } from '../logics/CancelOperation'

interface IOperationModal {
  setModal: React.Dispatch<React.SetStateAction<boolean>>,
  class: boolean,
  forModal: forModal,
  modalLoading: boolean,
  forModalSecond: forModal
}

export const OperationsModal = (params: IOperationModal) => {
  const [first, setfirst] = useState(false)
  const [pos1, setPos1] = useState(0)
  const [pos2, setPos2] = useState(0)
  const [errorMessage, setErrorMessage] = useState(false)
  const [errorTextMessage, setErrorTextMessage] = useState('')
  const arrowblock = useRef<HTMLDivElement>({} as HTMLDivElement)
  const history = useHistory()
  useEffect(() => {
    setErrorMessage(false)
    setErrorTextMessage('')
    if (params.class) {
      const divelemet = arrowblock.current;
      divelemet.style.bottom = "0px";
    } else {
      const divelemet = arrowblock.current;
      divelemet.style.bottom = "-100%";
    }
  }, [params.class])

  const [value, setValue] = useState<Date | null>(
    new Date(params.forModal.date),
  );
  useEffect(() => {
    if (params.forModal.date) {
      var dd = new Date(params.forModal.date)
      if (Number.isNaN(new Date(params.forModal.date).getMonth())) {
        dd = new Date(params.forModal.date.replace(" ", "T") + "Z");
      }
      setValue(dd)
    }
    else {
      setValue(new Date())
    }
  }, [params.forModal.date, params.class])
  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
  };


  var clipboard1 = new ClipboardJS('#btn1');
  var clipboard2 = new ClipboardJS('#btn2');
  var clipboard3 = new ClipboardJS('#btn3');
  var clipboard4 = new ClipboardJS('#btn4');
  var clipboard5 = new ClipboardJS('#btn5')
  var clipboard6 = new ClipboardJS('#btn6')
  clipboard1.on('success', function (e) {
    e.clearSelection();
  })
  clipboard2.on('success', function (e) {
    e.clearSelection();
  })
  clipboard3.on('success', function (e) {
    e.clearSelection();
  })
  clipboard4.on('success', function (e) {
    e.clearSelection();
  })
  clipboard5.on('success', function (e) {
    e.clearSelection();
  })
  clipboard6.on('success', function (e) {
    e.clearSelection();
  })

  const CssTextPasswordField = styled(TextField)({

    '.Mui-disabled': {
      color: '#E8A736',
      "-webkit-text-fill-color": '#E8A736!important',
    },
    ".MuiButtonBase-root": {
      backgroundColor: "#2D3039",
      color: "#E8A73644"
    },

    '& label.Mui-focused': {
      color: '#E8A736',
      textAlign: "left",
      padding: '0rem',
      width: 'auto'

    },
    '& label': {
      color: '#E8A736',
      fontSize: '14px',
      textAlign: 'center',
      width: "100%",
      top: '-6px'

    },

    '& ::placeholder': {
      color: '#E8A736',
      textAlign: 'center',
      width: '100%'
    },
    '& .MuiInputLabel-shrink': {
      top: '0px',
      textAlign: 'center',
      width: "auto",

    },
    '& .MuiInputLabel-root': {

      textAlign: 'center',
      '& .Mui-disabled': {
        color: '#E8A736',
      },
      '& .Mui-error': {
        color: '#E8A736',
      },

    },
    ".MuiPickersDay-today": {
      color: "#E8A736",
      backgroundColor: "#E8A736",
      border: "1px solid #E8A736",
      "& .Mui-selected:hover": {
        backgroundColor: "#E8A736",
      }
    },
    '& .MuiInputBase-root': {
      textAlign: 'center',
      padding: "0.5rem",
    },

    '& .Mui-error': {
      color: '#E8A736!important',
    },
    '& .MuiTextField-root': {
      minWidth: "460px",

    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      padding: 0,
      '.MuiOutlinedInput-root': {
        '.Mui-disabled': {
          color: '#E8A736',
          "-webkit-text-fill-color": "#E8A736"
        },
      },
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: "#2D3039",
      color: '#E8A736',
      fontSize: '14px',
      textAlign: "center",
      borderRadius: "10px",
      width: "316px",
      '& .Mui-disabled': {
        color: '#E8A736',
      },
      '& fieldset': {
        border: "1px solid #E8A736 !important",
        color: '#E8A736',
      },
      '& fieldset legend': {
        float: 'none',
        color: '#E8A736',
      },
      '&:hover fieldset': {
        border: "1px solid #E8A736",
        color: '#E8A736',
      },

    },

  });

  const portNameTypeOper = (typeOper: string, typeDospExtr?: string) => {
    switch (typeOper) {
      case 'adjunctions':
        return { title: "Пополнение счета" }
      case 'accrualDaily':
        return { title: "Дивиденды" }
      case 'accrualForPartner':
        return { title: "Партнёрские" }
      case 'autoReinvest':
        return { title: "Автореинвестирование" }
      case 'changeByAdmin':
        return { title: "Изменение администратором" }
      case 'extractions':
        if ((typeDospExtr === "1")) { return { title: "Досрочный вывод средств" } }
        else {
          return { title: "Вывод средств" }
        }

      case 'investments':
        return { title: "Инвестирование" }
      case 'transfers':
        return { title: "Перевод" }
      
      default:
        return { title: "Неизвестно" }
    }

  }

  return (
    <div ref={arrowblock} className={params.class ? "modal_main" : "modal_main_close"}>
      <div style={{ touchAction: "none" }} onTouchStart={e => onArrowTouchStart(e, setPos2, setfirst)} onTouchMove={e => onArrowTouch(e, pos1, pos2, setfirst, first, setPos1, setPos2)} onTouchEnd={e => onArrowTouchEnd(e, params.setModal, 100)}>
        <img src={LONGARROW} alt='' style={{ height: 10 }} />
        <p style={{ color: "#E8A736", fontWeight: 500, marginBottom: "0.75rem" }}>Операция: {portNameTypeOper(params.forModal.type, params.forModal.extAnticip).title}</p>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

          <div className={params.forModal.type === "transfers" ? "col-6" : ""} style={params.forModal.type === "transfers" ? params.forModal.userData && params.forModal.userData.id === 0 ? { display: 'flex', flexDirection: 'column', justifyContent: "center" } : { float: "left" } : {}} >
            {params.forModal.type === "transfers" ? <>
              <p style={{ color: "#E8A736", fontWeight: 500, marginBottom: "0.35rem" }}>Статус:</p>
              <p style={{ color: "#E8A736", fontWeight: 500, marginBottom: "0.75rem" }}>{params.forModal.userData && params.forModal.userData.id === 0 ? "Ожидание участника" : params.forModal.memberStatus}</p>
            </>
              : null}

            {params.modalLoading ? <><Spinner style={{ marginTop: "5rem" }} animation="border" variant="light" /></> :
              params.forModal.userData && params.forModal.userData.id === 0 ? <></> :
                <>

                  <p style={{ fontSize: "10px", marginBottom: "0.5rem", color: "#c9c9c9" }}>ID: {params.forModal.userData?.id}</p>
                  <p style={{ color: "#fff", fontWeight: 400, marginBottom: "0.75rem" }}>{params.forModal.userData?.name1 + " " + params.forModal.userData?.name2}</p>
                  <p style={{ fontSize: 14, marginBottom: "0.5rem" }}><img src={MAIL} alt='' style={{ height: 20 }} /> {params.forModal.userData?.email}</p>
                  {params.forModal.userData?.phone ? <p id="btn5" data-clipboard-target="#btn5" style={{ fontSize: 14, marginBottom: "0.5rem" }}><img src={PHONE} alt='' style={{ height: 20 }} /> {params.forModal.userData?.phone}</p> : null}
                  {params.forModal.type === "extractions" ?
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: "1rem", fontSize: "14px" }}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', margin: "0 1rem" }}>
                        {params.forModal.userData?.cardNumber !== undefined ? <img src={params.forModal.userData?.outputSystem === 2 ? WISE : CARD} alt="" style={{ height: 20, float: "left" }} /> : null}
                      </div>
                      <div>
                        {params.forModal.userData?.cardNumber !== undefined ?
                          params.forModal.userData?.outputSystem === 1 ?
                            <span id="btn4" data-clipboard-target="#btn4" > {params.forModal.userData?.cardNumber?.toString().replace(/(.{4})/g, '$1 ')}</span> :
                            <div style={{ textAlign: 'left' }} ><span id="btn1" data-clipboard-text={params.forModal.userData?.wiseOwner!}><span style={{ color: "#E8A736" }}>Фио:</span> {params.forModal.userData?.wiseOwner!}</span><br /><span id="btn2" data-clipboard-text={params.forModal.userData?.wiseBIC!}><span style={{ color: "#E8A736" }}>BIC:</span> {params.forModal.userData?.wiseBIC!}</span><br /><span id="btn3" data-clipboard-text={params.forModal.userData?.wiseIBAN!}><span style={{ color: "#E8A736" }}>IBAN:</span> {params.forModal.userData?.wiseIBAN!}</span></div>
                          : "Нет данных"}

                      </div>
                    </div> : null}

                </>}
          </div>
          {params.forModal.type === "transfers" ?
            <div className={params.forModal.type === "transfers" ? "col-6" : ""} style={params.forModal.type === "transfers" ? !params.forModalSecond.userData?.id ? { display: 'flex', flexDirection: 'column', justifyContent: "center" } : { float: "left" } : {}}>
              {params.modalLoading ? <><Spinner style={{ marginTop: "5rem" }} animation="border" variant="light" /></> : <>

                {params.forModal.type === "transfers" ? <div>
                  <p style={{ color: "#E8A736", fontWeight: 500, marginBottom: "0.35rem" }}>Статус:</p>
                  <p style={{ color: "#E8A736", fontWeight: 500, marginBottom: "0.75rem" }}>{params.forModalSecond.userData?.id ? params.forModal.recipientStatus : "Ожидание участника"}</p></div>
                  : null}
                {params.forModalSecond.userData?.id ? <>
                  <p style={{ fontSize: "10px", marginBottom: "0.5rem", color: "#c9c9c9" }}>ID: {params.forModalSecond.userData?.id}</p>
                  <p style={{ color: "#fff", fontWeight: 400, marginBottom: "0.75rem" }}>{params.forModalSecond.userData?.name1 + " " + params.forModalSecond.userData?.name2}</p>
                  <p style={{ fontSize: 14, marginBottom: "0.5rem" }}><img src={MAIL} alt='' style={{ height: 20 }} /> {params.forModalSecond.userData?.email}</p>
                  {params.forModalSecond.userData?.phone ?

                    <p id="btn6" data-clipboard-text={params.forModalSecond.userData?.phone} style={{ fontSize: 14, marginBottom: "0.5rem" }} ><img src={PHONE} alt='' style={{ height: 20 }} /> {params.forModalSecond.userData?.phone}</p>

                    : null}
                  {params.forModalSecond.type === "extractions" ?
                    <p onClick={() => navigator.clipboard.writeText(params.forModalSecond.userData!.cardNumber!.toString())}>
                      {params.forModalSecond.userData?.cardNumber !== undefined ? <img src={params.forModalSecond.userData?.outputSystem === 2 ? WISE : CARD} alt="" style={{ height: 20 }} /> : null} {params.forModalSecond.userData?.cardNumber !== undefined ? params.forModalSecond.userData?.cardNumber?.toString().replace(/(.{4})/g, '$1 ') : "Нет данных"}</p> : null}
                </> : null}
              </>}
            </div> : null}
        </div>



        <div style={{ float: "left" }}>
          <span style={{ color: "#E8A736", fontWeight: 500, margin: "0.5rem" }}>
            {params.forModal.type === "adjunctions" ? "Хочет пополнить депозит на " :
              params.forModal.type === "extractions" ? "Хочет вывести " :
                params.forModal.type === "investments" ? "Инвестировал " : "Сумма сделки "}

            {params.forModal.money} <i className="fas fa-euro-sign"></i></span>
        </div>
      </div>

      {(params.forModal.type === "adjunctions" || params.forModal.type === "extractions") ? <div>
        {!params.forModal.date ?
          <p style={{ color: "#E8A736c0" }}>Введите дату фактической транзакции</p> : null}
        <div style={{ marginTop: "1rem" }}>
          <LocalizationProvider locale={ru} dateAdapter={DateAdapter}>
            <MobileDatePicker
              cancelText="Отмена"
              okText="Ок"
              maxDate={new Date()}
              label="Дата"
              // disabled={params.forModal.date ? true : false}
              disabled={true}
              inputFormat="dd.MM.yyyy"
              value={value}
              onChange={handleChange}
              renderInput={(params) => <CssTextPasswordField {...params} />}

            />
          </LocalizationProvider>
        </div>
      </div>
        : null}
      {errorTextMessage !== '' ? <span style={{ color: "red" }}>{errorTextMessage}</span> : <></>}
      {params.forModal.isCanceled !== "1" ?
        <div style={{ padding: "0 1rem" }}>
          <button className="modal_button" onClick={() => CancelOperation({ history: history, id: params.forModal.id, title: params.forModal.type, setErrorMessage: setErrorMessage, dateFact: params.forModal.date, setModal: params.setModal })}>{errorMessage ? "Данная операция не может быть отклонена" : "Отклонить"}</button>
          {((params.forModal.type === "adjunctions" || params.forModal.type === "extractions") && (params.forModal.date + "") === "") ?

            <button className="modal_button_second" onClick={() => { VerfOperation({ history: history, id: params.forModal.id, title: params.forModal.type, dateFact: "" + value!, setModal: params.setModal, setErrorTextMessage: setErrorTextMessage }); }

            }>Подтвердить</button>

            :
            params.forModal.type === "Инвестирование" ? null : null}
        </div>
        : null}
    </div>

  )
}