import {createEffect, restore} from 'effector'
import React from 'react';
import {forItem} from "../../../pages/operations/organoids/operations"
import { myAxios } from '../../../common/config/forAxios';
import { refreshToken } from '../../../common/config/accesToken';

interface IOperationQuery{
    isLoading:React.Dispatch<React.SetStateAction<boolean>>,
    dtFirst?:string ,
    dtSecond?:string,
    operationType?:string,
    isOperations?:React.Dispatch<React.SetStateAction<forItem[] | null | undefined>>,
    history:any
}

interface IOperationSimpleQuery{
    isLoading?:React.Dispatch<React.SetStateAction<boolean>>,
    dtFirst?:string ,
    dtSecond?:string,
    operationType?:string,
    setMoney?:React.Dispatch<React.SetStateAction<number|undefined|null>>,
    isOperations?:React.Dispatch<React.SetStateAction<forItem[] | null | undefined>>,
    history:any
}

export const LoadOperation = createEffect<IOperationQuery, forItem[]>();
export const LoadOperationSimple = createEffect<IOperationSimpleQuery, forItem[]>();
export const $OperationList= restore<forItem[]>(LoadOperation,null)

const calculateByType=(massiv:forItem[])=>{
        let summa=0;
    massiv?.forEach(element => {
        if(element.dt!==""){
            summa+=element.amount;
        }
    });
    return summa;
}


LoadOperation.use(async (params) => {

    var empty_pack:forItem[]=[];
    await myAxios.post('/stuff/viewOperations',{dtFirst:params.dtFirst, dtSecond:params.dtSecond, operationType:(params.operationType==="extractionsAnticipatorily")?"extractions": (params.operationType==="activeApplications") ? "":params.operationType },{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
    .then(res=>{ if(res.data.calculations!==null){
        if(params.operationType==="extractions"){
            empty_pack=res.data.calculations.filter((item: { extAnticip: string; })=>item.extAnticip!=="1")
        }
        else if(params.operationType==="activeApplications"){
            empty_pack=res.data.calculations.filter((item: { isCanceled: string,dt:string })=>(item.isCanceled!=="1" && item.dt === ''))
        }   
        else{
            if(params.operationType==="extractionsAnticipatorily"){
                empty_pack=res.data.calculations.filter((item: { extAnticip: string; })=>item.extAnticip==="1")
            }
            else{
                empty_pack=res.data.calculations
            }
        }
    }
    params.isLoading(false)
    })
    .catch((async res=>{
        if(res.response.data.httpStatus === 401){
            var newToken = await refreshToken(params.history)
            await myAxios.post('/stuff/viewOperations',{dtFirst:params.dtFirst, dtSecond:params.dtSecond, operationType:params.operationType==="extractionsAnticipatorily"?"extractions":params.operationType},{ headers:{"Authorization": "Bearer "+newToken}})
            .then(res=>{ if(res.data.calculations!==null){ 
                if(params.operationType==="extractions"){
                    empty_pack=res.data.calculations.filter((item: { extAnticip: string; })=>item.extAnticip!=="1")
                }
                else{
                    if(params.operationType==="extractionsAnticipatorily"){
                        empty_pack=res.data.calculations.filter((item: { extAnticip: string; })=>item.extAnticip==="1")
                    }
                    else{
                        empty_pack=res.data.calculations
                    }
                }
            }
            params.isLoading(false)
            })
    }}))
    return empty_pack as forItem[]
})

LoadOperationSimple.use(async (params) => {
    var empty_pack:forItem[]=[];
    await myAxios.post('/stuff/viewOperations',{dtFirst:params.dtFirst, dtSecond:params.dtSecond, operationType:params.operationType==="extractionsAnticipatorily"?"extractions":params.operationType},{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
    .then(res=>{ if(res.data.calculations!==null){
            if(params.isOperations!==undefined){
                params.isOperations!(res.data.calculations)
            }
            if(params.operationType==="extractions"){
                empty_pack=res.data.calculations.filter((item: { extAnticip: string; })=>item.extAnticip!=="1")
            }
            else{
                if(params.operationType==="extractionsAnticipatorily"){
                    empty_pack=res.data.calculations.filter((item: { extAnticip: string; })=>item.extAnticip==="1")
                }
                else{
                    empty_pack=res.data.calculations
                }
            } 
            if( params.setMoney)
                params.setMoney(calculateByType(empty_pack!))
        }
        if( params.isLoading!==undefined)
            params.isLoading!(false)
        
    }).catch(async res =>{
        if(res.response.data.httpStatus === 401){
            var newToken = await refreshToken(params.history)
            await myAxios.post('/stuff/viewOperations',{dtFirst:params.dtFirst, dtSecond:params.dtSecond, operationType:params.operationType==="extractionsAnticipatorily"?"extractions":params.operationType},{ headers:{"Authorization": "Bearer "+newToken}})
            .then(res=>{ if(res.data.calculations!==null){
                if(params.isOperations!==undefined){
                    params.isOperations!(res.data.calculations)
                }
                if(params.operationType==="extractions"){
                    empty_pack=res.data.calculations.filter((item: { extAnticip: string; })=>item.extAnticip!=="1")
                }
                else{
                    if(params.operationType==="extractionsAnticipatorily"){
                        empty_pack=res.data.calculations.filter((item: { extAnticip: string; })=>item.extAnticip==="1")
                    }
                    else{
                        empty_pack=res.data.calculations
                    }
                } 
                if( params.setMoney)
                    params.setMoney(calculateByType(empty_pack!))
            }
            if( params.isLoading!==undefined)
                params.isLoading!(false)
            })}})
    return empty_pack as forItem[]
})
$OperationList.on(LoadOperation.doneData, (_, val) => val);