import { createEffect,restore } from "effector";

interface IPathName{
    path:string
}

export const setLastPath = createEffect<IPathName,IPathName>()
export const $LastPath = restore<IPathName>(setLastPath,null);
//@ts-ignore
setLastPath.use((params:IPathName)=>{
    return (params.path)
})
$LastPath.on(setLastPath.doneData, (state, params) => params)