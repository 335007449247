import './styles/tech.css'
import LOGO from '../../common/assets/images/lionLogo.png'

export const LionTech=()=>{
    return(
        <div className='tech_div'>
             <h1>Служба поддержки LionInvest</h1>
        <p>Email: Popkov_ivan91@mail.ru</p>

 
        <img alt="" className='img_tech' src={LOGO}/>
        </div>
    )
}