
import './App.css';
import { MESSAGE_PAGE, OPERATIONS_PAGE, USERS_PAGE } from './common/config/path';
import { Switch, Route } from 'react-router-dom';
import { Header } from './common/ui/Header/organoids/Header';
import { Footer } from './common/ui/Footer/organoids/Footer';
import { AuthPage } from './pages/auth/organoids/auth';
import { UsersPage } from './pages/users/organoids/UsersPage';
import { Operations } from './pages/operations/organoids/operations';
import { MessagePage } from './pages/messages/organoids/MessagePage';
import { Privacy } from './pages/privacy/privacy';
import { LionTech } from './pages/tech/tech';
import { LionSitePage } from './pages/site/organoids/LionSitePage';
import { DayCurs } from './pages/users/molecules/DayCurs';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path={"/webabsamdinpanelpage"}>
          <AuthPage />
        </Route>
        <Route path={USERS_PAGE}>
          <Header />
          <UsersPage />


          <Footer />
        </Route>

        <Route path={OPERATIONS_PAGE}>
          <Header />
          <Operations />
          <Footer />
        </Route>

        <Route path={MESSAGE_PAGE}>
          <Header />
          <MessagePage />
          <Footer />
        </Route>
        <Route path={"/lionPrivacy"}>
          <Privacy />
        </Route>
        <Route path={"/lionTech"}>
          <LionTech />
        </Route>
        <Route path={"/"}>
          <LionSitePage />
        </Route>
      </Switch>


      <DayCurs />
      {/*окно курса дня*/}
    </div>
  );
}

export default App;
