import {createEffect, restore} from 'effector'

import React from 'react';
import { refreshToken } from '../../../common/config/accesToken';
import { myAxios } from '../../../common/config/forAxios';

export interface IUserItem{
    id: number,
    dtCreate:string,
    dtClose?: string,
    dtCreateAccount?: string,
    name1:string,
    name2:string,
    deposit:string,
    account:string,
    blockCheck:string
    email: string

}
interface IUsersQuery{
    isLoading:React.Dispatch<React.SetStateAction<boolean>>,
    history:any
}

export const LoadUsers = createEffect<IUsersQuery, IUserItem[]>();

export const $UsersList= restore<IUserItem[]>(LoadUsers,null)


LoadUsers.use(async (params) => {
    var empty_pack:IUserItem[]=[]
    await myAxios.post('/users/list',{},{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
            .then(res=>{params.isLoading(false);
                        empty_pack=res.data.members
                    })
            .catch((async res=>{
                if(res.response.data.httpStatus === 401){
                    var newToken = await refreshToken(params.history)
                    await myAxios.post('/users/list',{},{ headers:{"Authorization": "Bearer "+newToken}})
                    .then(res=>{params.isLoading(false);
                        empty_pack=res.data.members
                    })
            }}))
   
        return empty_pack as IUserItem[]
})
$UsersList.on(LoadUsers.doneData, (_, val) => val);



