import { useState } from 'react'
import { Link } from 'react-router-dom'
import PlatForm_1 from '../../../common/assets/images/Platform1.png'
import PlatForm_2 from '../../../common/assets/images/Platform2.png'
import { USERS_PAGE } from '../../../common/config/path'
import '../style/auth.css'

export const PlatformChooser = () =>{
    const [platform,setPlatform] = useState('none')
    return(
        <div>
            <span className="auth_title">Выберите платформу</span>
            <div style={{display:'flex',flexDirection:"row", justifyContent:'space-between', height:"130px", boxSizing:"border-box", margin:"20px 0"}}>
                <img onClick={()=>setPlatform('lion')} src={PlatForm_1} alt="" className={platform==='lion'?"auth_platform_border":platform==='finway'?"auth_platform_blur":"auth_platform"}></img>
                <img onClick={()=>setPlatform('finway')} src={PlatForm_2} alt=""className={platform==='finway'?"auth_platform_border":platform==='lion'?"auth_platform_blur":"auth_platform"}></img>
            </div>
            {platform==='none'?<button style={{opacity:0}} className="auth_button">Войти</button> : <Link to={USERS_PAGE}><button style={{opacity:1}} className="auth_button">Войти</button> </Link>}
        </div>
    )
}