import { useEffect, useRef, useState } from 'react'
import LONGARROW from '../../../common/assets/images/longarrow2.png'
import { ModalBlur } from '../../operations/molecules/ModalBlur';
import '../styles/score.css'
import { ExctractModal } from './ExtractModal';
import { onArrowTouch, onArrowTouchEnd, onArrowTouchStart } from '../logics/arrowTouch'
import { removeAfterPoint } from '../../../common/config/functions';
import { myAxios } from '../../../common/config/forAxios';
import { refreshToken } from '../../../common/config/accesToken';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../../common/ui/UserProvider/useUser';
import { IModal } from '../../../common/config/interfaces';

export const ScoreChange = (params: IModal) => {
    const { fullUserInfo, setFullUserInfo } = useUser()
    const [deposit, setDeposit] = useState<string>("0");
    const [score, setScore] = useState<string>("0")
    const [modal, setModal] = useState<boolean>(false)
    const history = useHistory()
    const [first, setfirst] = useState(false)
    const [pos1, setPos1] = useState(0)
    const [pos2, setPos2] = useState(0)
    const arrowblock = useRef<HTMLDivElement | null>(null)


    const setNewScore = async () => {
        await myAxios.post('/stuff/depositOrAccount',
            {
                memberId: fullUserInfo.id,
                depositNew: deposit,
                accountNew: score
            },
            { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
            .then(res => {
                setFullUserInfo({
                    id: fullUserInfo.id,
                    name: fullUserInfo.name,
                    bonusPoints: fullUserInfo.bonusPoints,
                    email: fullUserInfo.email,
                    phone: fullUserInfo.phone,
                    dtCreate: fullUserInfo.dtCreate,
                    accurals: fullUserInfo.accurals,
                    deposit: res.data.calculation.depositNew,
                    account: res.data.calculation.currentAccountNew,
                    blockCheck: fullUserInfo.blockCheck,
                    verificationStatusEmail: fullUserInfo.verificationStatusEmail,
                    verificationStatusPhone: fullUserInfo.verificationStatusPhone,
                    passNumber:  fullUserInfo.passNumber,
                    dtIssue:  fullUserInfo.dtIssue,
                    authority:  fullUserInfo.authority,
                    statusSignatura:  fullUserInfo.statusSignatura,
                    documentId: fullUserInfo.documentId

                })
            })
            .catch((async res => {
                if (res.response.data.httpStatus === 401) {
                    var newToken = await refreshToken(history)
                    await myAxios.post('/stuff/depositOrAccount',
                        {
                            memberId: fullUserInfo.id,
                            depositNew: deposit,
                            accountNew: score
                        }, { headers: { "Authorization": "Bearer " + newToken } })
                        .then(res => {
                            setFullUserInfo({
                                id: fullUserInfo.id,
                                name: fullUserInfo.name,
                                bonusPoints: fullUserInfo.bonusPoints,
                                email: fullUserInfo.email,
                                phone: fullUserInfo.phone,
                                dtCreate: fullUserInfo.dtCreate,
                                accurals: fullUserInfo.accurals,
                                deposit: res.data.calculation.depositNew,
                                account: res.data.calculation.currentAccountNew,
                                blockCheck: fullUserInfo.blockCheck,
                                verificationStatusEmail: fullUserInfo.verificationStatusEmail,
                                verificationStatusPhone: fullUserInfo.verificationStatusPhone,
                                passNumber:  fullUserInfo.passNumber,
                                dtIssue:  fullUserInfo.dtIssue,
                                authority:  fullUserInfo.authority,
                                statusSignatura:  fullUserInfo.statusSignatura,
                                documentId: fullUserInfo.documentId

                            })
                        })
                }
            }))

        params.setModal(false)
    }


    useEffect(() => {
        if (!modal) {

            if (fullUserInfo.deposit !== undefined) {
                setDeposit(removeAfterPoint(fullUserInfo.deposit.toString()))

            }
            if (fullUserInfo.account !== undefined) {
                setScore(removeAfterPoint(fullUserInfo.account.toString()))
            }
            if (params.class) {
                const divelemet = arrowblock.current;
                divelemet!.style.bottom = "0px";
            } else {
                const divelemet = arrowblock.current;
                divelemet!.style.bottom = "-100%";
            }
        }
    }, [params.class, fullUserInfo, modal])



    return (//@ts-ignore
        <div ref={arrowblock} className={params.class ? "score_main" : "score_main_close"}>
            {modal ? <ModalBlur /> : null}
            <ExctractModal class={modal} setModal={setModal} />
            <div style={{ touchAction: "none" }} onTouchStart={e => onArrowTouchStart(e, setPos2, setfirst)} onTouchMove={e => onArrowTouch(e, pos1, pos2, setfirst, first, setPos1, setPos2)} onTouchEnd={e => onArrowTouchEnd(e, params.setModal, 100)}>
                <img src={LONGARROW} alt='' style={{ height: 10 }} />
                <p style={{ color: "#E8A736", fontWeight: 500, marginBottom: "0.75rem", fontSize: "18px" }}>Изменение счета</p>
            </div>
            <div style={{ position: 'relative', margin: "0.5rem 0", height: "3rem" }}>
                <span style={{ position: 'absolute', right: "20px", top: "1rem", color: "#E8A736", zIndex: 2 }}>EUR</span>

                <fieldset style={{ border: "1px solid", height: "3rem", borderRadius: "10px", backgroundColor: "#2D3039", color: "#E8A736" }}>
                    <legend className="score_legend">
                        <span>Депозит</span>
                    </legend>
                    <input autoComplete="off" className="score_form" type="number" id="deposit" value={deposit} onChange={(e) => setDeposit(e.target.value)} />
                </fieldset>
            </div>
            <div style={{ position: 'relative', margin: "0.5rem 0", height: "2rem" }}>
                <span style={{ position: 'absolute', right: "20px", top: "1rem", color: "#E8A736", zIndex: 2 }}>EUR</span>

                <fieldset style={{ border: "1px solid", height: "3rem", borderRadius: "10px", backgroundColor: "#2D3039", color: "#E8A736" }}>
                    <legend className="score_legend">
                        <span>Текущий счет</span>
                    </legend>
                    <input autoComplete="off" className="score_form" type="number" id="deposit" value={score} onChange={(e) => setScore(e.target.value)} />
                </fieldset>
            </div>

            <button className="score_button_extract" disabled={fullUserInfo.deposit?.toString() === "0.0000" ? true : false}
                onClick={() => { setModal(true) }} style={fullUserInfo.deposit?.toString() === "0.0000" ? { opacity: 0.4 } : { opacity: 1 }}
            >Досрочный вывод</button>
            <button className="score_save_button" onClick={() => setNewScore()}>Сохранить</button>

        </div>
    )
}