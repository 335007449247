export const Privacy=()=>{
    return(
        <div style={{backgroundColor:"white", padding:"1em",textAlign:"start", overflowY:"scroll" }}>
        <h1 style={{paddingLeft:"1em"}}>Согласие на обработку персональных данных</h1>
   <p>Настоящим, действуя своей волей и в своем интересе, при размещении (вводе) своих персональных
      данных на Интернет-ресурсах Lioninvest, даю свое согласие компании «Lioninvest» (краткое наименование:
       LINordgroup OÜ), в дальнейшем "Компания", на обработку своих персональных данных, на следующих условиях:</p>
    <ul>
      <li>1. Компания осуществляет обработку моих персональных данных исключительно в целях осуществления возложенных на «Lioninvest»
         законодательством функций в соответствии с Налоговым кодексом, федеральными законами, в частности: "О противодействии
          легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма", "О валютном регулировании
           и валютном контроле", "О рынке ценных бумаг", "О персональных данных", Уставом «Lioninvest», а также в целях исполнения
            обязательств, предусмотренных договором доверительного управления/депозитарным договором, заключенным с «Lioninvest».</li>

      <li>2. Перечень персональных данных, передаваемых Компании на обработку: персональные данные, размещенные (введенные) мной
         на Интернет-ресурсах «Lioninvest», а также предоставленные мной электронные копии документов, удостоверяющих личность.</li>

      <li>3. Даю согласие на обработку Компанией своих персональных данных, указанных в п.2 настоящего Согласия, то есть на совершение,
         в том числе, следующих действий: обработку (включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
          изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование,
           удаление, уничтожение персональных данных), при этом общее описание вышеуказанных способов обработки данных приведено в
            Федеральном законе от 27.07.2006 № 152-ФЗ "О персональных данных», а также на передачу такой информации аффилированным
             или связанным с Компанией лицам, а также аффилированным или связанным лицам (так, как эти лица определены
              законодательством), а также иным лицам в случаях, установленных нормативными правовыми актами государственных регулирующих
               органов и законодательством.</li>

      <li>4. Компанией осуществляется автоматизированная обработка персональных данных. Полученная в ходе обработки персональных данных
         информация передается по внутренней сети «Lioninvest» (информация, доступна лишь для строго определенных сотрудников), а также
          передается с использованием сети общего пользования Интернет.</li>

      <li>5. Настоящее согласие действует с момента предоставления персональных данных до истечения 5 (пяти) лет со дня прекращения
         действия договора доверительного управления/ депозитарного договора, заключенного «Lioninvest».</li>

      <li>6. Настоящее согласие может быть отозвано мной в любой момент по соглашению сторон. В случае неправомерного использования
         предоставленных данных соглашение отзывается письменным заявлением субъекта персональных данных. Подтверждаю, что ознакомлен(а)
          с положениями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», права и обязанности в области защиты
           персональных данных мне разъяснены. Субъект дает согласие на обработку своих персональных данных путем проставления флага
            в поле «Даю согласие на обработку персональных данных» на Интернет-ресурсах «Lioninvest». </li>

    </ul>
    <h1 style={{paddingLeft:"1em"}}>Политика конфиденциальности «Lioninvest»</h1>

    <p>Уважаемые пользователи мобильного приложения/Сервисов Сайта,
      Просим вас внимательно ознакомиться с настоящей Политикой конфиденциальности «Lioninvest» (далее — Политика),
      чтобы получить полное представление о наших принципах и правилах, касающихся обработки «Lioninvest» предоставленной вами Информации.
      Условием использования Сайта и (или) мобильного приложения является ваше согласие с Политикой, размещенной в мобильном приложении.
      Политика разработана в соответствии с требованиями законодательства Российской Федерации и действует в отношении всей информации, 
      которую «Lioninvest» может получить о вас во время использования вами Сайта и (или) мобильного приложения.
      </p>

    <ul style={{margin:0}}>
      <li>
        1. Термины и определения
        <ul>
          <li>1.1. Информация — вся информация, в том числе, но не ограничиваясь, ваши персональные данные, как они определены
             в Федеральном законе от 27.07.2006 г. № 152-ФЗ «О персональных данных», которую «Lioninvest» может получить о вас
              в процессе пользования вами Сайта и (или) Сервисов «Lioninvest» с ваших устройств.
          </li>
          <li>1.2. Конфиденциальность информации — обязательное для выполнения лицом, получившим доступ к определенной информации,
             требование не передавать такую информацию третьим лицам без согласия ее обладателя за исключением случаев, установленных
             Политикой.
          </li>
          <li>1.3. Сайт — сайт в сети Интернет в значении, указанном в статье 2 Федерального закона от 27.07.2006 г. № 149-ФЗ 
            «Об информации, информационных технологиях и о защите информации», имеющий одно из следующих доменных имён:
             Lioninvest.club , а также иные существующие на данный момент сайты в сети Интернет, а также поддомены Сайта,
              используемые «Lioninvest» для оказания услуг, условия использования которых ссылаются на данную Политику,
               в том числе поддомены Сайта, условия использования которых ссылаются на данную Политику, а также мобильные приложения,
                правообладателем которых является «Lioninvest», доступные для загрузки на Сайте или внешних ресурсах.
          </li>
          <li>1.4. Сервисы — функциональные возможности Сайта (программы, службы, продукты, функции, интерфейсы, веб-формы),
             размещенные на Сайте.
          </li>
          <li>1.5. Услуги — услуги по осуществлению дилерской, депозитарной деятельности и деятельности по управлению ценными бумагами, услуги по инвестиционному консультированию , иные услуги, оказываемые «Lioninvest» с использованием Сайта/Сервисов.
          </li>
          <li>1.6. «Lioninvest» LINordgroup OÜ (адрес места нахождения:Tallinn ,Kesklinna Linnaosa vesivarava tn 50-201 ).
          </li>
        </ul>
      </li>
      <li>
        2. Общие положения
        <ul>
          <li>
            2.1. Целью Политики является обеспечение надлежащей защиты Информации от несанкционированного доступа и разглашения.
          </li>
          <li>
            2.2. «Lioninvest» проверяет достоверность получаемой (собираемой) Информации о вас, только если вы 
            являетесь клиентом «Lioninvest».
          </li>
          <li>
            2.3.  «Lioninvest» не создает общедоступные источники персональных данных на основе Информации о вас.
          </li>
          <li>
            2.4. В целях исполнения договора, заключенного с вами, «Lioninvest» вправе осуществлять трансграничную передачу Информации на территории иностранных государств, являющихся сторонами Конвенции Совета Европы о защите физических лиц при автоматизированной обработке персональных данных, а также иных иностранных государств, обеспечивающих адекватную защиту прав субъектов персональных данных. 
          </li>
          <li>
            2.5. В случае несогласия с условиями Политики вы должны прекратить использование Сайта и (или) Сервисов.
          </li>
        </ul>
      </li>
      <li>
        3. Цели обработки Информации 
        <ul>
          <li>
            3.1. «Lioninvest» обрабатывает Информацию для следующих целей:
            <ul>
              <li>
                упрощенная идентификация клиента, обновление сведений о клиенте на Сайте/сервисах (перечень обрабатываемой Информации указан в форме анкеты клиента);
              </li>
              <li>
                оказание Услуг, предоставление Сервисов на Сайте (перечень обрабатываемой Информации: фамилия, имя, телефон, электронный адрес, информация о том, являетесь ли налоговым резидентом России, номер счета (если вы клиент));
              </li>
              <li>
                обеспечение связи с вами, в том числе направление уведомлений, сообщений, запросов и информации, касающихся оказания услуг и (или) использования Сервисов (перечень обрабатываемой Информации: фамилия, имя, телефон, электронный адрес);
              </li>
              <li>
                проведение рекламных, маркетинговых, обучающих мероприятий, включая, но не ограничиваясь: выдача призов участникам, проведение вебинаров и онлайн-курсов,(перечень обрабатываемой Информации: фамилия, имя, телефон, электронный адрес, город, информация о том, являетесь ли вы клиентом«Lioninvest»);
              </li>
              <li>
                улучшение качества и удобства использования Сервисов (перечень обрабатываемой Информации: фамилия, имя, телефон, электронный адрес, файлы cookie);
              </li>
              <li>
                направление вам информации о продуктах и услугах «Lioninvest», в том числе, таргетированных рекламных материалов, а также реализация партнерских программ (перечень обрабатываемой Информации: фамилия, имя, телефон, электронный адрес, файлы cookie);
              </li>
              <li>
                привлечение и отбор соискателей на работу во «Lioninvest» и учет в кадровом резерве (перечень обрабатываемой Информации: фамилия, имя, электронный адрес, иные данные, указанные в резюме). «Lioninvest» не собирает с помощью Сайта или Сервисов Сайта чувствительную персональную информацию такую, как расовая принадлежность, политические взгляды, информация о здоровье и биометрические данные.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        4. Передача Информации третьим лицам
        <ul>
          <li>
            4.1. «Lioninvest» предоставляет доступ к вашим Персональным данным следующим категориям получателей:
            <ul>
              <li>
                аффилированным лицам «Lioninvest» ;
              </li>
              <li>
                лицам, которым «Lioninvest» обязано или имеет право раскрыть Информацию о вас в соответствии с требованиями законодательства Российской Федерации или условиями заключенных Российской федерацией международных договоров, а также лицам, указанным в вашем согласии на обработку персональных данных;                
              </li>
              <li>
                контрагентам «Lioninvest» в целях исполнения договора, по которому вы являетесь стороной либо выгодоприобретателем.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        5. Требования по защите Информации
        <ul>
          <li>
          5.1. Информация хранится в защищенных системах и доступна только ограниченному кругу лиц, обладающих особыми правами доступа к таким системам, и с которыми подписаны соответствующие соглашения о соблюдении конфиденциальности Информации.
          </li>
          <li>
            5.2. «Lioninvest» реализовал достаточные технические и организационные меры для защиты Информации от несанкционированного, случайного или незаконного уничтожения, потери, изменения, недобросовестного использования, раскрытия или доступа, а также иных незаконных форм обработки. Данные меры безопасности были реализованы с учетом современного уровня техники, стоимости их реализации, рисков, связанных с обработкой и характером Информации.
          </li>

        </ul>
      </li>
      <li>
        6. Хранение Информации
        <ul>
          <li>
            6.1. «Lioninvest» вправе хранить Информацию не дольше, чем этого требуют цели обработки Информации, либо до момента отзыва вами согласия на обработку персональных данных, если более длительный срок хранения Информации не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому, является субъект персональных данных.            
          </li>
          <li>
            6.2. При сборе персональных данных «Lioninvest» осуществляет запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации.
          </li>
        </ul>
      </li>
      <li>
        7. Права и обязанности
        <ul>
          <li>
            7.1. При регистрации на Сайте и (или) Сервисах Сайта вы даете согласие на обработку персональных данных в соответствии с Политикой путем проставления электронной отметки в специальном чек-боксе.
          </li>
          <li>
            7.2. Вы вправе на основании запроса (в зависимости от того, являетесь вы соискателем или пользователем иных услуг Сайта) получать от «Lioninvest» сведения, касающиеся обработки вашей Информации.
          </li>
          <li>
            7.3. Вы вправе изменять свою Информацию или вносить в нее исправления путем направления запроса (в зависимости от того, являетесь вы соискателем вакансии или пользователем иных услуг Сайта).
          </li>
          <li>
            7.4. Вы вправе инициировать ограничение обработки Информации, когда выявлена неправомерная обработка Информации.
          </li>
          <li>
            7.5. Вы имеете право потребовать ограничить обработку своей Информации в целях маркетинговых активностей «Lioninvest».
          </li>
          <li>
            7.6. Вы имеете право отозвать свое согласие на обработку персональных данных на основании соответствующего подписанного вами письменного заявления, направленного на электронную почту компании(в зависимости от того, являетесь вы соискателем вакансии или пользователем иных услуг Сайта). При этом оказание вам Услуг, предоставление Сервисов на Сайте может быть прекращено.
          </li>
          <li>
            7.7. Вы обязуетесь принимать необходимые меры для обеспечения конфиденциальности учетных данных (логин и пароль), используемых для доступа к Сервисам во избежание их компрометации.
          </li>
          <li>
            7.8. «Lioninvest» должен удалить Информацию о вас с Сайта и (или) Сервисов Сайта, при наступлении одного из следующих условий:
            <ul>
              <li>достижение целей обработки Информации;</li>
              <li>
                вы отозвали свое согласие, на основании которого проводилось обработка Информации, за исключением случаев, при которых «Lioninvest» вправе или обязано продолжить обработку Информации в соответствии с требованиями законодательства Российской Федерации;
              </li>
              <li>информация обрабатывается незаконно.</li>
            </ul>
          </li>
          <li>
            7.9. «Lioninvest» должен произвести удаление Информации о вас в своих информационных системах в течение 30 дней с момента наступления одного из условий, указанных в пункте 7.8 Политики, при этом ваши учетные записи блокируются, и из их описания удаляются персональные данные (фамилия, имя).
          </li>
          <li>
            7.10. «Lioninvest» обязан использовать полученную Информацию исключительно для целей указанных в пункте 3 Политики.
          </li>
          <li>
            7.11. «Lioninvest» обязан обеспечить хранение Информации в тайне и не разглашать ее без вашего согласия, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданной Информации, за исключением передачи Информации уполномоченным государственным органам по основаниям и в порядке, установленным законодательством Российской Федерации.
          </li>
          <li>
            7.12. «Lioninvest» обязан осуществлять обработку персональных данных в составе Информации, а также обеспечивать конфиденциальность и защиту обрабатываемой Информации в соответствии с требованиями Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных».
          </li>
        </ul>
      </li>
      <li>
        8. Ответственность
        <ul>
          8.1. «Lioninvest» и пользователи Сайта/сервисов , нарушившие свои обязательства по защите Информации, несут ответственность за ущерб, причиненный в связи с неправомерным использованием Информации (в том числе, но не ограничиваясь, неправомерным использованием персональных данных) в соответствии с законодательством Российской Федерации.
        </ul>
      </li>
      <li>
        9. Дополнительные условия
        <ul>
          9.1. «Lioninvest» приветствует ваши вопросы и предложения, касающиеся исполнения или изменения Политики. Для этого вы можете написать нам на электронный адрес.
        </ul>
      </li>
      <li>
        10. Изменение условий Политики
        <ul>
          <li>
            10.1. «Lioninvest» вправе вносить изменения в Политику.
          </li>
          <li>
            10.2. При внесении изменений в Политику, мы уведомляем об этом пользователя путем размещения новой редакции Политики на Сайте/сервисах. Продолжение пользования Сайтом, Сервисами после публикации новой редакции Политики на Сайте означает безусловное согласие пользователя с новой редакцией Политики. На пользователе лежит обязанность знакомиться с текстом Политики при каждом посещении Сайта.
          </li>
          <li>
            10.3. При размещении на Сайте новой редакции Политики предыдущая редакция Политики хранится на Сайте. Новая редакция Политики вступает в силу с момента ее размещения на Сайте/сервисе.
          </li>
        </ul>
      </li>
    </ul>
    </div>
    )
}