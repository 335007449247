import axios from "axios"
import { SetStateAction, useState } from "react"
import { FormControl } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { USERS_PAGE } from "../../../common/config/path"
// import { useUser } from "../../../common/ui/UserProvider/useUser"
import '../style/auth.css'

interface IAuthForm{
    setLogIn:React.Dispatch<React.SetStateAction<boolean>>
}

export const AuthForms = ({setLogIn}:IAuthForm) =>{
    
    const [text, setText]=useState('')
    const [password,setPassword]=useState('')
    const [error,setError]=useState<boolean>(false)
    const history = useHistory()
    //const {setUserName} =useUser()

    const onLogInClick=async ()=>{ //http://5.253.63.177:8039/stuff/signIn   https://lioninvest.eu/api/stuff/signIn",
        var fail = false
        await axios.post(
            "https://lioninvest.eu/api/stuff/signIn",
            {"e-mail":   text,
            psswrd: password},
            {    
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(async res=>{
                localStorage.setItem('AccessToken',res.data.AccessToken);
                localStorage.setItem('RefreshToken', res.data.RefreshToken);
                // setUserName(res.data.userName)
                localStorage.setItem('NameUser', res.data.userName);
               
            }).catch(res => {fail = true;setError(true)})
        if(!fail){
            history.push(USERS_PAGE)
        } 
    }
    return(
        <div>
            <span className="auth_title" >Авторизация</span>
            <div style={{position:"relative"}}>
        
                <i className="far fa-envelope auth_icon_inside"></i>
                
                <FormControl value={text} className="auth_form" placeholder="Email" onChange={(e: { target: { value: SetStateAction<string> } })=>setText(e.target.value)}/>
            </div>
            <div style={{position:"relative"}}>
                <i className="far fa-lock auth_icon_inside"></i>
                
                <FormControl value={password} className="auth_form" placeholder="Пароль" type="password" onChange={(e: { target: { value: SetStateAction<string> } })=>setPassword(e.target.value)}/>
            </div>
            <div style={!error?{height:"0px", overflow:"hidden", transition:"0.3s"}:{height:"2rem",overflow:"hidden", transition:"0.3s"}}>
                <span style={{color:"#B20000", fontWeight:700}}>Неверный логин или пароль</span>
            </div>
            <button  className="auth_button" onClick={()=>onLogInClick()}>Войти</button>
        </div>
        
    )
}