import { refreshToken } from '../../../common/config/accesToken';
import { myAxios } from '../../../common/config/forAxios';
import { forModal } from '../organoids/operations'
import { Idatas } from './OperationItem';


interface IUserSelected{
    history:any,
    id?:number,
    setForModal:React.Dispatch<React.SetStateAction<forModal>>,
    setModalLoading:React.Dispatch<React.SetStateAction<boolean>>,
    data:Idatas

}

export interface IUserData{
    bonusPoints?:number,
    cardNumber?:number,
    codeword?: string,
    dtCreate?: string,
    deposit?:string,
    email?: string,
    id?: number,
    name1?: string,
    name2?: string,
    outputSystem?:number,
    phone?:string,
    projectId?:number,
    psswrd?: string,
    reinvest?: number,
    verificationStatusEmail?: number,
    verificationStatusPhone?:number,
    wiseBIC?: string,
    wiseIBAN?: string,
    wiseOwner?: string,
}

// const [dataUsers,setDataUsers]=useState()

export const UserSelected=async (params:IUserSelected)=>{
 
  var dataUsers:any=null;
  await myAxios.post('/users/getOnId',{id:params.id},{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
  .then(res=>{
        params.setModalLoading(false)
        const datas={id:params.data?.id!,type:params.data?.type!, date:params.data?.date!, extAnticip:params.data?.extAnticip,
        money:params.data?.money!,userData:res.data.user,  memberStatus:params.data?.memberStatus,     recipientStatus:params.data?.recipientStatus, isCanceled:params.data?.isCanceled}//@ts-ignore
        params.setForModal(datas!)})
  .catch((async res=>{
      if(res.response.data.httpStatus === 401){
          var newToken = await refreshToken(params.history)
          await myAxios.post('/users/getOnId',{id:params.id},{ headers:{"Authorization": "Bearer "+newToken}})
                  .then(res=>{ params.setModalLoading(false)
                    const datas={id:params.data?.id!,type:params.data?.type!, date:params.data?.date!, extAnticip:params.data?.extAnticip,
                      money:params.data?.money!,userData:res.data.user,  memberStatus:params.data?.memberStatus,     recipientStatus:params.data?.recipientStatus, isCanceled:params.data?.isCanceled}//@ts-ignore
                      params.setForModal(datas!)})
                  }
  }))
    return dataUsers as IUserData
}