export const DateParse=(post:string)=>{
    if(post){
        var published = new Date(post);
        var d = new Date("0000-00-00 00:00:00")
        if(Number.isNaN(published.getMonth())) {
            published = new Date(post.replace(" ", "T") + "Z");
            
        }
        if(published!==d){
            var publishedStr = published.getDate().toString().padStart(2, '0') + "." + (published.getMonth() + 1).toString().padStart(2, '0') + "." + published.getFullYear(); // парсим дату в строку
            return publishedStr
        }
        else{
            return "Отсутствует"
        }
    }
    return "Отсутствует"
}