
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { refreshToken } from "../../../common/config/accesToken"
import { myAxios } from "../../../common/config/forAxios"
import "../styles/extract_modal.css"

interface IExtractModal {
    setModal: React.Dispatch<React.SetStateAction<boolean>>,
    class: boolean,
    idUser: string,
    setCloseSelected: React.Dispatch<React.SetStateAction<boolean>>,
    selectedIsClose: boolean
}

const onDeleteClickButton = async (history: any, idUser: string, setCloseSelected: React.Dispatch<React.SetStateAction<boolean>>, setModal: React.Dispatch<React.SetStateAction<boolean>>,) => {

    await myAxios.post('/stuff/deleteUser', { memberId: idUser }, { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
        .then(res => {
            setCloseSelected(false);
            setModal(false)
        })
        .catch((async res => {
            if (res.response.data.httpStatus === 401) {
                var newToken = await refreshToken(history)
                await myAxios.post('/stuff/deleteUser', { memberId: idUser }, { headers: { "Authorization": "Bearer " + newToken } })
                    .then(res => {
                        setCloseSelected(false);
                        setModal(false)
                    })
            }
        }))


}


export const DeletingUserModal = (params: IExtractModal) => {
    useEffect(() => {
        if (!params.selectedIsClose) {
            params.setModal(false)
        }
    }, [params])
    const history = useHistory()
    return (
        <div className={params.class ? "ext_modal" : "ext_modal_close"}>
            <div style={{ margin: "1rem" }}>
                <span style={{ fontWeight: 500, fontSize: "18px" }}>
                    Удаление пользователя
                </span>
            </div>


            <p style={{ color: "#E8A736a9", fontSize: "12px" }}>
                Вы действительно уверены, что хотите удалить этого пользователя? Отменить это действие будет невозможно.
            </p>


            <button className="ext_button" onClick={() => onDeleteClickButton(history, params.idUser, params.setCloseSelected, params.setModal)}>Продолжить</button>
            <button className="ext_cancel_button" onClick={() => params.setModal(false)}>Отмена</button>
        </div>

    )
}