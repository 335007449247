import axios from "axios";
import { createEffect } from "effector";
export const refreshToken = createEffect<any, string>();


refreshToken.use(async (history:any)=>{
    var newAccess =""
    await axios.post("https://lioninvest.eu/api/stuff/refresh",
    {RefreshToken:  localStorage.getItem('RefreshToken')},
    {    
        headers: {
            "Content-Type": "application/json",
        }
    }).then(res=>{
        localStorage.setItem('AccessToken', res.data.AccessToken);
        localStorage.setItem('RefreshToken', res.data.RefreshToken);
        
        newAccess = res.data.AccessToken;
   
    }).catch(res=>{
        history.push("/webabsamdinpanelpage")
    })
    return newAccess.length>0?newAccess:'fail'
})

