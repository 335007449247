
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { refreshToken } from "../../../common/config/accesToken"
import { myAxios } from "../../../common/config/forAxios"
import { IModal } from "../../../common/config/interfaces"
import { useUser } from "../../../common/ui/UserProvider/useUser"
import "../styles/extract_modal.css"


export const ExctractModal = (params: IModal) => {
    const { fullUserInfo, setFullUserInfo } = useUser()
    const [extractOpen, setExtractOpen] = useState(false)
    const [money, setMoney] = useState(0)
    const history = useHistory()

    useEffect(() => {
        if (params.class)
            setExtractOpen(false)
            setMoney(0)
    }, [params.class])
    const onExtractionAnticipatorily = async () => {
        if (!extractOpen) {
            await myAxios.post('/extractions/anticipatorilyAmount',
                { memberId: fullUserInfo.id },
                { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
                .then(res => { setMoney(res.data.availableAmount) })
                .catch((async res => {
                    if (res.response.data.httpStatus === 401) {
                        var newToken = await refreshToken(history)
                        await myAxios.post('/extractions/anticipatorilyAmount',
                            { memberId: fullUserInfo.id }, { headers: { "Authorization": "Bearer " + newToken } })
                            .then(res => { setMoney(res.data.availableAmount) })

                    }
                }))
            setExtractOpen(true)
        }
        else {

            await myAxios.post('/stuff/createExtractionAnticipatorily',
                { memberId: fullUserInfo.id },
                { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
                .then(res => {
                    setFullUserInfo({
                        id: fullUserInfo.id,
                        name: fullUserInfo.name,
                        bonusPoints: fullUserInfo.bonusPoints,
                        email: fullUserInfo.email,
                        phone: fullUserInfo.phone,
                        dtCreate: fullUserInfo.dtCreate,
                        accurals: fullUserInfo.accurals,
                        deposit: res.data.calculations.depositNew,
                        account: res.data.calculations.currentAccountNew,
                        blockCheck: fullUserInfo.blockCheck,
                        verificationStatusEmail: fullUserInfo.verificationStatusEmail,
                        verificationStatusPhone: fullUserInfo.verificationStatusPhone,
                        passNumber:  fullUserInfo.passNumber,
                        dtIssue:  fullUserInfo.dtIssue,
                        authority:  fullUserInfo.authority,
                        statusSignatura:  fullUserInfo.statusSignatura,
                        documentId: fullUserInfo.documentId
                    })
                })
                .catch((async res => {
                    if (res.response.data.httpStatus === 401) {
                        var newToken = await refreshToken(history)
                        await myAxios.post('/stuff/createExtractionAnticipatorily',
                            { memberId: fullUserInfo.id }, { headers: { "Authorization": "Bearer " + newToken } })
                            .then(res => {
                                setFullUserInfo({
                                    id: fullUserInfo.id,
                                    name: fullUserInfo.name,
                                    bonusPoints: fullUserInfo.bonusPoints,
                                    email: fullUserInfo.email,
                                    phone: fullUserInfo.phone,
                                    dtCreate: fullUserInfo.dtCreate,
                                    accurals: fullUserInfo.accurals,
                                    deposit: res.data.calculations.depositNew,
                                    account: res.data.calculations.currentAccountNew,
                                    blockCheck: fullUserInfo.blockCheck,
                                    verificationStatusEmail: fullUserInfo.verificationStatusEmail,
                                    verificationStatusPhone: fullUserInfo.verificationStatusPhone,
                                    passNumber:  fullUserInfo.passNumber,
                                    dtIssue:  fullUserInfo.dtIssue,
                                    authority:  fullUserInfo.authority,
                                    statusSignatura:  fullUserInfo.statusSignatura,
                                    documentId: fullUserInfo.documentId

                                })
                            })
                    }
                }))
            params.setModal(false)
        }

    }
    return (

        <div className={params.class ? "ext_modal" : "ext_modal_close"} style={{ overflow: 'hidden' }}>
            <div style={{ margin: "1rem" }}>
                <span style={{ fontWeight: 500, fontSize: "18px" }}>
                    Досрочный вывод
                </span>
            </div>
                    <div style={{  position:"relative", height:"100px"}}>
                        <p  className="ext_modal_p" style={!extractOpen ? {left:0, right:0} : {left:"-100%", right:'100%' }}>
                            Вы действительно уверены? При досрочном расторжении договора проценты пользователя сгорают и/или высчитываются при их снятии из тела инвестиций.
                        </p>
                    
                        <p  className="ext_modal_p" style={!extractOpen ? {left:"100%", right:'-100%',color:"#E8A736" } : {left:0, right:0,color:"#E8A736"} }>
                            Сумма вывода составляет: 
                            <div style={{ position: 'relative', margin: "0.5rem 0", height: "3rem" }}>
                                <span style={{position:'absolute', right:"20px", top:"1.3rem", color:"#E8A736",  opacity:"0.4", zIndex:2}}>EUR</span>

                                <fieldset style={{ border: "1px solid", height: "3rem", borderRadius: "10px", backgroundColor: "#2D3039", color: "#E8A736", opacity:"0.4" }}>
                                    <legend className="score_legend">
                                        <span>Депозит</span>
                                    </legend>
                                    <input autoComplete="off" className="score_form" type="number" id="deposit" value={money} disabled />
                                </fieldset>
                            </div>
                        </p>
                    </div>
              
          

            <button className="ext_button" onClick={() => onExtractionAnticipatorily()}>{!extractOpen?"Продолжить":"Вывести"}</button>
            <button className="ext_cancel_button" onClick={() => params.setModal(false)}>Отмена</button>



        </div>



    )
}