export const onArrowTouchEnd=(e:React.TouchEvent<HTMLDivElement>, close:React.Dispatch<React.SetStateAction<boolean>>, endscore:number)=>{
    if(parseInt(e.currentTarget.parentElement!.style.bottom.replace(/px/,""))<-endscore){
        e.currentTarget.parentElement!.style.bottom="-100%";
        close(false)
    }
    else{
        e.currentTarget.parentElement!.style.bottom="0px";
    }
}
export  const onArrowTouch=(e:React.TouchEvent<HTMLDivElement>, pos1:number,
    pos2:number,setfirst:React.Dispatch<React.SetStateAction<boolean>>,
    first:boolean, setPos1: React.Dispatch<React.SetStateAction<number>>,
    setPos2: React.Dispatch<React.SetStateAction<number>>)=>{
 
   if(first){
       setfirst(false)
       setPos1(0);
   }
   else{
       if(pos2===0){
           setPos2(e.touches[0].clientY);
       }
       setPos1(pos2 - e.touches[0].clientY);
   }
   if( (parseInt(e.currentTarget.parentElement!.style.bottom.replace(/px/,""))+pos1)<0){
       setPos2(e.touches[0].clientY);
       e.currentTarget.parentElement!.style.bottom= (parseInt(e.currentTarget.parentElement!.style.bottom.replace(/px/,""))+pos1)+"px";
   }

}
export  const onArrowTouchStart=(e:React.TouchEvent<HTMLDivElement>,setPos2:React.Dispatch<React.SetStateAction<number>>,setfirst:React.Dispatch<React.SetStateAction<boolean>>)=>{
    setPos2(e.touches[0].clientY);
    if(!e.currentTarget.parentElement!.style.bottom){
        e.currentTarget.parentElement!.style.bottom="0px";
    }
    setfirst(true)

}