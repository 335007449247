import { useStore } from 'effector-react'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import RUB from '../../../common/assets/images/ruble.png'
import { refreshToken } from '../../../common/config/accesToken'
import { myAxios } from '../../../common/config/forAxios'
import { $ModalShow, setModalShow } from '../logics/DayCursLogic'
import '../styles/daycurs.css'



export const DayCurs = ( ) => {

    const modalShow =useStore($ModalShow)

    const [money, setMoney] = useState<any>(0)
    const [showError,setShowError]=useState<boolean>(false)
    const history = useHistory()
    const arrowblock = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        onChangeFunc("0")
    }, [])
    useEffect(() => {
        if (modalShow.open) {
            const divelemet = arrowblock.current;
            divelemet!.style.top = "50%";
            setMoney(modalShow.quantity.replace(".",","))

        } else {
            const divelemet = arrowblock.current;
            divelemet!.style.top = "-100%";
        }
    }, [modalShow])
    const onChangeFunc = (e: string) => {
        if (e.replace(/[0-9,]/g, '').length === 0) {
            e = e.split(',').join('');
            e = e.replace(/^0+(?=(\d*))/i, "")
            while (e.length < 3) {
                e = "0" + e;
            }
            var newstr = e.replace(/\d+(?=(\d{2})+(?!\d))/, '$&,')
            setMoney(newstr)
        }
    }

    const onSaveButton = async () => {
        await myAxios.post('/stuff/setMoneyRate', { rubRate: money.replace(',', ".") }, { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
            .catch((async res => {
                if (res.response.data.httpStatus === 401) {
                    var newToken = await refreshToken(history)
                    await myAxios.post('/stuff/setMoneyRate', { rubRate: money.replace(',', ".") }, { headers: { "Authorization": "Bearer " + newToken } })
                }
            }))
        setModalShow({open:false,actual:true,quantity:money.replace(',', ".")})
    }
  
    const onCloseClick=()=>{
        if($ModalShow.getState().actual){
            setModalShow({open:false,actual:true,quantity:money.replace(',', ".")})
            setShowError(false)
        }
        else{
            setShowError(true)
        }
    }
    return (
        <div className={modalShow.open ? 'day_curs_blur' : ''}>
            <div ref={arrowblock} className="day_curs_main">
            {$ModalShow.getState().actual?<i className="fas fa-times" style={{color:'#E8A736a9', position:'absolute', right:"10%", top:"7%"}} onClick={()=>onCloseClick()}></i>:null}
                <span style={{ fontWeight: 500 }}>Курс дня</span>
                <span style={{ color: "#E8A736a9", margin: '1rem' }}>Введите стоимость 1 EUR</span>
                <div style={{ position: 'relative', margin: "0", height: "3rem" }}>
                    <span style={{ position: 'absolute', right: "20px", top: "0.75rem", color: '#E8A736a9', zIndex: 1 }}><img src={RUB} alt="" style={{ height: "14px" }} /></span>

                    <fieldset style={{ border: "1px solid", height: "3rem", borderRadius: "10px", backgroundColor: "#2D3039" }}>
                        <legend className="day_curs_legend">
                            <span>RUB</span>
                        </legend>
                        <input className="day_curs_form" type="text" id="id_partner" value={money} onChange={(e) => onChangeFunc(e.target.value)} />
                    </fieldset>

                </div>
                <button className="day_curs_button" onClick={() => onSaveButton()}>Сохранить</button>
                <div style={showError?{height:"100%",overflow:'hidden' }:{height:"0px",overflow:'hidden'}}>
                    <span style={{color:"#B20000"}}>Курс на сегодня еще не установлен</span>
                </div>
            </div>
        </div>
    )
}
