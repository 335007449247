import { useEffect, useRef, useState } from 'react'
import LONGARROW from '../../../common/assets/images/longarrow2.png'
import '../styles/score.css'
import { onArrowTouch, onArrowTouchEnd, onArrowTouchStart } from '../logics/arrowTouch'
import { AddNewPartner } from '../logics/partnersLogic'
import { useHistory } from 'react-router-dom'
import { useUser } from '../../../common/ui/UserProvider/useUser'
import { IModal } from '../../../common/config/interfaces'

export const AddPartner = (params: IModal) => {
    const { fullUserInfo } = useUser()
    const [id, setID] = useState("0")

    const [first, setfirst] = useState(false)
    const [pos1, setPos1] = useState(0)
    const [pos2, setPos2] = useState(0)
    const arrowblock = useRef<HTMLDivElement | null>(null)
    const history = useHistory()


    useEffect(() => {
        if (params.class) {
            const divelemet = arrowblock.current;
            divelemet!.style.bottom = "0px";
        } else {
            const divelemet = arrowblock.current;
            divelemet!.style.bottom = "-100%";
        }
    }, [params.class])

    const focus = () => {
        AddNewPartner({ id: fullUserInfo.id, referalId: id, history: history })
        setID("0");
        params.setModal(false);
    }
    return (
        <div ref={arrowblock} className={params.class ? "score_main" : "score_main_close"}>
            <div style={{ touchAction: "none" }} onTouchStart={e => onArrowTouchStart(e, setPos2, setfirst)} onTouchMove={e => onArrowTouch(e, pos1, pos2, setfirst, first, setPos1, setPos2)} onTouchEnd={e => onArrowTouchEnd(e, params.setModal, 70)}>
                <img src={LONGARROW} alt='' style={{ height: 10 }} />
                <p style={{ color: "#E8A736", fontWeight: 500, marginBottom: "0.75rem", fontSize: "18px" }}>Добавить партнера</p>
            </div>
            <div style={{ position: 'relative', margin: "0", height: "3rem" }}>

                <fieldset style={{ border: "1px solid", height: "3rem", borderRadius: "10px", backgroundColor: "#2D3039", color: "#E8A736" }}>
                    <legend className="score_legend">
                        <span>ID партнера</span>
                    </legend>
                    <div >
                        <input className="score_form" type="text" id="id_partner" value={id} onChange={(e) => setID(e.target.value)} />
                    </div>
                </fieldset>

            </div>
            <button className="score_save_button" onClick={() => focus()} >Добавить</button>
        </div>
    )
}