import { useEffect, useRef, useState } from 'react'
import { ModalBlur } from '../../operations/molecules/ModalBlur'
import { AddPartner } from '../molecules/AddPartner'
import { ModalUsers } from '../molecules/ModalUser'
import { ScoreChange } from '../molecules/ScoreChange'
import { StatusScore } from '../molecules/StatusScore'
import { UserInfo } from '../molecules/UserInfo'
import { UserSelected } from '../molecules/UserSelected'
import { UsersTable } from '../molecules/users_table'
import '../styles/UsersPage.css'
import { useHistory } from 'react-router'
import { useStore } from 'effector-react'
import { $UsersList, LoadUsers } from '../logics/LoadUsers'
import { Dropdown, Spinner } from 'react-bootstrap'
import { myAxios } from '../../../common/config/forAxios'
import { refreshToken } from '../../../common/config/accesToken'
import { useUser } from '../../../common/ui/UserProvider/useUser'
import { setModalShow } from '../logics/DayCursLogic'
import { LoadMessages } from '../../messages/logics/MessagesLoad'
import moment from 'moment'
export interface forModal {
    id: number,
    deposit: number,
    account: number
}


export const UsersPage = () => {
    const { fullUserInfo } = useUser()
    const [isScrolling, setIsScrolling] = useState<boolean>(false);
    const [userSelected, setUserSelected] = useState<boolean>(false)
    const [userInfo, setUserInfo] = useState<boolean>(false)
    const [stats, setStats] = useState<boolean>(false);
    const [score, setScore] = useState<boolean>(false);
    const [partner, setPartner] = useState<boolean>(false);
    const [statusScore, setStatusScore] = useState<boolean>(false);
    const [forModal, setForModal] = useState<forModal>({} as forModal)
    let historyMess = useHistory()
    // eslint-disable-next-line
    const [isLoadingMess, setLoadingMess] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const history = useHistory();
    const searchRef = useRef<HTMLInputElement | null>(null);

    const [filter, setFilter] = useState('')

    const rows = useStore($UsersList)
    const [tablerows, setTableRows] = useState(rows)

    const operTypes = ["Все пользователи","Активные пользователи","Неактивные пользователи"]
    const [activeSelect, setactiveSelect] = useState("")

    useEffect(() => {
        var day = new Date()
        if (Number.isNaN(day.getMonth())) {
            day = new Date(moment().format().replace(" ", "T") + "Z");
            console.log(day)
        }
        if(!tablerows && rows)
        setTableRows(rows.filter((e)=> 
        ((e.dtCreate!=="" && e.dtClose!=="" && new Date(e.dtClose!.replace(" ", "T") + "Z")>=day) ||
        (e.dtCreate!=="" && e.dtClose==="") ||
        (e.dtCreate==="" && e.dtCreateAccount!=="" && new Date(e.dtCreateAccount!.replace(" ", "T") + "Z")>=day) 
        )&& (e.blockCheck=="0" || !e.blockCheck)
        ))
    }, [rows])



    const activeUserNoActive = (typeActive: any) => {       ///фильтр по активным и нет пользователям
        setFilter('')
        if(rows){
        var day = new Date()
        if (Number.isNaN(day.getMonth())) {
            day = new Date(moment().format().replace(" ", "T") + "Z");
            console.log(day)
        }
            if(typeActive=="Все пользователи")
            {   
                setTableRows(rows)
            }
            else if(typeActive=="Активные пользователи"){
            day.setDate(day.getDate()-30)
            setTableRows(rows.filter((e)=> 
                            ((e.dtCreate!=="" && e.dtClose!=="" && new Date(e.dtClose!.replace(" ", "T") + "Z")>=day) ||
                            (e.dtCreate!=="" && e.dtClose==="") ||
                            (e.dtCreate==="" && e.dtCreateAccount!=="" && new Date(e.dtCreateAccount!.replace(" ", "T") + "Z")>=day) 
                            )&& (e.blockCheck=="0" || !e.blockCheck)
                            ))
            }
            else if(typeActive=="Неактивные пользователи"){
                day.setDate(day.getDate()-30)
                setTableRows(rows.filter((e)=>
                (e.dtCreate!="" && e.dtClose!="" && new Date(e.dtClose!.replace(" ", "T") + "Z")<day) ||
                ((e.dtCreate=="" || !e.dtCreate) && e.dtCreateAccount!="" && new Date(e.dtCreateAccount!.replace(" ", "T") + "Z")<day) 
                || e.blockCheck==="2" || e.blockCheck==="1"
                            ))
                }

            }
        console.log("typeActive",typeActive)
    };
    useEffect(() =>{
        console.log("tablerows",tablerows)
    },[tablerows,activeSelect])



    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value)
        const newarray = rows?.filter(function (item) {
            return (item.id?.toString().indexOf(e.currentTarget.value.toLocaleLowerCase()) > -1 ||
                (item.name1?.toLowerCase() + item.name2?.toLowerCase()).indexOf(e.currentTarget.value.toLocaleLowerCase().replace(" ", "")) > -1 ||
                item.deposit?.toString().indexOf(e.currentTarget.value.toLocaleLowerCase()) > -1 ||
                item.email?.toString().indexOf(e.currentTarget.value.toLocaleLowerCase()) > -1 ||
                item.dtCreate?.toString().indexOf(e.currentTarget.value.toLocaleLowerCase()) > -1)
        })
        if (newarray)
            setTableRows(newarray)
    }
    const refer = useRef<HTMLDivElement>(null);
    const movescreen = () => {
        const divelemet = refer.current;
        if(divelemet)
            divelemet.style.left = "0%";
    }
    //Установка курса дня
    useEffect(() => {
        const loadCurs = async () => {
            await myAxios.post('/stuff/getMoneyRate', {}, { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
                .then(res => {
                    var published = new Date(res.data.dtFrom)
                    if (Number.isNaN(published.getMonth())) {
                        published = new Date(res.data.dtFrom.replace(" ", "T") + "Z");
                    }
                    if (new Date().getDate() !== new Date(published).getDate()) {
                        console.log("hello")
                        setModalShow({open:true,actual:false, quantity:res.data.paramValue})
                    }
                    else{
                        setModalShow({open:false,actual:true, quantity:res.data.paramValue})
                    }
                })
                .catch((async res => {
                    if (res.response.data.httpStatus === 401) {
                        var newToken = await refreshToken(history)
                        await myAxios.post('/stuff/getMoneyRate', {}, { headers: { "Authorization": "Bearer " + newToken } })
                            .then(res => {
                                var published = new Date(res.data.dtFrom)
                                if (Number.isNaN(published.getMonth())) {
                                    published = new Date(res.data.dtFrom.replace(" ", "T") + "Z");

                                }
                                if (new Date().getDate() !== new Date(published).getDate()) {
                                    console.log("hello")
                                    setModalShow({open:true,actual:false, quantity:res.data.paramValue})
                                }
                                else{
                                    setModalShow({open:false,actual:true, quantity:res.data.paramValue})
                                }
                            })
                    }
                }))
        }
        loadCurs()
    }, [history])

    useEffect(() => {
        setLoading(true)
        LoadUsers({ isLoading: setLoading, history: history })
        LoadMessages({setIsLoading:setLoadingMess, history:historyMess})
        const divelemet = refer.current;//@ts-ignore
        divelemet!.style.left = "-100%";//@ts-ignore
        setTimeout(movescreen, 10);
        // eslint-disable-next-line
    }, [fullUserInfo, history])
    useEffect(() => {
        searchRef.current?.blur()
    }, [userSelected])

    return (
        <>
            <div style={{ flex: 1, position: 'relative' }}>
                {stats || userInfo || score || partner || statusScore ? <ModalBlur /> : null}
                <div style={{ position: 'absolute', minHeight: "70vh" }} className="users_main" ref={refer}>
                    <p className="users_title">Пользователи</p>
                    <div style={{ position: 'relative' }}>
                        <i className="fas fa-search users_icon_pos"></i>
                        <input ref={searchRef} className="users_form" type="text" placeholder="Поиск..." value={filter} onChange={e => onSearch(e)} />
                    </div>

                    {/* <select className="user_users_status_select" onChange={(e) =>{}}> */}
                    {/* {StatusDoc.map((e:any)=>{
                        <option value={e.value}>{e.name}</option>
                    })} */}
                    {/* <option selected value="all">Все</option>
                    <option value="active">Активные пользователи</option>
                    <option value="noactive">Неактивные пользователи</option>
                    </select> */}

                <div className="oper_dropmenu_activ_user" style={{ padding: 0, height: "4%",boxShadow: "none"}}>
                    <Dropdown >
                        <Dropdown.Toggle variant="default" id="dropdown-basic" className="oper_dropmenu_toggle_userSelect" style={{boxShadow: "none"}}>
                            {activeSelect?activeSelect:"Активные пользователи"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ backgroundColor: '#2D3039', width: "100%", minWidth: "230px",    margin:"-2% -5%",boxShadow: "none"}}>
                            {operTypes.map((e, i) => {
                                return <Dropdown.Item className="selectOper" style={{ color: '#9B9B9B',boxShadow: "none" }} 
                                onClick={() => {setactiveSelect(e);activeUserNoActive(e)}} key={i}>{
                                    e !== "" ?
                                    e
                                        : "Активные пользователи"
                                }</Dropdown.Item>
                            })}
                        </Dropdown.Menu>
                    </Dropdown>

                </div>

                    {isLoading ?
                        <div style={{ position: "absolute", left: 0, right: 0, top: "50%", textAlign: "center", zIndex: 2 }}>
                            <Spinner animation="border" variant="light" />
                        </div> :
                        <UsersTable IsScrolling={isScrolling} setIsScrolling={setIsScrolling} userSelect={setUserSelected} setForModal={setForModal} rows={tablerows ? tablerows : []} />}
                </div>
            </div>
           
            <UserSelected
                forModal={forModal} //id,deposit,account - эти три параметра не приходит из getOnId !!!!!! ПРИХОДЯТ
                setStatusScore={setStatusScore} //хук, открывающий окно с статус баллами
                setScore={setScore}//хук, открывающий окно с депозитом и счетом
                setPartner={setPartner} //хук, открывающий окно с добавлением партнера
                setInfo={setUserInfo} //хук, открывающий окно с информацией о пользователе
                setStats={setStats}//хук, открывающий окно с статистикой
                userSelect={setUserSelected} selected={userSelected} //хук, открывающий само окно модалки
            />
            <ModalUsers setModal={setStats} class={stats} />     {/*окно статистики*/}
            <UserInfo setModal={setUserInfo} class={userInfo} />     {/*окно "информация"*/}
            <ScoreChange setModal={setScore} class={score} />     {/*окно депозита и счета*/}
            <AddPartner setModal={setPartner} class={partner} />     {/*окно добавления партнера*/}
            <StatusScore setModal={setStatusScore} class={statusScore} />     {/*окно статус баллов*/}
        </>
    )
}