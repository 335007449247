import { createEvent, createStore } from "effector";

interface IDayCurs{
    open:boolean
    actual:boolean
    quantity:string
}
export const $ModalShow = createStore<IDayCurs>({open:false,actual:false,quantity:"0"});

export const setModalShow = createEvent<IDayCurs>()

$ModalShow.on(setModalShow, (state, params) => params)