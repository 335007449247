import PLUS from '../../../common/assets/images/Plus.png'
import ARROW from '../../../common/assets/images/arrowdown.png'
import EXCHANGE from '../../../common/assets/images/exchange.png'
import INVEST from '../../../common/assets/images/invest.png'
import ENV from '../../../common/assets/images/env.png'
import ADMIN from '../../../common/assets/images/adminEdit.png'
import ADMINDOTS from '../../../common/assets/images/adminDots.png'
import { useEffect, useState } from 'react'
import '../styles/stats.css'
import { removeAfterPoint } from '../../../common/config/functions'
import { DateParse } from '../../messages/logics/MessageDateParse'

export interface IStatsItem{
    [index:string]:string|Date|number|boolean,
    type:string,
    date:string,
    deposit:string,
    currentAccount:string,
    id:string,
    modalActive:boolean,
    partnerId:string,
    memberId:number,
    TransferFromId:string
}

const returnType=(type:string)=>{
    switch(type){
        case 'adjunctionToDeposit':
            return {title:"Пополнение счета",icon:PLUS,color:'#149000'}
        case 'accrualDaily':
            return {title:"Дивиденды",icon:ENV,color:'#E8A736'}
        case 'accrualForPartner':
            return {title:"Партнёрские",icon:ENV,color:'#E8A736'}
        case 'autoReinvest':
            return {title:"Автореинвестирование",icon:INVEST,color:'#fff'}
        case 'changeByAdmin':
            return {title:"Изменение администратором",icon:ADMIN,color:'#C9C9C9'}
        case 'extraction':
            return {title:"Вывод средств",icon:ARROW,color:'#B20000'}
        case 'extractionAnticipatorily':
            return {title:"Досрочный вывод средств",icon:ARROW,color:'#B20000'}
        case 'investment':
            return {title:"Инвестирование",icon:INVEST,color:'#fff'}
        case 'transferToMember':
            return {title:"Перевод",icon:EXCHANGE,color:'#B20000'}
        default:
             return {title:"Неизвестно",icon:PLUS,color:'#B20000'}
    }
}
export const StatsItem=(params:IStatsItem)=>{
    const [isWrapped,setIsWrapped]=useState(false)
    useEffect(()=>{
        setIsWrapped(false)
    },[params.modalActive])
    return(
        <div className='stats_item' style={isWrapped?{backgroundColor:"#272A32"}:{backgroundColor:"rgba(0,0,0,0)"}}>
            <div className='stats_item_upper_part' style={isWrapped?{backgroundColor:"#272A32"}:{backgroundColor:"rgba(0,0,0,0)"}}>
                <img style={{height:"30px",margin:"auto 1rem auto 0"}} 
                src={returnType(params.type).icon}
                alt=""/>
                <div style={{display:'flex', flexDirection:'column', fontSize:'12px', textAlign:'start', flex:4}}>
                    <span style={{color:'#C9C9C9'}} >{DateParse(params.date)}</span>
                    <span style={{color:'white', fontWeight:300}}>{returnType(params.type).title}&nbsp;
                        {returnType(params.type).title==="Перевод"?
                        <span>{params.partnerId===params.memberId.toString()?"от #"+params.TransferFromId:"на #"+params.partnerId}</span>:
                        null
                        }
                    </span>
                </div>
                <div style={{display:'flex', flexDirection:'column',fontSize:'12px', textAlign:'end', flex:2}}>
                    <span style={{color:'#C9C9C9'}}>#{params.id}</span>
                    <span style={
                        params.type==="transferToMember"&& params.deposit!=="0" && params.currentAccount[0]!=="-"?
                        {color:"#149000", fontWeight:700}:
                        {color:returnType(params.type).color, fontWeight:700}
                    }>
                        { params.type==="transferToMember"&& params.deposit!=="0" && params.currentAccount[0]!=="-"?"+":null}
                        {
                        returnType(params.type).color==='#B20000'?"":
                        returnType(params.type).color==='#149000'||returnType(params.type).color==='#E8A736'? "+":null}
                        {
                            params.type==='changeByAdmin'?<button className='stats_button' onClick={()=>setIsWrapped(!isWrapped)} ><img src={ADMINDOTS} alt="" style={{height:"4px"}}/></button>:
                            <span style={{whiteSpace: "nowrap"}}>{removeAfterPoint(
                                params.type==="adjunctionToDeposit" || params.type==="autoReinvest"|| params.type==="investment"
                                 || (params.type==="transferToMember"&& params.deposit!=="0")?
                                params.deposit: params.type==="transferToMember"?params.currentAccount.replace(/-/g,""):params.currentAccount
                        
                                )}&nbsp;<i className="fas fa-euro-sign" ></i></span>
                            }
                        </span>
                </div>
            </div>
            <div className='stats_item_lower_part' style={isWrapped?{height:"70px", backgroundColor:"#272A32"}:{height:"0",backgroundColor:"rgba(0,0,0,0)"}}>
               <div style={{marginTop:"0.5rem", display:'flex',flexDirection:'row', justifyContent:"space-between"}}>
                    <span style={{color:'white', fontWeight:300,fontSize:"12px",paddingLeft:"3rem"}}>Депозит</span>
                    <span style={parseInt(params.deposit)>0?{color:"#149000",fontWeight:700,fontSize:"12px", whiteSpace: "nowrap"}:{color:"#B20000",fontWeight:700,fontSize:"12px", whiteSpace: "nowrap"}}>{parseInt(params.deposit)>0?"+":null}{removeAfterPoint(params.deposit)}</span>
                </div>
                <div style={{marginTop:"0.5rem",display:'flex',flexDirection:'row', justifyContent:"space-between"}}>
                    <span style={{color:'white', fontWeight:300,fontSize:"12px",paddingLeft:"3rem"}}>Текущий счет</span>
                    <span style={parseInt(params.currentAccount)>=0?{color:"#E8A736", fontWeight:700,fontSize:"12px", whiteSpace: "nowrap"}:{color:"#B20000", fontWeight:700,fontSize:"12px", whiteSpace: "nowrap"}}>
                        {parseInt(params.currentAccount)>0?"+":null}
                        {parseInt(params.currentAccount)===0?removeAfterPoint(params.currentAccount).replace(/-/g,""):removeAfterPoint(params.currentAccount)}</span>
                </div>
            </div>
        </div>

    )
}