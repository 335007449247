import USERS from '../../../assets/images/users.png'
import USERS_FILLED from '../../../assets/images/users_filled.png'
import MESSAGE from '../../../assets/images/message.png'
import MESSAGE_FILLED from '../../../assets/images/message_filled.png'
import MONEY from '../../../assets/images/money.png'
import MONEY_FILLED from '../../../assets/images/money_filled_second.png'
import REDELLIPSER from '../../../assets/images/Ellipse_Red.png'
import '../style/Footer.css'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { setLastPath } from '../../pathhistory'
import { useStore } from 'effector-react'
import {$OperationList, LoadOperationSimple} from '../../../../../src/pages/operations/logics/LoadOperations'
import { forItem  } from '../../../../../src/pages/operations/organoids/operations'
import { $messages } from '../../../../pages/messages/logics/MessagesLoad'
import { $flagReadMess, setFlagReadMess } from '../../../../pages/messages/molecules/MessageItem'
import { MESSAGE_PAGE, OPERATIONS_PAGE, USERS_PAGE } from '../../../config/path'


export const Footer = () =>{
    const [select,setSelected]=useState('users')
    const history = useHistory();
    const [operations,setOperations]=useState<forItem[]|null>()
    const operationsList = useStore($OperationList)

    const [summNotReadMess,setSummNotReadMess] = useState<number>(0)
    const MessageList = useStore($messages)
    const flagReadMess = useStore($flagReadMess)
    // const [messages,setMessages]=useState(MessageList)

    // useEffect(()=>{
    //     setMessages(MessageList)
    // },[MessageList])
   
    var summNotDate = 0;
    operations?.forEach((oper, i)=>{
        if(oper.dt===''&&oper.isCanceled!=='1'){
            summNotDate+=1;
        }
    });

    useEffect(()=>{
        var promSummNotRead=0;
    MessageList?.forEach((mess, i)=>{
        if(mess.MessageRead===0){    
            promSummNotRead+=1
        }
    });
    setSummNotReadMess(promSummNotRead);
    // eslint-disable-next-line
    },[MessageList])
    useEffect(()=>{
        if(flagReadMess===true){
        setSummNotReadMess(summNotReadMess-1);
        setFlagReadMess(false)
        }
        // eslint-disable-next-line
    },[flagReadMess])


    useEffect(()=>{
        setSelected(window.location.pathname)
         LoadOperationSimple({history:history, operationType:"",
            dtFirst:"2021-09-01 00:00:00",
            isOperations:setOperations
            })
        // eslint-disable-next-line
    },[window.location.pathname,operationsList])

    return(
        <div className="footer_main">
            
            <div className="footer_block_dot" style={select===USERS_PAGE?{left:"10%"}:select===OPERATIONS_PAGE?{left:0, right:0, margin: "0 auto", textAlign:'center'}:{right:"10%"}}>
                <div className="footer_dot"></div>
            </div>
            
            <Link to={USERS_PAGE} style={{color:"#2D3039"}}>
                <div onClick={()=>setLastPath({path:history.location.pathname})} className="footer_icon" style={select===USERS_PAGE?{color:'white'}:{color:"#444957"}}>
                    <img src={select===USERS_PAGE?USERS_FILLED:USERS} alt="" className="footer_img"/>
                    <span>Пользователи</span>
                </div>
            </Link>
            <Link to={OPERATIONS_PAGE} style={{color:"#2D3039"}}>
                <div onClick={()=>setLastPath({path:history.location.pathname})} 
                className="footer_icon" style={select===OPERATIONS_PAGE?{color:'white'}:{color:"#444957"}}>
                    <img src={select===OPERATIONS_PAGE?MONEY_FILLED:MONEY} alt="" className="footer_img"/>
                    {summNotDate!==0?<>
                    <div className='footer_redEllipse_div'>
                        <span className="footer_img_ellipseRed_textSumm">{summNotDate}</span>
                        <img src={REDELLIPSER} 
                    alt="" className="footer_img_ellipseRed"/>
                        </div>
                    </>:null}
                    <span>Операции</span>
                </div>
            </Link>
            <Link to={MESSAGE_PAGE} style={{color:"#2D3039"}}>
                <div onClick={()=>setLastPath({path:history.location.pathname})} className="footer_icon" style={select===MESSAGE_PAGE?{color:'white'}:{color:"#444957"}}>
                    <img src={select===MESSAGE_PAGE?MESSAGE_FILLED:MESSAGE} alt="" className="footer_img"/>
                    {summNotReadMess!==0?<>
                    <div className='footer_redEllipse_div'>
                        <span className="footer_img_ellipseRed_textSumm">{summNotReadMess}</span>
                        <img src={REDELLIPSER} 
                    alt="" className="footer_img_ellipseRed"/>
                        </div>
                    </>:null}
                    <span>Сообщения</span>
                </div>
            </Link>
        </div>
    )
}