import {createEffect, restore} from 'effector'
import { myAxios } from '../../../common/config/forAxios';
import { refreshToken } from '../../../common/config/accesToken';

export interface ICalculation{
    [index:string]:string|number,
    adjunctionId: number,
    currentAccountNew: number,
    currentAccountOld: number,
    depositNew: number,
    depositOld: number,
    dt: string,
    dtISO: string,
    extractionId: number,
    id: number,
    investmentId: number,
    memberId: number,
    operationTypeSignatura: string,
    transferId: number,
    partnerId:string,
    TransferFromId:string
}

interface ICalculationQuery{
    history:any,
    id:number
}
export const LoadCalculations = createEffect<ICalculationQuery, ICalculation[]>();

export const $CalculationList= restore<ICalculation[]>(LoadCalculations,null)


LoadCalculations.use(async (params) => {
    var empty_pack:ICalculation[]=[]
    await myAxios.post('/users/getAllCalculationsOnUserId',{ id:params.id},{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
            .then(res=>{  empty_pack=res.data.calculations})
            .catch((async res=>{
                if(res.response.data.httpStatus === 401){
                    var newToken = await refreshToken(params.history)
                    await myAxios.post('/users/getAllCalculationsOnUserId',{id:params.id},{ headers:{"Authorization": "Bearer "+newToken}})
                    .then(res=>{  empty_pack=res.data.calculations})
            }}))
        return empty_pack as ICalculation[]
})
$CalculationList.on(LoadCalculations.doneData, (_, val) => val);



