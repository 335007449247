import LOGO from '../../../common/assets/images/Лого.png'
import PEN from '../../../common/assets/images/pen.png'
import PEN_WHITE from '../../../common/assets/images/pen_white.png'
import ICON_1 from '../../../common/assets/images/icon_1.png'
import ICON_2 from '../../../common/assets/images/icon_2.png'
import ICON_4 from '../../../common/assets/images/icon_4.png'
import LOCK from '../../../common/assets/images/lock.png'
import UNLOCK from '../../../common/assets/images/unlock.png'
import '../styles/UserSelected.css'
import { Partner } from '../atoms/partner'
import { forModal } from '../organoids/UsersPage'
import { useEffect, useState } from 'react'
import { refreshToken } from '../../../common/config/accesToken'
import { DateParse } from '../../messages/logics/MessageDateParse'
import { $PartnersList, AddNewPartner, DeletePartner, LoadPartners, setLevelTwoReferal, $LevelTwoReferal } from '../logics/partnersLogic'
import { removeAfterPoint } from '../../../common/config/functions'
import { DeletingUserModal } from './DeletingUserModal'
import { useHistory } from 'react-router-dom'
import { myAxios } from '../../../common/config/forAxios'
import { useUser } from '../../../common/ui/UserProvider/useUser'
import { useStore } from 'effector-react'
import { createEvent, createStore } from "effector";
interface IUserSelected {
    userSelect: React.Dispatch<React.SetStateAction<boolean>>,
    selected: boolean,
    setStats: React.Dispatch<React.SetStateAction<boolean>>,
    setInfo: React.Dispatch<React.SetStateAction<boolean>>,
    setScore: React.Dispatch<React.SetStateAction<boolean>>,
    forModal: forModal,
    setPartner: React.Dispatch<React.SetStateAction<boolean>>,
    setStatusScore: React.Dispatch<React.SetStateAction<boolean>>
}

export interface IPartnersInfo {
    count: number,
    income: number,
    structureTurnover: number,
    partners: any[]

}
export const $ShowTwoPartPr = createStore<boolean>(false);
export const setTwoPartPr = createEvent<boolean>();
$ShowTwoPartPr.on(setTwoPartPr, (state, params) => params)

export const UserSelected = (params: IUserSelected) => {
    const [statusballi, setStatusBalli] = useState(13500)
    const [fullName, setFullName] = useState('')
    const [infoDate, setInfoDate] = useState('')
    const [deposit, setDeposit] = useState('')
    const [account, setAccount] = useState('')
    const [lock, setLock] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const history = useHistory()
    const { setFullUserInfo, fullUserInfo } = useUser()
    const [deleted, setDeleted] = useState(false)
    const [partnersInfo, setPartnersInfo] = useState<IPartnersInfo>({
        count: 0,
        income: 0,
        structureTurnover: 0,
        partners: []
    })
    var TwoPartPr = useStore($ShowTwoPartPr)
    var LevelTwoReferal = useStore($LevelTwoReferal)





    useEffect(() => {
        if (params.selected) {
            // Здесь мы подписываем обновление листа партнеров на удаление и добавление партнеров
            $PartnersList.on(AddNewPartner.done, () => { LoadPartners({ id: params.forModal.id, history: history, setPartnersInfo: setPartnersInfo }) })
            $PartnersList.on(DeletePartner.done, () => { LoadPartners({ id: params.forModal.id, history: history, setPartnersInfo: setPartnersInfo }) })

        }
    }, [params.selected, history, params.forModal.id])


    const deletePartner = async (id: number) => {
        function close() {
            DeletePartner({ id: id, history: history })
        }
        setTimeout(close, 1000)
    }
    const blockUser = () => {
        myAxios.post('/stuff/blockUser', { memberId: params.forModal.id }, { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
            .then(res => { setLock(!lock) })
            .catch((async res => {
                if (res.response.data.httpStatus === 401) {
                    var newToken = await refreshToken(history)
                    await myAxios.post('/stuff/blockUser', { memberId: params.forModal.id }, { headers: { "Authorization": "Bearer " + newToken } })
                        .then(res => { setLock(!lock) })
                }
            }))

    }
    useEffect(() => {
        if (params.forModal.id !== 1) {
            myAxios.post('/users/getOnId', { id: params.forModal.id }, { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
                .then(res => {
                    var newdata = res.data.user
                    setFullUserInfo({
                        id: newdata.id,
                        name: newdata.name1 + " " + newdata.name2,
                        bonusPoints: newdata.bonusPoints,
                        email: newdata.email ? newdata.email : '',
                        phone: newdata.phone ? newdata.phone : "",
                        dtCreate: newdata.dtCreate ? newdata.dtCreate : '',
                        accurals: newdata.accrualProcent ? newdata.accrualProcent : '6',
                        deposit: params.forModal.deposit,
                        account: params.forModal.account,
                        blockCheck: newdata.blockCheck,
                        verificationStatusEmail: newdata.verificationStatusEmail,
                        verificationStatusPhone: newdata.verificationStatusPhone,
                        passNumber:  newdata.passNumber,
                        dtIssue:  newdata.dtIssue,
                        authority:  newdata.authority,
                        statusSignatura:  newdata.statusSignatura,
                        documentId: newdata.documentId
                    })
                    setTwoPartPr(res.data.user.isTwoLevelReferal)
                    setStatusBalli(newdata.bonusPoints)
                    setFullName(newdata.name1 + " " + newdata.name2)
                    setInfoDate(newdata.dtCreate ? newdata.dtCreate : '')
                    setLock(newdata.blockCheck !== "1" ? true : false)
                })
                .catch((async res => {
                    if (res.response.data.httpStatus === 401) {
                        var newToken = await refreshToken(history)
                        await myAxios.post('/users/getOnId', { id: params.forModal.id }, { headers: { "Authorization": "Bearer " + newToken } })
                            .then(res => {
                                var newdata = res.data.user
                                setFullUserInfo({
                                    id: newdata.id,
                                    name: newdata.name1 + " " + newdata.name2,
                                    bonusPoints: newdata.bonusPoints,
                                    email: newdata.email ? newdata.email : '',
                                    phone: newdata.phone ? newdata.phone : "",
                                    dtCreate: newdata.dtCreate ? newdata.dtCreate : '',
                                    accurals: newdata.accrualProcent ? newdata.accrualProcent : '6',
                                    deposit: params.forModal.deposit,
                                    account: params.forModal.account,
                                    blockCheck: newdata.blockCheck,
                                    verificationStatusEmail: newdata.verificationStatusEmail,
                                    verificationStatusPhone: newdata.verificationStatusPhone,
                                    passNumber:  newdata.passNumber,
                                    dtIssue:  newdata.dtIssue,
                                    authority:  newdata.authority,
                                    statusSignatura:  newdata.statusSignatura,
                                    documentId: newdata.documentId
                                })
                                setTwoPartPr(res.data.user.isTwoLevelReferal)
                                setStatusBalli(newdata.bonusPoints)
                                setFullName(newdata.name1 + " " + newdata.name2)
                                setInfoDate(newdata.dtCreate ? newdata.dtCreate : '')
                                setLock(newdata.blockCheck !== "1" ? true : false)
                            })
                    }
                }))
        }
        LoadPartners({ id: params.forModal.id, history: history, setPartnersInfo: setPartnersInfo })

        // eslint-disable-next-line
    }, [params.forModal, history])
    useEffect(() => {
        setDeleted(!deleted) //Это тригер, который всем партнерам устанавливает значение false для close. Не могу исправить баг, который скрывает одного из пользователей после удаления.
        // eslint-disable-next-line
    }, [partnersInfo])
    useEffect(() => {
        setStatusBalli(fullUserInfo.bonusPoints)
        setFullName(fullUserInfo.name)
        setInfoDate(fullUserInfo.dtCreate)
        setDeposit(fullUserInfo.deposit?.toString() + ".00")
        setAccount(fullUserInfo.account?.toString() + ".00")

    }, [fullUserInfo])

    const DateIncreeser = (post: string) => {
        if (post) {

            var published = new Date(post);
            var d = new Date("0000-00-00 00:00:00")
            if (Number.isNaN(published.getMonth())) {
                published = new Date(post.replace(" ", "T") + "Z");
            }
            published.setFullYear(published.getFullYear() + 1)
            if (published !== d) {
                var publishedStr = published.getDate().toString().padStart(2, '0') + "." + (published.getMonth() + 1).toString().padStart(2, '0') + "." + published.getFullYear(); // парсим дату в строку
                return publishedStr
            }
            else {
                return "Отсутствует"
            }
        }
        return "Отсутствует"
    }
    const soldCheckbox = () => { //функция смены флага двухуровневой партнерки
        setLevelTwoReferal({ memberId: params.forModal.id })
        setTwoPartPr(LevelTwoReferal)
        // console.log(TwoPartPr)
    };
   
    return (
        <div className={params.selected ? "user_sel_main" : "user_sel_main_close"}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: " 0 10%" }}>
                <span onClick={() => params.userSelect(false)} style={{ color: "#E8A736", margin: "1rem 0", cursor: "pointer" }}><i className="fas fa-chevron-left"></i> Назад</span>
                <div style={{ color: "#E8A736", fontWeight: 500 }}>
                    <p style={{ margin: "0" }}>{fullName}</p>
                    <p style={{ margin: 0 }}>ID: #{params.forModal.id}</p>
                </div>
                <div style={{ width: "59px" }}>
                    <img style={{ height: 24, margin: "1rem 0" }} src={LOGO} alt="" />
                </div>
            </div>
            <div>
                <span style={{ color: "#E8A736", fontSize: "28px", margin: "-0.5rem" }}>{fullUserInfo.deposit ? removeAfterPoint(deposit.toString()) : "0.00"} EUR <img style={{ height: 20, cursor: "pointer" }} onClick={() => params.setScore(true)} src={PEN} alt="" /></span>
                <p style={{ color: "#fff", margin: "-0.5rem 0 0", fontSize: "14px" }}>Текущий счет: {fullUserInfo.account ? removeAfterPoint(account.toString()) : "0.00"} EUR</p>
                <p style={{ color: "#808080", margin: 0, fontSize: "14px" }}>{infoDate !== '' ? DateParse(infoDate) + "-" + DateIncreeser(infoDate) : null}</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', margin: "5%" }}>
                <div onClick={() => { params.setInfo(true) }} className="user_sel_but">
                    <img src={ICON_1} alt="" />
                    <p style={{ margin: "0" }}>Информация</p>
                </div>
                <div onClick={() => params.setStats(true)} className="user_sel_but">
                    <img src={ICON_2} alt="" />
                    <p style={{ margin: "0" }}>Статистика</p>
                </div>
                <div className="user_sel_but" onClick={() => blockUser()}>
                    <img src={LOCK} style={lock ? { transition: "0.5s", opacity: "1", position: 'absolute' } : { transition: "0.5s", opacity: "0", position: 'absolute' }} alt="" />
                    <img src={UNLOCK} alt="" />
                    <p style={lock ? { color: "#B20000", margin: "0" } : { margin: "0" }}>{lock ? "Заблокировать" : "Разблокировать"}</p>
                </div>
                <div className="user_sel_but" style={fullUserInfo.blockCheck === "2" ? { opacity: 0.4 } : { opacity: 1 }}
                    onClick={() => fullUserInfo.blockCheck !== "2" ? setDeleteModal(true) : null}
                >
                    <img src={ICON_4} alt="" />
                    <p style={{ color: "#B20000", margin: "0" }}>Удалить</p>
                </div>

            </div>
            <div style={{ backgroundColor: "#2D3039", borderRadius: "15px", display: "flex", flexDirection: 'column', flex: 1, overflow: "hidden" }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: "1rem 5rem 0" }}>
                    <p style={{ color: "#E8A736", fontSize: "18px", margin: 0 }}>Статус-баллы</p>
                    <p style={{ color: "#fff", fontSize: "18px", margin: 0 }}>{statusballi} <img style={{ height: 24, cursor: "pointer" }} onClick={() => params.setStatusScore(true)} src={PEN_WHITE} alt="" /></p>
                </div>
                <p style={{ color: "#E8A736", fontSize: "10px", margin: 0 }}>Статус:&nbsp;
                    <span style={statusballi < 150000 ? { color: "#149000" } :
                        statusballi < 300000 ? { color: "#AAAAAA" } :
                            statusballi < 500000 ? { color: "#F36D22" } :
                                { color: "#E8A736" }
                    }>{statusballi < 150000 ? "Newbie" :
                        statusballi < 300000 ? "Experienced" :
                            statusballi < 500000 ? "Professional" :
                                "Expert"}</span> </p>



                <span style={{ color: "#E8A736" }}>Партнерская программа</span>
                <div style={{ margin: "0 2rem" }}>
                    <p className="user_sel_left">Двухуровневая программа <span style={{ float: 'right', color: "#fff" }}>{

                    }    <label className="switch">
                            <input type="checkbox" checked={TwoPartPr} onChange={soldCheckbox} />
                            <span className="slider round"></span>
                        </label>
                    </span></p>
                </div>
                <div style={{ margin: "0 2rem" }}>
                    <p className="user_sel_left">Оборот структуры <span style={{ float: 'right', color: "#fff" }}>{removeAfterPoint(partnersInfo.structureTurnover.toString())} <i className="fas fa-euro-sign"></i></span></p>
                    <p className="user_sel_left">Всего активных партнеров <span style={{ float: 'right', color: "#fff" }}>{partnersInfo.count}</span></p>
                    <p className="user_sel_left">Заработано по партнерской программе <span style={{ float: 'right', color: "#fff" }}>{removeAfterPoint(partnersInfo.income.toString())} <i className="fas fa-euro-sign"></i></span></p>
                </div>
                <p style={{ color: "#E8A736" }}>Структура партнеров</p>
                <div style={{ overflowY: 'scroll', paddingBottom: "1rem" }}>
                    <div onClick={() => params.setPartner(true)} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: "#272A32", margin: "0 2rem", padding: "0.5rem 1rem", borderRadius: "5px" }}>
                        <p style={{ color: '#fff', margin: 0 }}>Добавить партнера</p>
                        <i style={{ color: "#E8A736", margin: "auto 0" }} className="fas fa-plus"></i>
                    </div>
                    {partnersInfo.partners?.map((e, i) => {
                        return (<Partner key={i} id={e.id} mail={e.email} money={e.lastDeposit} delete={deletePartner} level={e.level} deleted={deleted} />

                        )
                    })}

                </div>
            </div>
            <DeletingUserModal idUser={params.forModal.id + ""} selectedIsClose={params.selected} setCloseSelected={params.userSelect} class={deleteModal} setModal={setDeleteModal} />
        </div>
    )
}