import {LocalizationProvider, MobileDatePicker} from '@mui/lab'
import {useEffect, useRef, useState} from 'react'
import LONGARROW from '../../../common/assets/images/longarrow2.png'
import DOWLOADSIMG from '../../../common/assets/images/download.svg'
import {onArrowTouch, onArrowTouchEnd, onArrowTouchStart} from '../logics/arrowTouch'
import DateAdapter from '@mui/lab/AdapterDateFns';
import '../styles/userinfo.css'
import {styled, TextField} from '@mui/material';
import {ru} from 'date-fns/esm/locale';
import {DateParse} from '../../messages/logics/MessageDateParse';
import {myAxios} from '../../../common/config/forAxios';
import {refreshToken} from '../../../common/config/accesToken';
import {useHistory} from 'react-router-dom';
import {useUser} from '../../../common/ui/UserProvider/useUser';
import {IModal} from '../../../common/config/interfaces';
import {FormControl, InputGroup} from 'react-bootstrap';
import {ChangeEmail, ChangeTelephone} from '../logics/verificationLogic';


export const UserInfo = (params: IModal) => {
    const {fullUserInfo, setFullUserInfo} = useUser()

    const [name, setName] = useState<string>("Иванов Иван")
    const [Email, setEmail] = useState<string>("ivanivanov1990@mail.ru")
    const [telephone, setTelephone] = useState<string>("+79991231212")
    const [date, setDate] = useState<string>('2021-08-18T21:11:54z')
    const [accruals, setAccruals] = useState<string>("6%")
    const [pasport, setpasport] = useState<string>("1111 111111")
    const [datepasport, setDatePasport] = useState<string>('2021-08-18T21:11:54z')
    const [organpasport, setOrganPasport] = useState<string>('УФМС')
    const [treatyStatus, setTreatyStatus] = useState<string>('Новый')
    const [StatusDoc, setStatusDoc] = useState<any>([])
    const [documentId, setdocumentId] = useState<string>("1")

    const [password, setPassword] = useState<string>("")
    const [codeword, setCodeWord] = useState<string>("")
    const history = useHistory()

    const [first, setfirst] = useState(false)
    const [pos1, setPos1] = useState(0)
    const [pos2, setPos2] = useState(0)
    const arrowblock = useRef<HTMLDivElement | null>(null)
    const accuralRef = useRef<HTMLDivElement | null>(null)

    const [orgaTextDef, setorgaTextDef] = useState<string>("Орган выдавший документ:")

    useEffect(() => {
        console.log(treatyStatus)
    }, [treatyStatus])

    useEffect(() => {
        StatusDocAll()
    }, [])


    useEffect(() => {
        StatusDoc.map((e: any) => console.log(e))

    }, [StatusDoc])


    useEffect(() => {
        setStatusDoc([
            {
                signatura: "closed",
                name: "Закрыт"
            },
            {
                signatura: "signed",
                name: "Подписан"
            },
            {
                signatura: "waitsForSignature",
                name: "Ожидает подписи"
            },
        ])
    }, [fullUserInfo])

    const StatusDocAll = () => {
        myAxios.post('/stuff/getContractStatuses', {}, {headers: {"Authorization": "Bearer " + localStorage.getItem("AccessToken")}})
            .then(res => {
                console.log(res.data)
                setStatusDoc(res.data)
            })
            .catch((async res => {
            }))

    }


    useEffect(() => {
        var paramsDate = fullUserInfo.dtCreate
        if (paramsDate) {
            if (Number.isNaN(new Date(paramsDate).getMonth())) {
                paramsDate = fullUserInfo.dtCreate.replace(" ", "T") + "Z"
            }
        } else {
            if (Number.isNaN(new Date('2021-08-18 21:11:54').getMonth())) { // Проверка на то, что это айос. Я другого способа не знаю
                paramsDate = ""
            }
        }
        console.log("fullUserInfo", fullUserInfo)
        setName(fullUserInfo.name)
        setEmail(fullUserInfo.email)
        setTelephone(fullUserInfo.phone)
        setDate(paramsDate === "" ? '' : paramsDate)
        setAccruals(fullUserInfo.accurals)
        setpasport(fullUserInfo.passNumber!)
        setDatePasport(fullUserInfo.dtIssue!)
        setOrganPasport(fullUserInfo.authority!)
        setTreatyStatus(fullUserInfo.statusSignatura!)
        setdocumentId(fullUserInfo.documentId)

        setorgaTextDef("Орган выдавший документ:")

        setCodeWord('')
        setPassword('')
        if (params.class) {
            const divelemet = arrowblock.current;
            divelemet!.style.bottom = "0px";
        } else {
            const divelemet = arrowblock.current;
            divelemet!.style.bottom = "-150%";
        }
    }, [params.class, fullUserInfo])

    const handleChange = (newValue: string | null) => {
        if (newValue !== null)
            setDate(newValue);
    };
    const handleChangePasp = (newValue: string | null) => {
        if (newValue !== null)
            setDatePasport(newValue);
    };
    const CssTextPasswordField = styled(TextField)({
        '& label.Mui-focused': {
            color: '#E8A736',
            textAlign: "left",
            padding: '0rem',
            width: 'auto'

        },
        ".MuiPickersDay-today": {
            color: "#E8A736",
            border: "1px solid #E8A736"
        },

        '& label': {
            color: '#E8A736',
            fontSize: '14px',
            textAlign: 'center',
            width: "100%",
            top: '-6px'

        },

        '& ::placeholder': {
            color: '#E8A736',
            textAlign: 'center',
            width: '100%'
        },
        '& .MuiInputLabel-shrink': {
            top: '0px',
            textAlign: 'center',
            width: "auto",
        },

        '& .MuiInputBase-root': {
            textAlign: 'center',
            padding: "0.5rem",
        },
        '& .MuiInputBase-input': {
            textAlign: 'center',

        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: "#2D3039",
            color: '#E8A736',
            fontSize: '14px',
            textAlign: "center",
            borderRadius: "10px",

            '& fieldset': {
                border: "1px solid #E8A736 !important",
                color: "red"
            },
            '& fieldset legend': {
                border: "1px solid #E8A736",
                float: 'none',
                color: "red"
            },
            '&:hover fieldset': {
                border: "1px solid #E8A736",
                color: 'red',
            },

        },

    });

    const CssTextField = styled(TextField)({


        "& .MuiDialog-container": {
            backgroundColor: "#2D3039",
            border: "3px solid red",
            "& .MuiPaper-root": {
                backgroundColor: "#2D3039",
            },
            "& .MuiDialogContent-root": {
                backgroundColor: "#2D3039",
            }
        },
        paper: {
            outline: "4px solid #000000"
        },

        '& label': {
            transition: "none",
            color: '#E8A73644!important',
            padding: "1.2rem 0rem",
            fontSize: "18px",

        },
        '& .MuiOutlinedInput-root': {
            height: "8vw",
            color: date !== "01.01.2000" ? '#E8A736' : "#2d3039",
            paddingLeft: "8rem",
            backgroundColor: "#2D3039",
            borderRadius: "10px",
            fontSize: "14px",
            width: "100%",
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff00',
            },
            '& fieldset': {
                background: "#2D3039",
            },
            '& input': {
                zIndex: 9999

            },


        },

        '& .Mui-focused': {
            color: '#E8A73644',
        },
        '& .MuiOutlinedInput-input': {
            padding: "0.5rem",
        },


        // css-9yjdhh-MuiDialogContent-root

    });


    useEffect(() => {
        if (document !== null) {
            if (params.class) {
                var input = document.getElementById("password-id")
                //@ts-ignore
                input.focus();    //@ts-ignore
                input.selectionStart = password.length;
            }
        }
        // eslint-disable-next-line
    }, [password])

    useEffect(() => {
        if (document !== null) {
            if (params.class) {
                var input = document.getElementById("codeword-id")
                input?.focus()    //@ts-ignore
                //@ts-ignore
                input.selectionStart = codeword.length;
            }
        }
        // eslint-disable-next-line
    }, [codeword])
    const onVerify = (object: string) => {
        if (object === "email") {
            ChangeEmail({
                memberId: fullUserInfo.id,
                history: history,
                fullUserInfo: fullUserInfo,
                setFullUserInfo: setFullUserInfo
            })
        } else
            ChangeTelephone({
                memberId: fullUserInfo.id,
                history: history,
                fullUserInfo: fullUserInfo,
                setFullUserInfo: setFullUserInfo
            })

    }
    const onSaveClickButton = async () => {
        var count = 0;
        const formData = new FormData();
        formData.append('id', fullUserInfo.id.toString())
        if (name !== fullUserInfo.name) {
            count++;
            formData.append('name1', name.split(" ")[0])
            formData.append('name2', name.split(" ")[1])
        }
        if (Email !== fullUserInfo.email) {
            count++;
            formData.append('email', Email)
        }

        if (telephone !== fullUserInfo.phone) {
            count++;
            formData.append('phone', telephone)

        }
        if (date !== fullUserInfo.dtCreate && date !== "01.01.2000") {
            count++;
            formData.append('dtCreate', DateParse(date).split('.').reverse().join('-') + " 00:00:00")
        }


        if (accruals !== fullUserInfo.accurals) {
            count++;
            formData.append('accrualProcent', accruals)
        }

        if (pasport !== fullUserInfo.passNumber) {
            count++;
            formData.append('passNumber', pasport)
        }
        if (datepasport !== fullUserInfo.dtIssue) {
            count++;
            formData.append('dtIssue', DateParse(datepasport).split('.').reverse().join('-'))
        }
        if (organpasport !== fullUserInfo.authority) {
            count++;
            formData.append('authority', organpasport)
        }
        if (treatyStatus !== fullUserInfo.statusSignatura) {
            count++;
            formData.append('contractStatus', treatyStatus)
        }


        var object = {};
        formData.forEach(function (value, key: string) {//@ts-ignore
            object[key] = value;
        });
        var json = JSON.stringify(object);
        console.log(count)
        if (count > 0) {
            await myAxios.post('/stuff/membersData', json, {headers: {"Authorization": "Bearer " + localStorage.getItem("AccessToken")}})
                .then(res => {
                    var newdata = res.data.member
                    setFullUserInfo({
                        id: newdata.id,
                        name: newdata.name1 + " " + newdata.name2,
                        bonusPoints: newdata.bonusPoints,
                        email: newdata.email ? newdata.email : '',
                        phone: newdata.phone ? newdata.phone : "",
                        dtCreate: newdata.dtCreate ? newdata.dtCreate : '',
                        accurals: newdata.accrualProcent ? newdata.accrualProcent : '6',
                        deposit: fullUserInfo.deposit,
                        account: fullUserInfo.account,
                        blockCheck: fullUserInfo.blockCheck,
                        verificationStatusEmail: newdata.verificationStatusEmail,
                        verificationStatusPhone: newdata.verificationStatusPhone,
                        passNumber: newdata.passNumber,
                        dtIssue: newdata.dtIssue,
                        authority: newdata.authority,
                        statusSignatura: newdata.statusSignatura,
                        documentId: newdata.documentId
                    })
                })
                .catch((async res => {
                    if (res.response.data.httpStatus === 401) {
                        var newToken = await refreshToken(history)
                        await myAxios.post('/stuff/membersData', json, {headers: {"Authorization": "Bearer " + newToken}})
                            .then(res => {
                                var newdata = res.data.member
                                setFullUserInfo({
                                    id: newdata.id,
                                    name: newdata.name1 + " " + newdata.name2,
                                    bonusPoints: newdata.bonusPoints,
                                    email: newdata.email ? newdata.email : '',
                                    phone: newdata.phone ? newdata.phone : "",
                                    dtCreate: newdata.dtCreate ? newdata.dtCreate : '',
                                    accurals: newdata.accrualProcent ? newdata.accrualProcent : '6',
                                    deposit: fullUserInfo.deposit,
                                    account: fullUserInfo.account,
                                    blockCheck: fullUserInfo.blockCheck,
                                    verificationStatusEmail: newdata.verificationStatusEmail,
                                    verificationStatusPhone: newdata.verificationStatusPhone,
                                    passNumber: newdata.passNumber,
                                    dtIssue: newdata.dtIssue,
                                    authority: newdata.authority,
                                    statusSignatura: newdata.statusSignatura,
                                    documentId: newdata.documentId
                                })
                            })
                    }
                }))

        }
        if (password !== "") {
            await myAxios.post('/stuff/changePsswrd', {
                memberId: fullUserInfo.id,
                psswrd: password
            }, {headers: {"Authorization": "Bearer " + localStorage.getItem("AccessToken")}})
                .then(res => {
                })
                .catch((async res => {
                    if (res.response.data.httpStatus === 401) {
                        var newToken = await refreshToken(history)
                        await myAxios.post('/stuff/changePsswrd', {
                            memberId: fullUserInfo.id,
                            psswrd: password
                        }, {headers: {"Authorization": "Bearer " + newToken}})
                            .then(res => {
                            })
                    }
                }))


        }
        if (codeword !== "") {
            await myAxios.post('/stuff/changeCodeword', {
                memberId: fullUserInfo.id,
                codeword: codeword
            }, {headers: {"Authorization": "Bearer " + localStorage.getItem("AccessToken")}})
                .then(res => {
                })
                .catch((async res => {
                    if (res.response.data.httpStatus === 401) {
                        var newToken = await refreshToken(history)
                        await myAxios.post('/stuff/changeCodeword', {
                            memberId: fullUserInfo.id,
                            codeword: codeword
                        }, {headers: {"Authorization": "Bearer " + newToken}})
                            .then(res => {
                            })
                    }
                }))


        }
        params.setModal(false)
    }
    const onAccuralsChange = (e: React.ChangeEvent<any>) => {
        var str = e.target.value;
        if (e.target.value.includes("%")) {
            str = e.target.value.replace("%", "");
        } else {
            str = e.target.value.substring(0, e.target.value.length - 1)
        }
        setAccruals(str)
    }

    return (
        <div ref={arrowblock} className={params.class ? "user_info_main" : "user_info_main_close"}>

            <div style={{touchAction: "none"}} onTouchStart={e => onArrowTouchStart(e, setPos2, setfirst)}
                 onTouchMove={e => onArrowTouch(e, pos1, pos2, setfirst, first, setPos1, setPos2)}
                 onTouchEnd={e => onArrowTouchEnd(e, params.setModal, 130)}>
                <img src={LONGARROW} alt='' style={{height: 10}}/>
                <p style={{color: "#E8A736", fontWeight: 500, marginBottom: "0.75rem", fontSize: "18px"}}>Информация о
                    пользователе</p>
            </div>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <InputGroup style={{margin: "0.5rem 0"}}>
                    <InputGroup.Text className="user_info_group">Фамилия Имя:</InputGroup.Text>
                    <FormControl
                        placeholder="Введите ФИО"
                        className="user_info_form"
                        type="text" value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </InputGroup>
                <InputGroup style={{margin: "0.5rem 0"}}>
                    <InputGroup.Text className="user_info_group">E-mail:</InputGroup.Text>
                    <FormControl
                        placeholder="Введите e-mail"
                        className="user_info_form"
                        type="text" value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div style={{
                        width: "40px",
                        backgroundColor: "#2D3039",
                        borderRadius: "0 10px 10px 0",
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: 'pointer'
                    }} onClick={() => onVerify("email")}>
                        <i className={fullUserInfo.verificationStatusEmail ? "far fa-check-circle" : "far fa-circle"}
                           style={{color: "#e8a736"}}></i>
                    </div>
                </InputGroup>
                <InputGroup style={{margin: "0.5rem 0"}}>
                    <InputGroup.Text className="user_info_group">Телефон:</InputGroup.Text>
                    <FormControl
                        placeholder="Введите телефон"
                        className="user_info_form"
                        type="text" value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                    />
                    <div style={{
                        width: "40px",
                        backgroundColor: "#2D3039",
                        borderRadius: "0 10px 10px 0",
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: 'pointer'
                    }} onClick={() => onVerify("telephone")}>
                        <i className={fullUserInfo.verificationStatusPhone ? "far fa-check-circle" : "far fa-circle"}
                           style={{color: "#e8a736"}}></i>
                    </div>
                </InputGroup>

                <div style={{position: 'relative', margin: "0.3rem 0 0 0"}}>

                    <LocalizationProvider locale={ru} dateAdapter={DateAdapter}>
                        <> {date === '2021-08-18T21:11:54z' || date === '' ? <label style={{
                            color: "#E8A73644",
                            margin: "-0.5rem",          //заглушка чтобы текст не сьезжал!
                            paddingLeft: "1.5rem",      ///если что у нас текст съезжает, а на сервере нет
                            fontSize: "14px",
                            position: "absolute",
                            zIndex: 9999,
                            paddingTop: "0.7rem"
                        }} className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined MuiFormLabel-root MuiFormLabel-colorPrimary Mui-error css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root">Начало
                            договора:</label> : null}
                        </>
                        <MobileDatePicker
                            cancelText="Отмена"
                            okText="Ок"
                            label={date !== '2021-08-18T21:11:54z' && date !== '' ? "Начало договора:" : ""}
                            inputFormat="dd.MM.yyyy"
                            value={date}
                            onChange={handleChange}
                            renderInput={(params) => <CssTextField style={{width: "100%"}} {...params} />}
                        />

                    </LocalizationProvider>
                    <InputGroup style={{margin: "0.5rem 0"}}>
                        <InputGroup.Text className="user_info_group">Ежемесячные начисления:</InputGroup.Text>
                        <FormControl
                            placeholder="Процент"//@ts-ignore
                            ref={accuralRef}
                            className="user_info_form caretBlank"
                            type="text" value={accruals + "%"}
                            onChange={(e) => onAccuralsChange(e)}
                        />
                    </InputGroup>


                    {/* ХАРКОД, ПОКА НЕТ АПИ */}
                    <InputGroup style={{margin: "0.5rem 0"}}>
                        <InputGroup.Text className="user_info_group">Серия, номер документа:</InputGroup.Text>
                        <FormControl
                            placeholder=""//@ts-ignore
                            // ref={accuralRef}
                            className="user_info_form caretBlank"
                            type="text" value={pasport}
                            onChange={(e) => setpasport(e.target.value)}
                        />
                    </InputGroup>
                    <div style={{position: 'relative',display:"flex"}}>
                    <LocalizationProvider locale={ru} dateAdapter={DateAdapter}>
                        <> {datepasport === '2021-08-18T21:11:54z' || datepasport === '' ? <label style={{
                            color: "#E8A73644",
                            margin: "0",          //заглушка чтобы текст не сьезжал!
                            // paddingLeft: "6.4vw",      ///если что у нас текст съезжает, а на сервере нет
                            fontSize: "14px",
                            position: "absolute",
                            zIndex: 9999,
                            // webkitTransform:"none",
                            // webkitTransition:"none",
                            transform:"none",
                            // paddingTop: "1.4vh",
                            padding: "0.5rem 0.5rem 0.5rem 1rem",
                            display:"flex",
                            alignItems:"center",
                            height:"100%"



                        }} className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined MuiFormLabel-root
                              css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root">Дата выдачи:</label> : null}
                        </>
                        <MobileDatePicker
                            cancelText="Отмена"
                            okText="Ок"
                            label={datepasport !== '2021-08-18T21:11:54z' && datepasport !== '' ? "Дата выдачи:" : ""}
                            inputFormat="dd.MM.yyyy"
                            value={datepasport}
                            onChange={handleChangePasp}
                            renderInput={(params) => <CssTextField
                                style={{width: "100%", marginTop: "1%"}} {...params} />}
                        />
                    </LocalizationProvider>
                    </div>
                    <InputGroup style={{margin: "0.5rem 0"}}>
                        <InputGroup.Text className="user_info_group"
                                         onClick={() => orgaTextDef === "Орган выдавший документ:" ? setorgaTextDef("") : null}
                        >{orgaTextDef}</InputGroup.Text>
                        <FormControl
                            placeholder=""//@ts-ignore
                            // ref={accuralRef}
                            className={orgaTextDef === "Орган выдавший документ:" ? "user_info_form caretBlank truncate" : "user_info_form caretBlank"}
                            type="text" value={organpasport}
                            onClick={(e) => orgaTextDef === "Орган выдавший документ:" ? setorgaTextDef("") : null}
                            onChange={(e) => setOrganPasport(e.target.value)}
                        />
                    </InputGroup>

                    <InputGroup style={{margin: "0.5rem 0"}}>
                        <InputGroup.Text className="user_info_group" style={{width: "49%%"}}>Статус
                            договора:</InputGroup.Text>
                        {/* <FormControl
                        placeholder="Процент"//@ts-ignore
                        // ref={accuralRef}
                        className="user_info_form caretBlank"
                        type="text" value={treatyStatus}
                        onChange={(e) => setTreatyStatus(e.target.value)}
                    /> */}
                        <select className="user_treaty_status_select"  onChange={(e) => {
                            setTreatyStatus(e.target.value)
                        }}>
                            {/* {StatusDoc.map((e:any)=>{
                        <option value={e.signatura}>{e.name}</option>
                    })} */}
                            {treatyStatus == "" || treatyStatus == undefined ?
                                <option selected value="">Отсутствует</option> : null}

                            {treatyStatus === "closed" ? <option selected value="closed">Закрыт</option> :
                                <option value="closed">Закрыт</option>
                            }
                            {treatyStatus === "signed" ? <option selected value="signed">Подписан</option> :
                                <option value="signed">Подписан</option>
                            }
                            {treatyStatus === "waitsForSignature" ?
                                <option selected value="waitsForSignature">Ожидает подписи</option> :
                                <option value="waitsForSignature">Ожидает подписи</option>
                            }
                        </select>
                        {/*<div className="user_dowl_button"*/}
                        {/*     onClick={() => window.open('https://lioninvest.eu/lion/contract/users_contracts/Contract_' + documentId + '.pdf')}>*/}
                        {/*    <img*/}
                        {/*        src={DOWLOADSIMG} alt=''*/}
                        {/*        onClick={() => window.open('https://lioninvest.eu/lion/contract/users_contracts/Contract_' + documentId + '.pdf')}*/}
                        {/*        style={{width: "50%", marginTop: "15%"}}/>*/}
                        {/*</div>*/}
                        {Number(documentId) !== 0 ?(
                            <div className="user_dowl_button"
                                 onClick={() => window.open('https://lioninvest.eu/docs/Contract_' + documentId + '.pdf')}>
                                <img
                                    src={DOWLOADSIMG} alt=''
                                    onClick={() => window.open('https://lioninvest.eu/docs/Contract_' + documentId + '.pdf')}
                                    style={{width: "50%", marginTop: "15%"}}/>
                            </div>
                        ):null}
                    </InputGroup>
                    {/* <InputGroup style={{ margin: "0.5rem 0.5rem" }} className="user_dowl">
                <img src={DOWLOADSIMG} alt='' style={{ marginLeft: "auto", marginRight: "auto" }} />
                </InputGroup> */}

                </div>


                <CssTextPasswordField
                    label="Изменить пароль"
                    placeholder="******"
                    multiline
                    value={password}
                    id="password-id"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <CssTextPasswordField
                    style={{marginTop: "0.5rem"}}
                    id="codeword-id"
                    label="Изменить кодовое слово"
                    placeholder="******"
                    multiline
                    value={codeword}
                    onChange={(e) => setCodeWord(e.target.value)}
                />
                <button className="user_info_button" onClick={onSaveClickButton}>Сохранить</button>
            </div>

        </div>
    )
}