import {createEffect, createStore} from 'effector'
import { IPartnerItem } from '../atoms/partner';
import { myAxios } from '../../../common/config/forAxios';
import { refreshToken } from '../../../common/config/accesToken';
import { IPartnersInfo,setTwoPartPr } from '../molecules/UserSelected';


interface QueryPartnersLoad{
    id:number,
    history:any,
    setPartnersInfo: React.Dispatch<React.SetStateAction<IPartnersInfo>>
}
interface QueryPartnerDelete{
    id:number,
    history:any,
}
interface QueryPartnerAdd{
    id:number,
    referalId:string,
    history:any,
}
interface QueryTwoReferal{
    memberId?:number,
    isLevelTwoReferal?:boolean
}

export const LoadPartners = createEffect<QueryPartnersLoad, IPartnerItem[]>();
export const DeletePartner = createEffect<QueryPartnerDelete,void>()
export const AddNewPartner = createEffect<QueryPartnerAdd,void>()

export const setLevelTwoReferal = createEffect<QueryTwoReferal,boolean>()
export const $LevelTwoReferal = createStore<boolean>(false)

export const $PartnersList= createStore<IPartnerItem[]>([])


LoadPartners.use(async (params) => {
    var empty_pack:IPartnerItem[]=[]
    await myAxios.post('/users/partnersList',{id:params.id},{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
            .then(res=>{
                // console.log(res)
                params.setPartnersInfo({
                    count:res.data.count,
                    income:res.data.income,
                    structureTurnover:res.data.structureTurnover,
                    partners:res.data.partners
                })
                empty_pack=res.data.partners})
            .catch((async res=>{
                if(res.response.data.httpStatus === 401){
                    var newToken = await refreshToken(params.history)
                    await myAxios.post('/users/partnersList',{id:params.id},{ headers:{"Authorization": "Bearer "+newToken}})
                    .then(res=>{
                        params.setPartnersInfo({
                            count:res.data.count,
                            income:res.data.income,
                            structureTurnover:res.data.structureTurnover,
                            partners:res.data.partners
                        })
                        empty_pack=res.data.partners})
                            }
            }))
    return empty_pack as IPartnerItem[]
})
DeletePartner.use(async (params) => {
    await myAxios.post('/stuff/delReferal', {id:params.id},{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
    .catch((async res=>{
        if(res.response.data.httpStatus === 401){
            var newToken = await refreshToken(params.history)
            await myAxios.post('/stuff/delReferal',{id:params.id},{ headers:{"Authorization": "Bearer "+newToken}})
                   
    }}))
})
AddNewPartner.use(async (params)=>{
    await myAxios.post('/stuff/addReferal', { referalId:params.id,id:params.referalId},{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
    .catch((async res=>{
        if(res.response.data.httpStatus === 401){
            var newToken = await refreshToken(params.history)
            await myAxios.post('/stuff/addReferal',{ referalId:params.id,id:params.referalId},{ headers:{"Authorization": "Bearer "+newToken}})
                   
    }}))
   
})
setLevelTwoReferal.use(async (params) => {
    var empty_pack:boolean=false
    await myAxios.post('/stuff/setLevelTwoReferal',{
        memberId:params.memberId
    },{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
            .then(res=>{
                empty_pack=res.data.isLevelTwoReferal
                setTwoPartPr(res.data.isLevelTwoReferal)
            })
            .catch((async res=>{
                
            }))
    return empty_pack as boolean
   
})
$PartnersList.on(LoadPartners.doneData, (_, val) => val);
$LevelTwoReferal.on(setLevelTwoReferal.doneData, (_, val) => val);




