
import { useEffect, useRef, useState } from 'react'
import { styled, TextField } from '@mui/material';
import { IModal } from '../../../common/config/interfaces'
import { useUser } from '../../../common/ui/UserProvider/useUser'
import '../styles/daycurs.css'
import { LocalizationProvider, MobileDatePicker } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDateFns';
import { ru } from 'date-fns/esm/locale';
import { DateParse } from '../../messages/logics/MessageDateParse';

export const ReportWindow = (params: IModal) => {
    const arrowblock = useRef<HTMLDivElement | null>(null)
    const [dateStart, setDateStart] = useState<string>(new Date().toISOString())
    const [dateEnd, setDateEnd] = useState<string>(new Date().toISOString())
    const { fullUserInfo } = useUser()

    useEffect(() => {
        if (params.class) {
            const divelemet = arrowblock.current;
            divelemet!.style.top = "70%";

        } else {
            const divelemet = arrowblock.current;
            divelemet!.style.top = "-100%";
        }
    }, [params.class])
    const handleChange = (newValue: string | null, dateChanger: React.Dispatch<React.SetStateAction<string>>) => {
        if (newValue !== null)
            dateChanger(newValue);
    };
    const CssTextField = styled(TextField)({

        "& .MuiDialog-container": {
            backgroundColor: "#2D3039",
            border: "3px solid red",
            "& .MuiPaper-root": {
                backgroundColor: "#2D3039",
            },
            "& .MuiDialogContent-root": {
                backgroundColor: "#2D3039",
            }
        },
        paper: {
            outline: "4px solid #000000"
        },

        '& label': {
            transition: "none",
            color: '#E8A73644!important',
            padding: "1.5rem 0rem",
            fontSize: "18px",

        },
        '& .MuiOutlinedInput-root': {
            color: '#E8A736',
            paddingLeft: "2rem",
            backgroundColor: "#2D3039",
            borderRadius: "10px",
            fontSize: "14px",
            width: "100%",
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff00',
            },
        },
        '& .Mui-focused': {
            color: '#E8A73644',
        },
        '& .MuiOutlinedInput-input': {
            padding: "0.5rem",
        },

    });


    const onSaveButton = async () => {
        // let filename = `Отчет по №${fullUserInfo.id} от ${
        //   new Date().getFullYear() +
        //   "-" +
        //   (new Date().getMonth()>10?new Date().getMonth():"0"+new Date().getMonth()) +
        //   "-" +
        //   (new Date().getDate()>10?new Date().getDate():"0"+new Date().getDate()) +
        //   " " +
        //   (new Date().getHours()>10?new Date().getHours():"0"+new Date().getHours()) +
        //   "_" +
        //   (new Date().getMinutes()>10?new Date().getMinutes():"0"+new Date().getMinutes()) +
        //   "_" +
        //   (new Date().getSeconds()>10?new Date().getSeconds():"0"+new Date().getSeconds())
        // }.xlsx`;
        let xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.onreadystatechange = function () {
            var a;
            if (xmlHttpRequest.readyState === 4 && xmlHttpRequest.status === 200) {
                // var header = xmlHttpRequest.getResponseHeader("Content-Disposition")
                var header = xmlHttpRequest.getResponseHeader('content-disposition')!.split('filename=')[1].split(';')[0].split('"').join('');;
                console.log(header);  
                a = document.createElement('a');
                a.href = window.URL.createObjectURL(xmlHttpRequest.response);
                a.download = header;
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
            }
        };
        xmlHttpRequest.open("POST", 'https://lioninvest.eu/api/stuff/getReport');
        // xmlHttpRequest.setRequestHeader("Content-Type", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        // xmlHttpRequest.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xmlHttpRequest.setRequestHeader("Content-Type", "application/json");
        xmlHttpRequest.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("AccessToken"));
        // xmlHttpRequest.setRequestHeader('cache-control', 'no-cache, must-revalidate, post-check=0, pre-check=0');
        // xmlHttpRequest.setRequestHeader('cache-control', 'max-age=0');
        // xmlHttpRequest.setRequestHeader('expires', '0');
        // xmlHttpRequest.setRequestHeader('expires', 'Tue, 01 Jan 1980 1:00:00 GMT');
        // xmlHttpRequest.setRequestHeader('pragma', 'no-cache');
        // xmlHttpRequest.setRequestHeader("Access-Control-Expose-Headers", "content-disposition");
        xmlHttpRequest.responseType = 'blob';
        xmlHttpRequest.send(JSON.stringify({
            "memberId": fullUserInfo.id,
            'dtFirst': DateParse(dateStart).split('.').reverse().join('-') + " 00:00:00",
            "dtSecond": DateParse(dateEnd).split('.').reverse().join('-') + " 00:00:00"
        }));
    }


    return (
        <div className={params.class ? "ext_modal_blur" : "ext_modal_blur_close"}>

            <div className={params.class ? "ext_modal" : "ext_modal_close"}>
                <div ref={arrowblock} className="day_curs_main">
                    <i className="fas fa-times" style={{ color: '#E8A736a9', position: 'absolute', right: "10%", top: "7%" }} onClick={() => params.setModal(false)}></i>
                    <span style={{ fontWeight: 500 }}>Создать отчет</span>
                    <span style={{ color: "#E8A736a9", margin: '1rem' }}>Выберите даты</span>
                    <div style={{ position: 'relative', margin: "0", height: "100%" }}>
                        <div style={{ position: 'relative', margin: "0.3rem 0" }}>
                            <LocalizationProvider locale={ru} dateAdapter={DateAdapter}>
                                <MobileDatePicker
                                    cancelText="Отмена"
                                    okText="Ок"
                                    label="От:"
                                    inputFormat="dd.MM.yyyy"
                                    value={dateStart}
                                    onChange={(e) => handleChange(e, setDateStart)}
                                    renderInput={(params) => <CssTextField style={{ margin: "0 2rem" }} {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div style={{ position: 'relative', margin: "0.3rem 0" }}>
                            <LocalizationProvider locale={ru} dateAdapter={DateAdapter}>
                                <MobileDatePicker
                                    cancelText="Отмена"
                                    okText="Ок"
                                    label="До:"
                                    inputFormat="dd.MM.yyyy"
                                    value={dateEnd}
                                    onChange={(e) => handleChange(e, setDateEnd)}
                                    renderInput={(params) => <CssTextField style={{ margin: "0 2rem" }} {...params} />}
                                />
                            </LocalizationProvider>
                        </div>

                    </div>
                    <button className="day_curs_button" onClick={() => onSaveButton()}>Сохранить</button>

                </div>
            </div>
        </div>
    )
}
