import Lion from '../../../common/assets/images/halfLogo.png'
import LionLogo from '../../../common/assets/images/lionLogoWithText.png'
import AppleLogo from '../../../common/assets/images/apple.png'
import AndroidLogo from '../../../common/assets/images/android.png'
import phones from '../../../common/assets/images/phones.png'
import '../styles/LionSitePage.css'

export const LionSitePage = () => {
    return (
        <div className="lion_site_main">
            <div className="lion_site_lion">
                <img src={Lion} alt="" className="lion_site_lion" style={{ position: "relative" }} />
                <img src={phones} alt="" className="lion_site_phones" />

            </div>
            <div className='lion_site_content'>
                <img src={LionLogo} alt="" className="lion_site_logo" />
                <div className='lion_site_textBlock'>
                    <div></div>
                    <div>
                        <p style={{ color: "#E8A736", fontSize: "20px" }}>Investment Company</p>
                        <p className='lion_site_highText'>Increase capital and manage funds</p>
                        <div className='lion_site_p'>
                            <p>Opportunity to place your assets in one place with the possibility of capitalization</p>
                            <p>Operations monitoring 24/7</p>
                            <p>P2P transfers to other members</p>
                        </div>
                    </div>
                    <div>

                        <span style={{ color: "#555E78", fontSize: "18px" }}>The application can be downloaded from the links below</span>
                        <div className='lion_site_buttons'>
                                <img src={AppleLogo} alt="" />
                            <a href="https://play.google.com/store/apps/details?id=ru.haferflocken.lion_invest_android&hl=ru">
                                <img src={AndroidLogo} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}