import { HighBlocks } from '../molecules/HighBlock'
import '../styles/operations.css'
import { OperationItem } from '../molecules/OperationItem'
import { OperationsModal } from '../molecules/OperationsModal'
import { useEffect, useRef, useState } from 'react'
import { ModalBlur } from '../molecules/ModalBlur'
import { useHistory } from 'react-router-dom'
import { useStore } from 'effector-react'
import { $LastPath } from '../../../common/ui/pathhistory'
import { LocalizationProvider, MobileDateRangePicker } from '@mui/lab'
import { DateRange } from '@mui/lab/DateRangePicker';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { ru } from 'date-fns/esm/locale';

import { $OperationList, LoadOperation } from '../logics/LoadOperations'
import { Spinner,Dropdown } from 'react-bootstrap'
import PLUS from '../../../common/assets/images/Plus.png'
import ARROW from '../../../common/assets/images/arrowdown.png'
import EXCHANGE from '../../../common/assets/images/exchange.png'
import INVEST from '../../../common/assets/images/invest.png'
import ENV from '../../../common/assets/images/env.png'
import ADMIN from '../../../common/assets/images/adminEdit.png'
import { IUserData } from '../molecules/UserSelected'

import LOCK from '../../../common/assets/images/lock.svg'
import UNLOCK from '../../../common/assets/images/unlock.svg'
import { myAxios } from '../../../common/config/forAxios'
import { refreshToken } from '../../../common/config/accesToken'

export interface forItem {
    memberId: number,
    id: number,
    amount: number,
    dt: string,
    type: string,
    money: number,
    extAnticip: string,
    recipientId: string,
    memberStatus:string,
    recipientStatus:string,
    isCanceled:string
}

export interface forModal {
    id: number,
    type: string,
    date: string,
    money: number,    
    extAnticip?: string,
    userData?: IUserData | undefined,
    isLoad?: boolean,
    isCanceled:string,

    setLoad?: React.Dispatch<React.SetStateAction<boolean>>
    
    memberStatus:string,
    recipientStatus:string,

}
export interface chelovek {
    name: string,
    age: number
}
export const Operations = () => {
    const [modalLoading, setModalLoading] = useState(false)


    const [operationType, setOperationType] = useState('Все операции')
    const operTypes = ['','autoReinvest', 'adjunctions', 'extractions', 'extractionsAnticipatorily', 'transfers', 'investments','activeApplications']
    

    const [dateRange, setDateRange] = useState<DateRange<Date | string>>([new Date(), null]);
    const [modal, setModal] = useState(false)
    let history = useHistory()
    const [forModal, setForModal] = useState<forModal>({} as forModal)
    const [forModalSecond, setForModalSecond] = useState<forModal>({} as forModal)
    const lastPath = useStore($LastPath)
    const [isLoading, setLoading] = useState(false)
    const [filter, setFilter] = useState('')
    const [startDate, useStartDate] = useState("")
    const [endDate, useEndDate] = useState("")
    const operationAnalog = useStore($OperationList)
    const [operations, setOperations] = useState<forItem[] | null>()
    const [lock, setLock] = useState(false)

    const refer = useRef<HTMLDivElement>({} as HTMLDivElement );

    const movescreen = () => {
        const divelemet = refer.current;
        if(divelemet!==null)
        divelemet.style.left = "0%";
    }

    useEffect(() => {
        const arrayOfIndes: any = []
        const array: any = []
        operationAnalog?.forEach((item, index) => {
            if (arrayOfIndes.includes(item.id) && item.type === "transfers") {
                return
            }
            else{
                arrayOfIndes.push(item.id)
                array.push(item)
            }
            return array
        });  
        setOperations(array)

    }, [operationAnalog])

    useEffect(() => {
        const divelemet = refer.current;
        if (lastPath?.toString() === "/messages") {
            divelemet.style.left = "-100%";
        } else {
            divelemet.style.left = "100%";
        }
        setTimeout(movescreen, 10);
    }, [lastPath])
    useEffect(() => {
        if (!modal) {
            let operation = operationType === "Все операции" ? "" : operationType
            if (operationType == "activeApplications"){
            
                LoadOperation({
                    operationType: operationType,
                    dtFirst:  "00:00:00",
                    dtSecond:   undefined,
                    isLoading: setLoading, history: history
                })
                
                
            }else{
                LoadOperation({
                    operationType: operation,
                    dtFirst: startDate !== "" ? (startDate!.split('.').reverse().join('-') + " 00:00:00") : undefined,
                    dtSecond: endDate !== "" ? (endDate!.split('.').reverse().join('-') + " 00:00:00") : undefined,
                    isLoading: setLoading, history: history
                })
            }
            BlockOperations(false)
        }

        // eslint-disable-next-line
    }, [modal])
    const onTypeSelect = (e: string) => {
        setOperationType(e)
        setLoading(true)
        if (e == "activeApplications"){
            
            LoadOperation({
                operationType: e,
                dtFirst:  "00:00:00",
                dtSecond:   undefined,
                isLoading: setLoading, history: history
            })
            
        }
        else if (e !== "Все операции") {
            LoadOperation({
                operationType: e,
                dtFirst: startDate !== "" ? (startDate!.split('.').reverse().join('-') + " 00:00:00") : undefined,
                dtSecond: endDate !== "" ? (endDate!.split('.').reverse().join('-') + " 00:00:00") : undefined,
                isLoading: setLoading, history: history
            });
        }
        else {
            setOperations(operations)
        }

    }


    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value)
        var newarray;
        if (e.currentTarget.value !== "") {
            newarray = operationAnalog?.filter(function (item) {
                return (portNameTypeOper(item.type, item.extAnticip).title?.toString().toLowerCase().indexOf(e.currentTarget.value.toLocaleLowerCase()) > -1 ||
                    item.money?.toString().toLowerCase().indexOf(e.currentTarget.value.toLocaleLowerCase()) > -1 ||
                    item.amount?.toString().toLowerCase().indexOf(e.currentTarget.value.toLocaleLowerCase()) > -1 ||
                    item.memberId?.toString().toLowerCase().indexOf(e.currentTarget.value.toLocaleLowerCase()) > -1 ||
                    item.id?.toString().toLowerCase().indexOf(e.currentTarget.value.toLocaleLowerCase()) > -1 ||
                    item.recipientId?.toString().toLowerCase().indexOf(e.currentTarget.value.toLocaleLowerCase()) > -1 ||
                    item.dt?.toString().toLowerCase().indexOf(e.currentTarget.value.toLocaleLowerCase()) > -1)
            })
        }
        else {
            newarray = operationAnalog
        }
        setOperations(newarray)
    }

    let a:string ;
    useEffect(() => {
        
        setLoading(true)
        if (operationType == 'activeApplications'){
            LoadOperation({
                dtFirst:  " 00:00:00",
                dtSecond:  undefined,
                isLoading: setLoading, history: history
            })
            a = "activeApplications";
        }else{
            if(a == 'activeApplications'){
                LoadOperation({
                    dtFirst:  " 00:00:00",
                    dtSecond:  undefined,
                    isLoading: setLoading, history: history
                })
                a = "";
            }else{
                LoadOperation({
                    dtFirst: startDate !== "" ? (startDate!.split('.').reverse().join('-') + " 00:00:00") : undefined,
                    dtSecond: endDate !== "" ? (endDate!.split('.').reverse().join('-') + " 00:00:00") : undefined,
                    isLoading: setLoading, history: history
                })
            }
            
        }
        const divelemet = refer.current;
        divelemet.style.left = "-100%";
        setTimeout(movescreen, 10);
    }, [startDate, endDate, history])


    const portNameTypeOper = (typeOper: string, typeDospExtr?: string) => {

        switch (typeOper) {
            case 'adjunctions':
                return { title: "Пополнение счета", icon: PLUS, color: '#149000' }
            case 'accrualDaily':
                return { title: "Дивиденды", icon: ENV, color: '#E8A736' }
            case 'accrualForPartner':
                return { title: "Партнёрские", icon: ENV, color: '#E8A736' }
            case 'autoReinvest':
                return { title: "Автореинвестирование", icon: INVEST, color: '#149000' }
            case 'changeByAdmin':
                return { title: "Изменение администратором", icon: ADMIN, color: '#C9C9C9' }
            case 'extractions':
                if ((typeDospExtr === "1")) { return { title: "Досрочный вывод средств", icon: ARROW, color: '#B20000' } }
                else {
                    return { title: "Вывод средств", icon: ARROW, color: '#B20000' }
                }
            case 'extractionsAnticipatorily':
                return { title: "Досрочный вывод средств", icon: ARROW, color: '#B20000' }
            case 'investments':
                return { title: "Инвестирование", icon: INVEST, color: '#fff' }
            case 'transfers':
                return { title: "Перевод", icon: EXCHANGE, color: '#B20000' }
            case 'activeApplications':
                return { title: "Активные заявки", icon: EXCHANGE, color: '#B20000' } 
            default:
                return { title: "Неизвестно", icon: PLUS, color: '#B20000' }
        }

    }

    const BlockOperations = (flagToBlock  :any) => {
        myAxios.post('/stuff/blockAllOperations', 
        { 
            needToChange: flagToBlock
        }, 
        { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
            .then(res => { setLock(res.data.block==1?true:false) })
            .catch((async res => {
                if (res.response.data.httpStatus === 401) {
                    var newToken = await refreshToken(history)
                    await myAxios.post('/stuff/blockUser', 
                    { 
                        needToChange: flagToBlock 
                    }, 
                    { headers: { "Authorization": "Bearer " + newToken } })
                        .then(res => { setLock(res.data.block==1?true:false) })
                }
            }))

    }

    return (
        <>
            {modal ? <ModalBlur /> : null}
            <OperationsModal modalLoading={modalLoading} setModal={setModal} class={modal} forModal={forModal} forModalSecond={forModalSecond} />
            <div style={{ flex: 1, position: 'relative' }}>
                <div ref={refer} className="oper_main">
                    <div className={"oper_title_and_block"}>
                    <p className="oper_title">Операции</p>
                    <div className="user_sel_but oper_block_full" onClick={() => BlockOperations(true)}>
                    <p style={!lock ? { color: "#B20000", margin: "0" } : { margin: "0" }}>{!lock ? "Заблокировать" : "Разблокировать"}</p>
                    <img src={lock ? UNLOCK:LOCK} alt="" />
                    {/* <img src={UNLOCK} alt="" /> */}
                    {/* <p style={lock ? { color: "#B20000", margin: "0" } : { margin: "0" }}>{lock ? "Заблокировать" : "Разблокировать"}</p> */}
                    </div>
                </div>
                    {operations !== undefined ? <HighBlocks operations={operations!} dateSt={startDate} dateEnd={endDate} /> : null}
                    {modal ? null :
                        <div style={{ position: 'relative', zIndex: 0 }}>
                            <i className="fas fa-search oper_icon_pos"></i>
                            <input className="oper_form" type="text" placeholder="Поиск..." value={filter} onChange={e => onSearch(e)} />
                        </div>}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                        <div className="oper_dropmenu" style={{ display: 'flex', flexDirection: 'column', justifyContent: "center"}}> 

                            <LocalizationProvider locale={ru} dateAdapter={DateAdapter}>
                                <MobileDateRangePicker
                                    disableMaskedInput={true}
                                    okText="Ок"
                                    endText="Конец"
                                    startText="Начало"
                                    cancelText="Отмена"
                                    label="Дата"
                                    inputFormat="dd.MM.yyyy"
                                    clearText="Очистить"
                                    toolbarTitle="выберите диапазон"
                                    todayText="Сегодня"
                                    value={dateRange}
                                    onChange={(newValue) => {
                                        setDateRange(newValue);
                                        setOperationType("")
                                    }}
                                    renderInput={(params, endparams) =>
                                        < div style={{ float: "right", display: 'inline-block' }}>
                                            <span style={{ color: "#9B9B9B", fontSize: "13px" }}   {...params.inputProps}>
                                                {(params.inputProps?.value === `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}` ||
                                            params.inputProps?.value === `0${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`||
                                            params.inputProps?.value === `0${new Date().getDate()}.0${new Date().getMonth() + 1}.${new Date().getFullYear()}`||
                                            params.inputProps?.value === `${new Date().getDate()}.0${new Date().getMonth() + 1}.${new Date().getFullYear()}`)
                                                    && (!endparams.inputProps?.value || params.inputProps?.value === new Date()) ? "Сегодня" :
                                                    //"Период"
                                                    params.inputProps?.value.substr(0, params.inputProps?.value.length - 5)}
                                                {params.inputProps?.value && endparams.inputProps?.value ? "-" : null}
                                                {endparams.inputProps?.value.substr(0, params.inputProps?.value.length - 5)
                                                }
                                                {useStartDate!(params.inputProps?.value)}
                                                {useEndDate!(endparams.inputProps?.value)}
                                                &nbsp;
                                                <i className="far fa-calendar-alt"></i></span>
                                        </div>
                                    }
                                />
                            </LocalizationProvider>
                        </div>
                        

                        <div className="oper_dropmenu" style={{ padding: 0 }}>
                            <Dropdown >
                                <Dropdown.Toggle variant="default" id="dropdown-basic" className="oper_dropmenu_toggle">
                                    {portNameTypeOper(operationType).title !== "Неизвестно" ? portNameTypeOper(operationType).title : "Все операции"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ backgroundColor: '#2D3039', width: "100%", minWidth: "225px", transform: "translate3d(-30px, 37px, 0px)" }}>
                                    {operTypes.map((e, i) => {
                                        return <Dropdown.Item className="selectOper" style={{ color: '#9B9B9B' }} onClick={() => onTypeSelect(e)} key={i}>{
                                            e !== "" ?
                                                e !== "extractionsAnticipatorily" ? portNameTypeOper(e).title : portNameTypeOper("extractions", "1").title
                                                : "Все операции"
                                        }</Dropdown.Item>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                    </div>
                    <div className="oper_table">
                        {isLoading === true ? <div style={{ position: "absolute", left: 0, right: 0, top: "50%", textAlign: "center" }}>
                            <Spinner animation="border" variant="light" />
                        </div> :
                            operations?.length !== 0 ?
                                operations?.map((oper, i) => {
                                    return (<OperationItem key={i} userID={oper.id} setModalLoading={setModalLoading} memberId={oper.memberId} money={oper.amount} date={oper.dt}
                                        type={portNameTypeOper(oper.type, oper.extAnticip).title} origType={oper.type} setModal={setModal} setForModal={setForModal}
                                        icon={portNameTypeOper(oper.type, oper.extAnticip).icon} color={portNameTypeOper(oper.type, oper.extAnticip).color}
                                        extAnticip={oper.extAnticip} recipientId={oper.recipientId} setForModalSecond={setForModalSecond}
                                        memberStatus={oper.memberStatus} recipientStatus={oper.recipientStatus} isCanceled={oper.isCanceled}
                                    />)
                                }) : <span style={{ color: 'white' }}>Операций по этим фильтрам нет. Выберите другой период</span>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}