import { useEffect, useState } from 'react'
import DELETE from '../../../common/assets/images/delete.png'
import { removeAfterPoint } from '../../../common/config/functions'
import '../styles/UserSelected.css'

export interface IPartnerItem {
    id: number,
    mail: string,
    money: number,
    level: number,
    delete: (id: number) => void
    deleted: boolean
}
export const Partner = (params: IPartnerItem) => {
    const [close, setClose] = useState(true)
    useEffect(() => {
        setClose(false)
    }, [params.deleted])

    return (
        <div style={close ? { display: 'flex', flexDirection: 'row', justifyContent: "space-between", margin: "0rem 2rem", height: "0px", overflow: "hidden", transition: '0.3s' } : { display: 'flex', flexDirection: 'row', justifyContent: "space-between", margin: "0.5rem 2rem", height: '40px' }}>
            <div style={{ display: 'flex', flexDirection: "column" }}>
                <span style={{ color: "#c9c9c9", fontSize: "12px", textAlign: 'left' }}>ID: #{params.id}</span>
                <span style={{ color: "#fff", textAlign: "left" }}>{params.mail}</span>
            </div>
            <div style={{ margin: "auto 0", whiteSpace: "nowrap" }}>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <div style={{ display: 'flex', margin: "auto 0", flexDirection: "column" }}>
                        <span style={{ color: "#E8A736" }}>{removeAfterPoint(params.money.toString())} <i className="fas fa-euro-sign"></i></span>
                        <span style={{ color: "#c9c9c9", fontSize: "12px", textAlign: 'right' }}> {params.level} уровень </span>
                    </div>
                    <img style={{ height: 24, marginLeft:"0.5rem" }} onClick={() => { params.delete(params.id); setClose(true) }} src={DELETE} alt="" />

                </div>
            </div>
        </div>
    )
}