import axios from "axios";

export const myAxios =
    axios.create({baseURL: 
        "https://lioninvest.eu/api", // "Боевой Сервер"
        // "http://192.168.20.211:8039", // "Тестовый Сервер - не забудь поменять на http"
                           method: 'post',
                           headers: {"Content-Type": "application/json",}
                           })

