import { useState } from "react";
import { DateParse } from "../logics/MessageDateParse";
import "../styles/MessagePage.css";
import { setReadMessages } from "../logics/MessagesLoad";
import { createEvent, createStore } from "effector";
import UNREAD from "../../../common/assets/images/unread.png";
export interface IMessageItem {
  MemberId?: number;
  id: number;
  date: string;
  mail: string;
  text: string;
  MesRead?: number;
  FullName: string;
}

export const $flagReadMess = createStore<boolean>(false);
export const setFlagReadMess = createEvent<boolean>();
$flagReadMess.on(setFlagReadMess, (_, val) => val);

export const MessageItem = (params: IMessageItem) => {
  const [span, setSpan] = useState<boolean>(false);
  const [flagReadOfl, setflagReadOfl] = useState<boolean>(false);
  return (
    <div
      className="mess_item"
      onClick={() => {
        setSpan(!span);
        setReadMessages({ Id: params.id.toString() });
        if (span === false && params.MesRead === 0 && flagReadOfl === false) {
          setFlagReadMess(true);
          setflagReadOfl(true);
        }
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "0.75rem 0",
          color: "#C9C9C9",
        }}
      >
        <span>
          Обращение #{params.id}{" "}
          {flagReadOfl === false && params.MesRead === 0 ? (
            <img src={UNREAD} alt="" />
          ) : null}
        </span>
        <span>{DateParse(params.date)} </span>
      </div>
      <p style={{ fontWeight: 500, marginBottom: "0rem", color: "#C9C9C9" }}>
        {params.mail}{" "}
        {params.MemberId && params.MemberId !== 0 ? (
          <a style={{ color: "rgb(201 201 201 / 44%)" }}>
            {" #" + params.MemberId}
          </a>
        ) : (
          <></>
        )}
      </p>
      <p
        style={{
          fontWeight: 100,
          marginBottom: "0.5rem",
          color: "#C9C9C9",
          fontSize: "0.7rem",
        }}
      >
        {params.FullName}
      </p>
      <p style={{ marginBottom: "0.5rem", color: "#FFFFFF", fontSize: "12px" }}>
        {params.text.length > 150 && !span
          ? params.text.slice(0, 150) + "..."
          : params.text}
      </p>
      <div className={span ? "mess_item_span" : "mess_item_span_closed"}>
        {/* <button className={"mess_item_button"} onClick={e=>e.stopPropagation()}>Скрыть</button> */}
        <form action="mailto:foo@bar.com" method="get">
          <button
            className={"mess_item_button"}
            onClick={(e) => e.stopPropagation()}
          >
            <a className="mess_href" href={"mailto:" + params.mail.toString()}>
              Ответить
            </a>
          </button>
        </form>
      </div>
    </div>
  );
};
