import USER_ICON from '../../../assets/images/user_icon.png'
import LOGO_INVEST from '../../../assets/images/logo_invest.png'
import EXIT_LOGO from '../../../assets/images/exit_logo.png'
import '../styles/header.css'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { $ModalShow, setModalShow } from '../../../../pages/users/logics/DayCursLogic'
// import { useUser } from '../../UserProvider/useUser'

export const Header = () => {
    const [showExit, setShowExit] = useState(false)
    const history = useHistory()
    // const {userName}=useUser()
    const [UserName, setUserName] = useState('')
    const onLogOutClick = () => {
        console.log("pushed")
        localStorage.clear()
        history.push('/webabsamdinpanelpage')
    }
    useEffect(() => {
        setUserName(localStorage.getItem('NameUser')!);
    }, []);
    return (
        <div className="hed_main" >
            <img src={LOGO_INVEST} alt='' style={{ height: "24px", margin: "0" }} />
            <div style={{ display: 'flex', flexDirection: 'column', width: "146px", position: 'relative' }}>
                <div onClick={() => setShowExit(!showExit)} style={{ display: "flex", flexDirection: "row", margin: "auto", height: "auto", cursor: "pointer" }}>
                    <img src={USER_ICON} alt='' className='hed_user_icon' />
                    <span className="hed_user_name">{UserName}</span>
                    <i className="fas fa-chevron-down hed_user_arrow" />
                </div>

                <div className={showExit ? 'hed_user_open' : 'hed_user_close'} >
                    <div style={{ color: "#E8A736", backgroundColor: "#1D2029", borderLeft: "1px solid #E8A736", borderRight: "1px solid #E8A736", fontSize: "12px", padding: "0.2rem" }} onClick={() => { setModalShow({ ...$ModalShow.getState(), open: true }); setShowExit(false) }}>
                        <span><i className="fas fa-euro-sign"></i> Курс дня</span>
                    </div>
                    <div onClick={()=>onLogOutClick()}>
                        <img src={EXIT_LOGO} alt="" style={{ color: 'black', height: "11px" }} />
                        <span style={{ fontSize: '11px', margin: "10px", color: 'black' }}>Выйти</span>
                    </div>
                </div>

            </div>
        </div>
    )
}