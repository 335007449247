
import { useEffect, useState } from "react"
import { AuthForms } from "../molecules/AuthForms"
import { PlatformChooser } from "../molecules/PlatformChooser"
import '../style/auth.css'
import { refreshToken } from '../../../common/config/accesToken'
import { useHistory } from "react-router-dom"
import { USERS_PAGE } from "../../../common/config/path"

export const AuthPage = () => {
    const [LogedIn, setLogedIn] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (localStorage.getItem("AccessToken") !== null) {
            refreshToken(history).then((res) => {
                if (res !== "fail")
                    history.push(USERS_PAGE)
            })

        }
    }, [history])
    return (
        <>
            <div className="auth_main">
                {LogedIn ? <PlatformChooser /> : <AuthForms setLogIn={setLogedIn} />}
            </div>
        </>
    )
}