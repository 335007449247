import { useStore } from 'effector-react'
import { useEffect, useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import LONGARROW from '../../../common/assets/images/longarrow2.png'
import DOWNLOAD from '../../../common/assets/images/download.png'
import { onArrowTouch, onArrowTouchEnd, onArrowTouchStart } from '../logics/arrowTouch'
import { $CalculationList, ICalculation, LoadCalculations } from '../logics/CalculationHistory'
import '../styles/stats.css'
import { StatsItem } from './StatsItem'
import { removeAfterPoint } from '../../../common/config/functions'
import { LocalizationProvider, MobileDateRangePicker } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDateFns';
import { ru } from 'date-fns/esm/locale';
import { DateRange } from '@mui/lab/DateRangePicker';
import { useHistory } from 'react-router-dom'
import { useUser } from '../../../common/ui/UserProvider/useUser'
import { IModal } from '../../../common/config/interfaces'
import { ReportWindow } from './ReportWindow'

export const ModalUsers = (params: IModal) => {
    const [newModal,setNewModal]=useState(false)
    const [value, setValue] = useState<DateRange<Date | null>>([new Date(), null]);
    const [stats, setStats] = useState<ICalculation[]>([])
    const [first, setfirst] = useState(false)
    const [pos1, setPos1] = useState(0)
    const [pos2, setPos2] = useState(0)
    const operTypes = ['', "adjunctionToDeposit",'autoReinvest', 'extractionsAnticipatorily', 'transferToMember', 'investment', "extraction", "accrualDaily", "changeByAdmin", "accrualForPartner"]
    const [operationType, setOperationType] = useState('Все операции')
    const [dateStartFilter, setdateStartFilter] = useState<any>(null)
    const [dateEndFilter, setdateEndFilter] = useState<any>(null)
    const arrowblock = useRef<HTMLDivElement | null>(null)
    const history = useHistory()
    const { fullUserInfo } = useUser()
    const CalculationTable = useStore($CalculationList)
    useEffect(() => {
        if (params.class) {
            setOperationType('Все операции')
            const divelemet = arrowblock.current;
            divelemet!.style.bottom = "0px";
        } else {
            const divelemet = arrowblock.current;
            divelemet!.style.bottom = "-100%";
        }

        LoadCalculations({ history: history, id: fullUserInfo.id })
        // eslint-disable-next-line
    }, [params.class])

    useEffect(() => {
        if (CalculationTable !== null)
            setStats(CalculationTable)
    }, [CalculationTable])

    useEffect(() =>{
        console.log("stats",stats,value)
    },[stats])


    const portNameTypeOper = (typeOper: string, typeDospExtr?: number) => {
        switch (typeOper) {
            case "adjunctionToDeposit":
                return { title: "Пополнение счета" }
            case 'accrualDaily':
                return { title: "Дивиденды" }
            case 'accrualForPartner':
                return { title: "Партнёрские" }
            case 'autoReinvest':
                return { title: "Автореинвестирование" }
            case 'changeByAdmin':
                return { title: "Изм. администратором" }
            case 'extraction':
                return { title: "Вывод средств" }

            case 'extractionsAnticipatorily':
                return { title: "Досрочный вывод" }
            case 'investment':
                return { title: "Инвестирование" }
            case 'transferToMember':
                return { title: "Перевод" }
            default:
                return { title: "Неизвестно" }
        }

    }
    const onTypeSelect = (e: string) => {
        setOperationType(e)
        if (e === "" || e === 'Все операции') {
            if(dateStartFilter==null && dateEndFilter==null)
            setStats(CalculationTable!)
        else if(dateStartFilter!=null && dateEndFilter==null)
            setStats(CalculationTable!.filter(item =>  new Date(item.dt)<=new Date(dateEndFilter)))
        else if(dateStartFilter==null && dateEndFilter!=null)
            setStats(CalculationTable!.filter(item => new Date(item.dt)>=new Date(dateStartFilter)))
        else if(dateStartFilter!=null && dateEndFilter!=null)
            setStats(CalculationTable!.filter(item => new Date(item.dt)>=new Date(dateStartFilter) && new Date(item.dt)<=new Date(dateEndFilter)))

        } else {
            if(dateStartFilter==null && dateEndFilter==null)
                setStats(CalculationTable!.filter(item => item.operationTypeSignatura === e))
            else if(dateStartFilter==null && dateEndFilter!=null)
                setStats(CalculationTable!.filter(item => item.operationTypeSignatura === e && new Date(item.dt)<=new Date(dateEndFilter)))
            else if(dateStartFilter!=null && dateEndFilter==null)
                setStats(CalculationTable!.filter(item => item.operationTypeSignatura === e && new Date(item.dt)>=new Date(dateStartFilter)))
            else if(dateStartFilter!=null && dateEndFilter!=null)
                setStats(CalculationTable!.filter(item => item.operationTypeSignatura === e && new Date(item.dt)>=new Date(dateStartFilter) && new Date(item.dt)<=new Date(dateEndFilter)))

        }

    }

    const onPeriodSelect = (e: any) => {
        setdateStartFilter(e[0])
        setdateEndFilter(e[1])
        var dateStart = e[0]
        var dateEnd = e[1]
        var newarray:any[] = []
        if (dateStart === null) {
            newarray = CalculationTable!
            setdateStartFilter(null)
            setdateEndFilter(null)
        } else {
            if (dateEnd === null) {
                dateEnd = new Date();
            setdateEndFilter(new Date())
            }
            dateEnd.setDate(dateEnd.getDate() + 1);

            if(operationType=='Все операции' || operationType=='')
            newarray = CalculationTable!.filter(item => new Date(item.dt) >= new Date(dateStart)
                && new Date(item.dt) <= new Date(dateEnd))
            else if(operationType!='Все операции')
                newarray = CalculationTable!.filter(item => new Date(item.dt) >= new Date(dateStart)
                && new Date(item.dt) <= new Date(dateEnd)
                && item.operationTypeSignatura === operationType)

        }
        setStats(newarray)

    }
    const calculate = (type: string[], isExaction: boolean) => {
        var summ = 0;
        type.forEach(function (typeName) {
            stats.forEach((e) => {

                if (e.operationTypeSignatura === typeName) //Если тип операции входит в список типов операций для этого блока
                    if (e.operationTypeSignatura === "adjunctionToDeposit" ||
                        // e.operationTypeSignatura==="investment" || 
                        e.operationTypeSignatura === "transferToMember") //Если это Пополнение, перевод
                    {
                        if (e.operationTypeSignatura === "transferToMember") { //Этот блок отдельно считает переводы
                            if (e.depositNew !== e.depositOld) {
                                if (!isExaction) {
                                    summ += (e.depositNew - e.depositOld)

                                }
                            }
                            else {
                                if (isExaction) {
                                    summ += (e.currentAccountNew - e.currentAccountOld)
                                }
                            }
                        } else {

                            summ += (e.depositNew - e.depositOld)
                        }
                    }

                    else if (e.operationTypeSignatura === "changeByAdmin") {
                        if (isExaction) {
                            if (e.currentAccountNew - e.currentAccountOld < 0) {
                                summ += (e.currentAccountNew - e.currentAccountOld)
                            }
                        }
                        else {
                            if (e.currentAccountNew - e.currentAccountOld > 0) {
                                summ += (e.currentAccountNew - e.currentAccountOld)
                            }
                        }

                    }
                    else if (e.operationTypeSignatura !== "autoReinvest") {
                        summ += (e.currentAccountNew - e.currentAccountOld)
                    }
            });
        })
        return (removeAfterPoint(summ + ""))
    }
   

    return (
        <div ref={arrowblock} className={params.class ? "stats_main" : "stats_main_close"}>
            <div onClick={()=>setNewModal(true)} style={{color:"#c9c9c9", fontSize:"10px", position:"absolute", right:"1rem"}}>
                <span>Скачать отчет</span>
                 <img src={DOWNLOAD} alt='' style={{ height: 15, margin:"0.5rem" }}/>
            </div>
            <div style={{ touchAction: "none" }} onTouchStart={e => onArrowTouchStart(e, setPos2, setfirst)} onTouchMove={e => onArrowTouch(e, pos1, pos2, setfirst, first, setPos1, setPos2)} onTouchEnd={e => onArrowTouchEnd(e, params.setModal, 130)}>
                <img src={LONGARROW} alt='' style={{ height: 10 }}/>
                <p style={{ color: "#E8A736", fontWeight: 500, marginBottom: "0.75rem" }}>Статистика</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', margin: "3% 0 0" }}>
                <div className="stats_modal_highblock">
                    <span>Выведено</span>
                    <div style={{ display: 'flex', flexDirection: "column", margin: 'auto' }}>
                        <span style={{ color: '#B20000', fontWeight: 700, fontSize: "16px" }}>
                            {(calculate(["extraction", "extractionAnticipatorily", "transferToMember", 'changeByAdmin'], true) + " ").replace(/-/g, "")}
                            <i className="fas fa-euro-sign"></i></span>
                    </div>
                </div>
                <div className="stats_modal_highblock">
                    <span>Начислено</span>
                    <div style={{ display: 'flex', flexDirection: "column", margin: 'auto' }}>
                        <span style={{ color: '#E8A736', fontWeight: 700, fontSize: "16px" }}>
                            {calculate(["accrualDaily", "accrualForPartner", "changeByAdmin"], false) + " "}
                            <i className="fas fa-euro-sign"></i></span>
                    </div>
                </div>
                <div className="stats_modal_highblock">
                    <span>Пополнено</span>
                    <div style={{ display: 'flex', flexDirection: "column", margin: 'auto' }}>
                        <span style={{ color: '#149000', fontWeight: 700, fontSize: "16px" }}>
                            {calculate(["adjunctionToDeposit", "transferToMember"], false) + " "}
                            <i className="fas fa-euro-sign"></i></span>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div className="stats_dropmenu">

                    <LocalizationProvider locale={ru} dateAdapter={DateAdapter}>
                        <MobileDateRangePicker
                            disableMaskedInput={true}
                            okText="Ок"
                            endText="Конец"
                            startText="Начало"
                            cancelText="Отмена"
                            label="Дата"
                            inputFormat="dd.MM"
                            mask="__.__"
                            clearText="Очистить"
                            toolbarTitle="выберите диапазон"
                            todayText="За все время"
                            value={value}
                            onClose={() => { onPeriodSelect(value) }}
                            onChange={(newValue) => {
                                setValue(newValue);
                                // setOperationType('Все операции')

                                //    calculate("extractions", newValue);
                            }}
                            renderInput={(params, endparams) =>
                                < div style={{ textAlign: "center", width: "100%" }}>
                                    <span style={{ color: "#9B9B9B", fontSize: "13px" }}   {...params.inputProps}>
                                        {
                                        (params.inputProps?.value === `${new Date().getDate()}.${new Date().getMonth() + 1}` ||
                                            params.inputProps?.value === `0${new Date().getDate()}.${new Date().getMonth() + 1}`||
                                            params.inputProps?.value === `0${new Date().getDate()}.0${new Date().getMonth() + 1}`||
                                            params.inputProps?.value === `${new Date().getDate()}.0${new Date().getMonth() + 1}`)
                                            && (!endparams.inputProps?.value || params.inputProps?.value === "") ? <span>За все <br />время</span> :
                                            params.inputProps?.value
                                        }
                                        {params.inputProps?.value && endparams.inputProps?.value ? "-" : null}
                                        {
                                            endparams.inputProps?.value
                                        }
                                        &nbsp;
                                        <i className="far fa-calendar-alt"></i></span>
                                </div>

                            }
                        />
                    </LocalizationProvider>
                </div>
                <div className="oper_dropmenu" style={{ padding: 0 }}>
                    <Dropdown >
                        <Dropdown.Toggle variant="default" id="dropdown-basic" className="oper_dropmenu_toggle">
                            {portNameTypeOper(operationType).title !== "Неизвестно" ? portNameTypeOper(operationType).title : "Все операции"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ backgroundColor: '#2D3039', width: "100%", minWidth: "230px", transform: "translate3d(-30px, 37px, 0px)" }}>
                            {operTypes.map((e, i) => {
                                return <Dropdown.Item className="selectOper" style={{ color: '#9B9B9B' }} onClick={() => onTypeSelect(e)} key={i}>{
                                    e !== "" ?
                                        portNameTypeOper(e).title
                                        : "Все операции"
                                }</Dropdown.Item>
                            })}
                        </Dropdown.Menu>
                    </Dropdown>

                </div>

            </div>

            <div style={{ height: "35vh", overflow: "scroll" }}>
                {stats.length !== 0 ?
                    stats
                    .filter(e=>e.operationTypeSignatura!=="reserveExtraction")//Скрываем отмененные, наверное
                    .map((e, i) => {
                        return (
                            //new Date(dateStart)<=new Date(e.dt) && new Date(dateEnd)>=new Date(e.dt)?
                            <StatsItem key={i.toString()} TransferFromId={e.TransferFromId} partnerId={e.partnerId} memberId={e.memberId} modalActive={params.class!} date={e.dt} type={e.operationTypeSignatura} deposit={(e.depositNew - e.depositOld).toString()} id={e.id.toString()} currentAccount={(e.currentAccountNew - e.currentAccountOld).toString()} />
                            //:<></>
                        )

                    }) : <span style={{ color: "white" }}>У этого пользователя нет операций</span>
                }

            </div>
                <ReportWindow class={newModal} setModal={setNewModal}/>
        </div>
    )
}