import { createEvent } from 'effector'
import { myAxios } from '../../../common/config/forAxios';
import { refreshToken } from '../../../common/config/accesToken';
import { IfullUserInfo } from '../../../common/ui/UserProvider/UserProvider';

export interface IVerificationQuery {
    memberId: number
    history: any
    setFullUserInfo: React.Dispatch<React.SetStateAction<IfullUserInfo>>
    fullUserInfo: IfullUserInfo
}

export const ChangeEmail = createEvent<IVerificationQuery>();
export const ChangeTelephone = createEvent<IVerificationQuery>();


ChangeEmail.watch(async params => {

    await myAxios.post('/stuff/emailStatusChange', { memberId: params.memberId }, { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
        .then(res => {
            params.setFullUserInfo({ ...params.fullUserInfo, verificationStatusEmail: res.data.status })
        })
        .catch((async res => {
            if (res.response.data.httpStatus === 401) {
                var newToken = await refreshToken(params.history)
                await myAxios.post('/stuff/emailStatusChange', { memberId: params.memberId }, { headers: { "Authorization": "Bearer " + newToken } })
                    .then(res => { params.setFullUserInfo({ ...params.fullUserInfo, verificationStatusEmail: res.data.status }) })
            }
        }))
}
)

ChangeTelephone.watch(async params =>
    await myAxios.post('/stuff/phoneStatusChange', { memberId: params.memberId }, { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
        .then(res => { params.setFullUserInfo({ ...params.fullUserInfo, verificationStatusPhone: res.data.status }) })
        .catch((async res => {
            if (res.response.data.httpStatus === 401) {
                var newToken = await refreshToken(params.history)
                await myAxios.post('/stuff/phoneStatusChange', { memberId: params.memberId }, { headers: { "Authorization": "Bearer " + newToken } })
                    .then(res => { params.setFullUserInfo({ ...params.fullUserInfo, verificationStatusPhone: res.data.status }) })
            }
        }))
)


// LoadCalculations.use(async (params) => {
//     var empty_pack:ICalculation[]=[]
//     await myAxios.post('/users/getAllCalculationsOnUserId',{ id:params.id},{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
//             .then(res=>{  empty_pack=res.data.calculations})
//             .catch((async res=>{
//                 if(res.response.data.httpStatus === 401){
//                     var newToken = await refreshToken(params.history)
//                     await myAxios.post('/users/getAllCalculationsOnUserId',{id:params.id},{ headers:{"Authorization": "Bearer "+newToken}})
//                     .then(res=>{  empty_pack=res.data.calculations})
//             }}))
//         return empty_pack as ICalculation[]
// })
// $CalculationList.on(LoadCalculations.doneData, (_, val) => val);