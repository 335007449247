import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { MessageItem } from '../molecules/MessageItem'
import '../styles/MessagePage.css'
import { $messages, LoadMessages } from '../logics/MessagesLoad'
import { useStore } from 'effector-react'

import { Spinner } from 'react-bootstrap'

export const MessagePage = () =>{
    const MessageList = useStore($messages)
   
    useEffect(()=>{
        setMessages(MessageList)
    },[MessageList])


    const [isLoading,setIsLoading]=useState(true)

    const [filter,setFilter]=useState('')
    const [sortWay,setSortWay]=useState<number>(-1)
    const [messages,setMessages]=useState(MessageList)

    const buttonSort=useCallback(()=>{      //UseCallback ты используешь, чтобы исправить ошибку для 56 строки, но пока не понял зачем
        let newnumbers = MessageList?.sort(function(a, b) {
            if (a.ProblemDt > b.ProblemDt) {
                return sortWay;
              }
              if (a.ProblemDt < b.ProblemDt) {
                return -sortWay;
              }
              // a должно быть равным b
              return 0;
          });
          if(newnumbers!==undefined){
            setMessages(newnumbers)
          }         
          setSortWay(0-sortWay)
    },[MessageList,sortWay])

    const onSearch=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setFilter(e.currentTarget.value)
        const newarray = MessageList?.filter(function(item){
            return (item.Id.toString().indexOf(e.currentTarget.value.toLocaleLowerCase())>-1||
            item.ProblemDt.toLowerCase().indexOf(e.currentTarget.value.toLocaleLowerCase())>-1||
            item.EmailFrom.toLowerCase().indexOf(e.currentTarget.value.toLocaleLowerCase())>-1||
            item.ProblemText.toLowerCase().indexOf(e.currentTarget.value.toLocaleLowerCase())>-1) })
        if(newarray!==undefined){
            setMessages(newarray)
        }
     
    }
    const history = useHistory()
    const refer = useRef<HTMLDivElement>(null);
    const movescreen=()=>{
        const divelemet =  refer.current;//@ts-ignore
        divelemet!.style.left="0%";
    }
    useEffect(()=>{
        setIsLoading(true)
        LoadMessages({setIsLoading:setIsLoading, history:history})
        buttonSort()
        const divelemet =  refer.current;//@ts-ignore
        divelemet!.style.left="100%";//@ts-ignore
        setTimeout(movescreen,10);
        // eslint-disable-next-line
    },[])
    return(
        <div style={{ flex:1, position:'relative'}}>
            <div className="mess_main" ref={refer} >
                <p className="mess_title">Сообщения о проблемах</p>
                
                <div style={{position:'relative'}}>
                    <i className="fas fa-search mess_icon_pos"></i>
                    <input className="mess_form" type="text" placeholder="Поиск..." value={filter} onChange={(e)=>onSearch(e)}/>
                </div>
                <div style={{display:'flex', flexDirection:'row',justifyContent: 'center'}}>
                    <div className="mess_dropmenu">
                        <span onClick={buttonSort}>Период 
                            <i className={sortWay>0?"fas fa-chevron-up":"fas fa-chevron-down"} style={{margin:"0 0 0 0.3rem"}}></i>
                        </span>
                    </div>
                </div> 
                {isLoading?
                    <div style={{position:"absolute", left:0, right:0, top:"50%", textAlign:"center"}}>
                        <Spinner  animation="border" variant="light" />
                    </div>:
                <div style={{overflowY:"scroll", flex:1, paddingBottom:"1rem"}}>
                
                    {messages?.map((mes, i)=>{
                        return(<MessageItem key={i} id={mes.Id} FullName={mes.FullName} MemberId={mes.MemberId} mail={mes.EmailFrom} text={mes.ProblemText} date={mes.ProblemDt} MesRead={mes.MessageRead}/>)
                    })}
                </div>
                }
        
            </div>
        </div>
    )
}