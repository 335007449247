import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { makeStyles } from '@mui/styles';
import { useState } from "react";
import { forModal } from "../organoids/UsersPage";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IUserItem } from "../logics/LoadUsers";
import { DateParse } from "../../messages/logics/MessageDateParse";
import { removeAfterPoint } from "../../../common/config/functions";
import UNREAD from '../../../common/assets/images/unread.png'
import REDELLIPSER from '../../../common/assets/images/Ellipse_Red.png'

const CreateDateIfIOS = (date: string | number) => {
  var published = new Date(date);
  if (Number.isNaN(published.getMonth())) {
    published = new Date(date?.toString().replace(" ", "T") + "Z");

  }
  return published
}
const erase = (str: string) => {
  return str + "" !== "undefined" ? str.includes(".") ? str.split('.')[0] : str : 0
}
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === "deposit") {//@ts-ignore
    return erase(a[orderBy] + "") - erase(b[orderBy] + "");
  }
  else {
    if (orderBy === "dtCreate") {
      if (isNaN(new Date(a[orderBy] + "").getTime())) {
        return -1;
      }
      else {
        if (isNaN(new Date(b[orderBy] + "").getTime())) {
          return 1;
        }
      }
      if (new Date(b[orderBy] + "").getTime() < new Date(a[orderBy] + "").getTime()) {
        return -1;
      }
      if (new Date(b[orderBy] + "").getTime() > new Date(a[orderBy] + "").getTime()) {
        return 1;
      }
      return 0;

    }
    else {
      if ((b[orderBy] + "").toLowerCase() < (a[orderBy] + "").toLowerCase()) {
        return -1;
      }
      if ((b[orderBy] + "").toLowerCase() > (a[orderBy] + "").toLowerCase()) {
        return 1;
      }
      return 0;

    }

  }
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: string
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name1',
    numeric: false,
    disablePadding: false,
    label: 'Имя',
  },
  {
    id: 'deposit',
    numeric: true,
    disablePadding: false,
    label: 'Депозит',
  },
  {
    id: 'dtCreate',
    numeric: true,
    disablePadding: false,
    label: 'Открытие',
  }

];

interface EnhancedTableProps {
  onRequestSort: (event: any, property: string) => void;
  order: Order;
  orderBy: string;
}
const useStyles = makeStyles(() => ({
  root: {
    '&$active': {
      color: 'white!important',
    },
  },
  active: {},
  icon: {
    color: "white!important"

  },
  tableRow: {
    "&:hover": {
      backgroundColor: '#2D3039!important'
    }
  }
}));
function EnhancedTableHead(props: EnhancedTableProps) {

  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: string, event: any) => {
      event.preventDefault()
      onRequestSort(event, property);
    };
  const classes = useStyles();
  return (
    <TableHead style={{ backgroundColor: "#1D2029!important" }}>
      <tr>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ color: "#C9C9C9", backgroundColor: "#1D2029!important", border: "1px solid #1D2029", padding: "3px" }}
            key={headCell.id}
            align='center'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              classes={{ active: classes.active, root: classes.root, icon: classes.icon }}
              sx={{ color: "#C9C9C9", cursor: "pointer" }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
              onTouchEnd={e => { createSortHandler(headCell.id, e) }}
              IconComponent={KeyboardArrowDownIcon}
            // onMouseLeave={e=>e.preventDefault()}
            // onTouchMove ={e=>e.preventDefault()}
            >

              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>

          </TableCell>

        ))}
      </tr>
    </TableHead>)

}

interface IUserTable {
  userSelect: React.Dispatch<React.SetStateAction<boolean>>,
  setForModal: React.Dispatch<React.SetStateAction<forModal>>,
  rows: IUserItem[],
  IsScrolling: boolean,
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>,
}
export const UsersTable = (params: IUserTable) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('id');



  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onRowClick = (deposit: any, id: any, account: any) => {
    params.userSelect(true)
    params.setForModal({
      deposit: deposit,
      id: id,
      account: account
    })
  }
  return (
    <TableContainer component={Paper} sx={{ overflowY: "scroll", overflowX: "hidden", margin: "1rem 0", backgroundColor: "#1D2029",marginTop: "2%" }}>
      <Table stickyHeader aria-label="simple table">
        <EnhancedTableHead

          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />

        <TableBody>
          {//@ts-ignore
            stableSort(params.rows, getComparator(order, orderBy))
              .map((row) => {
                return (


                  <TableRow
                    style={{ backgroundColor: "#1D2029", cursor: "pointer" }}
                    // onMouseLeave={e=>e.preventDefault()}
                    // onTouchMove ={e=>e.preventDefault()}
                    onTouchStart={e => { params.setIsScrolling(false) }}
                    onTouchMove={e => { params.setIsScrolling(true) }}
                    onTouchEnd={e => { if (!params.IsScrolling) { onRowClick(row.deposit, row.id, row.account) } }}
                    // onTouchEnd={e=>onRowClick(row.deposit,row.id, row.account)}
                    // onClick={(e)=>{e.preventDefault();onRowClick(row.deposit,row.id, row.account)}}

                    tabIndex={-1}
                    key={row.id}
                  >

                    <TableCell
                      sx={{ color: "#C9C9C9", cursor: "pointer", border: "1px solid #1D2029", borderBottom: "1px solid #C9C9C9", fontSize: "12px", padding: "1rem 0" }}
                      component="th"
                      scope="row"
                      align='center'
                    >
                      #{row.id}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", cursor: "pointer", border: "1px solid #1D2029", borderBottom: "1px solid #C9C9C9", fontSize: "12px", padding: "1rem 0" }} align="center">{row.name1 + " " + row.name2}&nbsp;{row.blockCheck === "2" ? <img src={REDELLIPSER} alt="" /> : row.blockCheck === "1" ? <img src={UNREAD} alt="" /> : null}</TableCell>
                    <TableCell sx={{ color: "#fff", cursor: "pointer", border: "1px solid #1D2029", borderBottom: "1px solid #C9C9C9", fontSize: "12px", padding: "1rem 0" }} align="center"> {row.deposit ? removeAfterPoint(row.deposit.toString()) : "0.00"}&nbsp;<i className="fas fa-euro-sign"></i></TableCell>
                    {/* 3140636859 */}
                    <TableCell sx={{ color: "#fff", cursor: "pointer", border: "1px solid #1D2029", borderBottom: "1px solid #C9C9C9", fontSize: "12px", padding: "1rem 0" }} align="center">{row.dtCreate ? DateParse(row.dtCreate.toString()) : "Отсутствует"}&nbsp;{(CreateDateIfIOS(row.dtCreate).getTime() + 31536000000) - new Date().getTime() < 0 ? <img src={REDELLIPSER} alt="" /> : (CreateDateIfIOS(row.dtCreate).getTime() + 31536000000) - new Date().getTime() < 604800000 ? <img src={UNREAD} alt="" /> : null}</TableCell>

                  </TableRow>

                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  )

}