import {createEffect, restore} from 'effector'
import React from 'react';
import { myAxios } from '../../../common/config/forAxios';
import { refreshToken } from '../../../common/config/accesToken';

export interface IUserItem{
    id: number,
    dtCreate:string,
    name1:string,
    name2:string,
    deposit:string,
    account:string

}
interface IUsersQuery{
    history:any,
    isDepozit: React.Dispatch<React.SetStateAction<number>>

}

export const LoadDepozit = createEffect<IUsersQuery, IUserItem[]>();

export const $UsersList= restore<IUserItem[]>(LoadDepozit,null)


LoadDepozit.use(async (params) => {
    var empty_pack:IUserItem[]=[]
    await myAxios.post('/users/list',{},{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
    .then(res=>{
        empty_pack=res.data.members
        var countDep=0;
        for (var i = 0; i < empty_pack.length; i++) {
           if( res.data.members[i].deposit>0)
           {countDep=countDep+ Number(res.data.members[i].deposit)}
            }
            params.isDepozit(countDep)
        })
    .catch((async res=>{
        if(res.response.data.httpStatus === 401){
            var newToken = await refreshToken(params.history)
            await myAxios.post('/users/list',{},{ headers:{"Authorization": "Bearer "+newToken}})
                    .then(res=>{empty_pack=res.data.members
                        var countDep=0;
                        for (var i = 0; i < empty_pack.length; i++) {
                           if( res.data.members[i].deposit>0)
                           {countDep=countDep+ Number(res.data.members[i].deposit)}
                            }
                            params.isDepozit(countDep)})
                    }
    }))
        return empty_pack as IUserItem[]
        
})



