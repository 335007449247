
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LONGARROW from '../../../common/assets/images/longarrow2.png'
import { refreshToken } from '../../../common/config/accesToken'
import { myAxios } from '../../../common/config/forAxios'
import { IModal } from '../../../common/config/interfaces'
import { useUser } from '../../../common/ui/UserProvider/useUser'
import { onArrowTouch, onArrowTouchEnd, onArrowTouchStart } from '../logics/arrowTouch'
import '../styles/score.css'

export const StatusScore=(params:IModal)=>{
    const {fullUserInfo,setFullUserInfo}=useUser()
    const [score,setScore]=useState<string>("0") //Бонус-баллы
    const [userId,setUserId]=useState<number>()
    const history=useHistory()
    const [first,setfirst]=useState(false)
    const [pos1,setPos1]=useState(0)
    const [pos2,setPos2]=useState(0)
    const arrowblock = useRef<HTMLDivElement | null>(null)

    useEffect(()=>{
        setUserId(fullUserInfo.id)
        setScore(fullUserInfo.bonusPoints?.toString())
        if(params.class){
            const divelemet =  arrowblock.current;
           divelemet!.style.bottom="0px";
        } else{
            const divelemet =  arrowblock.current;
            divelemet!.style.bottom="-100%";
          }
    },[params.class,fullUserInfo])

    

    const onAddClick=async()=>{
        await myAxios.post('/stuff/bonusPoints',{memberId:userId,
            bonusPoints:score},{headers:{"Authorization": "Bearer "+localStorage.getItem("AccessToken")}})
        .then(res=>{ var newdata = res.data.member
            setFullUserInfo({
                id:fullUserInfo.id,
                name:fullUserInfo.name,
                bonusPoints:newdata.bonusPoints,
                email:fullUserInfo.email,
                phone:fullUserInfo.phone,
                dtCreate:fullUserInfo.dtCreate,
                accurals:fullUserInfo.accurals,
                deposit:fullUserInfo.deposit,
                account:fullUserInfo.account,
                blockCheck:fullUserInfo.blockCheck,
                verificationStatusEmail: fullUserInfo.verificationStatusEmail,
                verificationStatusPhone: fullUserInfo.verificationStatusPhone,
                passNumber:  fullUserInfo.passNumber,
                dtIssue:  fullUserInfo.dtIssue,
                authority:  fullUserInfo.authority,
                statusSignatura:  fullUserInfo.statusSignatura,
                documentId: fullUserInfo.documentId
            })})
        .catch((async res=>{
            if(res.response.data.httpStatus === 401){
                var newToken = await refreshToken(history)
                await myAxios.post('/stuff/bonusPoints',{memberId:userId,
                    bonusPoints:score},{ headers:{"Authorization": "Bearer "+newToken}})
                        .then(res=>{ var newdata = res.data.member
                            setFullUserInfo({
                                id:fullUserInfo.id,
                                name:fullUserInfo.name,
                                bonusPoints:newdata.bonusPoints,
                                email:fullUserInfo.email,
                                phone:fullUserInfo.phone,
                                dtCreate:fullUserInfo.dtCreate,
                                accurals:fullUserInfo.accurals,
                                deposit:fullUserInfo.deposit,
                                account:fullUserInfo.account,
                                blockCheck:fullUserInfo.blockCheck,
                                verificationStatusEmail: fullUserInfo.verificationStatusEmail,
                                verificationStatusPhone: fullUserInfo.verificationStatusPhone,
                                passNumber:  fullUserInfo.passNumber,
                                dtIssue:  fullUserInfo.dtIssue,
                                authority:  fullUserInfo.authority,
                                statusSignatura:  fullUserInfo.statusSignatura,
                                documentId: fullUserInfo.documentId
                            })})
                        }
        }))
        params.setModal(false)
    }

    return(
        <div  ref={arrowblock} className={params.class?"score_main":"score_main_close"}>
       <div style={{touchAction:"none"}} onTouchStart={e=>onArrowTouchStart(e, setPos2, setfirst)} onTouchMove={e=>onArrowTouch(e,pos1,pos2, setfirst, first, setPos1,setPos2)} onTouchEnd={e=>onArrowTouchEnd(e,params.setModal, 70)}>
            <img src={LONGARROW} alt='' style={{height:10}}/>
            <p style={{color:"#E8A736", fontWeight:500, marginBottom:"0.75rem", fontSize:"18px"}}>Статус баллы</p>
        </div>
        <div style={{position:'relative', margin:"0", height:"3rem"}}>
            
        <fieldset style={{border:"1px solid",height:"3rem",borderRadius:"10px",backgroundColor:"#2D3039", color: "#E8A736"}}>
                <legend className="score_legend">
                    <span>Статус-баллы</span>
                  
                </legend>
                <input className="score_form" type="text" id="deposit" value={score} onChange={(e)=>setScore(e.target.value)}/>
             
            </fieldset>
           
        </div>
        <button className="score_save_button" onClick={()=>onAddClick()} >Добавить</button>
    </div> 
    )
}