import { createEffect } from 'effector'
import React from 'react';
import { myAxios } from '../../../common/config/forAxios';
import { refreshToken } from '../../../common/config/accesToken';

interface ICancleOperationQuery {
    id: number,
    title: string,
    dateFact: string,
    setModal: React.Dispatch<React.SetStateAction<boolean>>,
    history: any,
    setErrorMessage: React.Dispatch<React.SetStateAction<boolean>>,

}

export const CancelOperation = createEffect<ICancleOperationQuery, null>();


CancelOperation.use(async (params) => {
    var dd = new Date(params.dateFact!)
    var dateString = ''
    console.log(params.dateFact)
    if (Number.isNaN(dd.getMonth())) {
        dd = new Date(params.dateFact!.replace(" ", "T") + "Z");
    }
    if (!Number.isNaN(dd.getMonth())) {
        dateString = new Date(dd!.getTime() - (dd!.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];

    }
    await myAxios.post('/stuff/rejection', { id: params.id, title: params.title, dtFact: dateString }, { headers: { "Authorization": "Bearer " + localStorage.getItem("AccessToken") } })
        .then(res => { params.setModal(false) })
        .catch((async res => {
            params.setErrorMessage(true);
            if (res.response.data.httpStatus === 401) {
                var newToken = await refreshToken(params.history)
                await myAxios.post('/stuff/rejection', { id: params.id, title: params.title, dtFact: dateString }, { headers: { "Authorization": "Bearer " + newToken } })
                    .then(res => { params.setModal(false) }).catch(res => params.setErrorMessage(true))
            }
        }))
    return null
})