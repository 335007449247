import { LocalizationProvider, MobileDateRangePicker } from '@mui/lab'
import { DateRange } from '@mui/lab/DateRangePicker';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { ru } from 'date-fns/esm/locale';
import { useEffect, useState } from 'react';
import '../styles/operations.css'
import { forItem } from '../organoids/operations';
import { LoadDepozit } from './LoadDepozit'
import { removeAfterPoint } from '../../../common/config/functions'
import { LoadOperationSimple } from '../logics/LoadOperations';
import { DateParse } from '../../messages/logics/MessageDateParse';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';


interface IHighBlocks {
    operations: forItem[],
    dateSt: string,
    dateEnd: string
}


export const HighBlocks = (paramsglob: IHighBlocks) => {
    const [value, setValue] = useState<DateRange<Date | null>>([new Date(), null]);
    const [valueSecond, setValueSecond] = useState<DateRange<Date | null>>([new Date(), null]);

    const [loading, setLoading] = useState(false)
    const history = useHistory()


    const calculate = (type: string, value: any) => {
        
        console.log(value)
        var dateStart = value[0]
        var dateEnd = value[1]
        // if (!isNaN(dateEnd)) {

        //     dateEnd = new Date()
        // }
        LoadOperationSimple({
            history: history, operationType: type,
            dtFirst: !isNaN(dateStart?.getTime()) ? (DateParse(dateStart).split('.').reverse().join('-') + " 00:00:00") : undefined,
            dtSecond: !isNaN(dateEnd?.getTime()) ? (DateParse(dateEnd).split('.').reverse().join('-') + " 00:00:01") : undefined,
            isLoading: setLoading, setMoney: type === "extractions" ? setExportMoneyDataRevers : setImportMoneyDataRevers
        })

    }


    const [Depozit, setDepozit] = useState(0)



    useEffect(() => {
        LoadDepozit({ history: history, isDepozit: setDepozit })
    }, [history])


    const [ExportMoneyDataRevers, setExportMoneyDataRevers] = useState<number | null>();
    useEffect(() => {
        calculate("extractions", value);
        setLoading(true)
        // eslint-disable-next-line
    }, [value])


    const [ImportMoneyDataRevers, setImportMoneyDataRevers] = useState<number | null>();
    useEffect(() => {
        calculate("adjunctions", valueSecond);
        setLoading(true)
        // eslint-disable-next-line
    }, [valueSecond])

    useEffect(() => {
        setValue([new Date(paramsglob.dateSt.split('.').reverse().join('.')), new Date(paramsglob.dateEnd.split('.').reverse().join('.'))])
        setValueSecond([new Date(paramsglob.dateSt.split('.').reverse().join('.')), new Date(paramsglob.dateEnd.split('.').reverse().join('.'))])

    }, [paramsglob.dateSt, paramsglob.dateEnd])


    return (
        <div style={{ display: 'flex', flexDirection: 'row', margin: "3% 0%" }}>
            <div className="oper_high_block">
                <span>Вывод</span>
                <span style={{ color: "#B20000", fontWeight: 700 }}>
                    {loading ? <Spinner animation="border" variant="danger" size="sm" /> : removeAfterPoint(ExportMoneyDataRevers ? ExportMoneyDataRevers + "" : "0")}
                    &nbsp;<i className="fas fa-euro-sign"></i></span>
                <LocalizationProvider locale={ru} dateAdapter={DateAdapter}>
                    <MobileDateRangePicker
                        disableMaskedInput={true}
                        okText="Ок"
                        endText="Конец"
                        startText="Начало"
                        cancelText="Отмена"
                        label="Дата"
                        inputFormat="dd.MM"
                        mask="__.__"
                        clearText="Очистить"
                        toolbarTitle="выберите диапазон"
                        todayText="Сегодня"
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                            calculate("extractions", newValue);
                        }}
                        renderInput={(params, endparams) =>
                            < div style={{ textAlign: "center", width: "100%" }}>
                                <span style={{ color: "#9B9B9B", fontSize: "13px" }}   {...params.inputProps}>
                                    {(params.inputProps?.value === `${new Date().getDate()}.${new Date().getMonth() + 1}` ||
                                        params.inputProps?.value === `0${new Date().getDate()}.${new Date().getMonth() + 1}` ||
                                        params.inputProps?.value === `0${new Date().getDate()}.0${new Date().getMonth() + 1}` ||
                                        params.inputProps?.value === `${new Date().getDate()}.0${new Date().getMonth() + 1}`)
                                        && (!endparams.inputProps?.value || params.inputProps?.value === new Date()) ? "Сегодня" :
                                        params.inputProps?.value
                                    }
                                    {params.inputProps?.value && endparams.inputProps?.value ? "-" : null}
                                    {
                                        endparams.inputProps?.value
                                    }
                                    &nbsp;
                                    <i className="far fa-calendar-alt"></i></span>
                            </div>

                        }
                    />
                </LocalizationProvider>
            </div>
            <div className="oper_high_block">
                <span>Депозиты</span>
                <div style={{ display: 'flex', flexDirection: "column", margin: 'auto' }}>
                    <span>{Depozit === 0 ? <Spinner animation="border" variant="warning" size="sm" /> : removeAfterPoint(Depozit + "")}&nbsp;<i className="fas fa-euro-sign"></i></span>
                </div>
            </div>
            <div className="oper_high_block">
                <span>Пополнения</span>
                <span style={{ color: "#149000", fontWeight: 700 }}>
                    {loading ? <Spinner animation="border" variant="success" size="sm" /> : removeAfterPoint(ImportMoneyDataRevers ? ImportMoneyDataRevers + "" : "0")}
                    &nbsp;<i className="fas fa-euro-sign"></i></span>
                <LocalizationProvider locale={ru} dateAdapter={DateAdapter}>
                    <MobileDateRangePicker
                        disableMaskedInput={true}
                        okText="Ок"
                        endText="Конец"
                        startText="Начало"
                        cancelText="Отмена"
                        clearText="Очистить"
                        toolbarTitle="выберите диапазон"
                        todayText="Сегодня"
                        inputFormat="dd.MM"
                        value={valueSecond}
                        onChange={(newValue) => {
                            setValueSecond(newValue);
                            calculate("adjunctions", newValue);
                        }}
                        renderInput={(params, endparams) =>

                            < div style={{ textAlign: "center", width: "100%" }}>
                                <span style={{ color: "#9B9B9B", fontSize: "13px" }}   {...params.inputProps}>
                                    {(params.inputProps?.value === `${new Date().getDate()}.${new Date().getMonth() + 1}` ||
                                        params.inputProps?.value === `0${new Date().getDate()}.${new Date().getMonth() + 1}` ||
                                        params.inputProps?.value === `0${new Date().getDate()}.0${new Date().getMonth() + 1}` ||
                                        params.inputProps?.value === `${new Date().getDate()}.0${new Date().getMonth() + 1}`)
                                        && (!endparams.inputProps?.value || params.inputProps?.value === new Date()) ? "Сегодня" :
                                        params.inputProps?.value
                                    }
                                    {params.inputProps?.value && endparams.inputProps?.value ? "-" : null}
                                    {
                                        endparams.inputProps?.value
                                    }
                                    &nbsp;
                                    <i className="far fa-calendar-alt"></i></span>
                            </div>

                        }
                    />
                </LocalizationProvider>
            </div>
        </div>
    )
}